import React, {useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileCirclePlus} from '@fortawesome/free-solid-svg-icons'
import DocumentContainer from '../index'
import {Select} from 'react-functional-select'
import Document from '../../../../../../models/Document'

const AccountingDocumentsModal = (
  {
    showAccountingDocumentsModal,
    documents,
    handleOnConfirmClick,
    handleOnCloseClick,
    documentTypeOptions,
    setDocuments,
    getPossibleFiles,
    backgroundOptions,
    prescriptions,
    setPrescriptions,
    healthProvisionTypes,
    policyId,
    insuree,
    triggerPolicyInstanceProvisionAlert
  }
) => {

  const [selectedAccountingDocument, setSelectedAccountingDocument] = useState('')

  let selectRef = useRef(null)

  const removeSelectedAccountingDocument = () => {
    setSelectedAccountingDocument('')
    selectRef.current.clearValue()
  }
  const getOptions = () => {
    let options = documents.filter((document) => {
      return document.isAccountingDocument
    }).map((accountingDocument) => {
      return {
        value: accountingDocument.systemId,
        label: accountingDocument.resume
      }
    })
    options = [...options, {value: '-1', label: <div><FontAwesomeIcon icon={faFileCirclePlus}/> Nuevo documento</div>}]
    return options
  }

  const handleChange = (selected) => {
    if (selected) {
      let {value} = selected,
        accountingDocuments = documents
      if (value === '-1') {
        // Agregar nuevo documento contable
        let newAccountingDocument = new Document({
            documentType: documentTypeOptions[2].options[0].value,
            documentTypeName: documentTypeOptions[2].options[0].label,
            documentTypeGroup: 'accounting_document'
          })
        accountingDocuments = [... documents, newAccountingDocument]
        value = newAccountingDocument.systemId
        setDocuments(accountingDocuments)
      }
      setSelectedAccountingDocument(accountingDocuments.filter((accountingDocument) => {
        return accountingDocument.systemId === value
      })[0])
    }else {
      setSelectedAccountingDocument('')
    }
  }

  const handleConfirm = () => {
    handleOnConfirmClick(selectedAccountingDocument)
    setSelectedAccountingDocument('')
  }

  const setDocument = (_, document) => {
    let newAccountingDocuments = documents.map((accountingDocument) => {
      if (accountingDocument.systemId === document.systemId) {
        document.changeKey()
        accountingDocument = document
      }
      return accountingDocument
    })
    setSelectedAccountingDocument(document)
    setDocuments(newAccountingDocuments)
  }

  const handleClose = () => {
    handleOnCloseClick()
    setSelectedAccountingDocument('')
  }

  return (
    <>
      <Modal
        show={showAccountingDocumentsModal}
        onHide={handleClose}
        className={'accounting-documents-modal'}
        dialogClassName={'mw-100 w-100 px-3'}
      >
        <Modal.Header>
          <Modal.Title>Asociar documento contable</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Select
            className={'w-100 mb-3'}
            placeholder={'Seleccione un documento contable'}
            value={selectedAccountingDocument}
            onOptionChange={handleChange}
            options={getOptions()}
            isClearable
            ref={selectRef}
          />
          {selectedAccountingDocument && (
            <div className={'pt-3'}>
              <DocumentContainer
                {...
                  {
                    documentTypeOptions,
                    backgroundOptions,
                    setDocument,
                    prescriptions,
                    setPrescriptions,
                    healthProvisionTypes,
                    policyId,
                    insuree,
                    triggerPolicyInstanceProvisionAlert
                  }
                }
                isModal={true}
                accountingDocuments={[]}
                setAccountingDocuments={() => {}}
                formAlerts={[]}
                setFormAlerts={() => {}}
                document={selectedAccountingDocument}
                removeDocument={removeSelectedAccountingDocument}
                index={selectedAccountingDocument.systemId}
                attachments={getPossibleFiles()}
                isAccountingDocument={true}
                containerClassName={'pb-4 w-100'}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-start'}>
          <div
            className={'btn btn-primary'}
            onClick={handleConfirm}
          >
            Aceptar
          </div>
          <div
            className={'btn btn-default'}
            onClick={handleClose}
          >
            Cancelar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AccountingDocumentsModal