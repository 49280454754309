import React from 'react'
import Input from '../../../../../../shared/Input'
import numberToCurrency from '../../../../../../utils/numberToCurrency'

const ProvisionDetailSimpleInput = (
  {
    provisionDetail,
    setProvisionDetail,
    index,
    currency,
    property,
    insuranceType,
    isEditable,
    money
  }
) => {

  const value = provisionDetail[property]

  const getMaxRequiredAmount = (pd) => {
    let { totalAmount, isapreBonus, otherInsurancesDiscounts } = pd,
      discounts = isapreBonus + otherInsurancesDiscounts,
      maxRequiredAmount = Math.floor((totalAmount - discounts) * 100) / 100 // Necesitamos que se redondeen hacia abajo los decimales
    return totalAmount >= discounts ? maxRequiredAmount : 0
  }

  const handleChange = (event) => {
    let newProvisionDetail = provisionDetail,
      newValue = parseFloat(event.target.value)
    if (property === 'approvedQuantity' && newValue > newProvisionDetail.requiredQuantity) {
      // Cantidad aprobada no puede sobrepasar la cantidad solicitada
      return
    }
    newProvisionDetail[property] = newValue
    // Casos especiales
    switch (property) {
      case 'totalAmount':
      case 'isapreBonus':
      case 'otherInsurancesDiscounts':
        // Tras ingreso de valor total, bonificación isapre o descuentos otros seguros.
        // Se actualiza valor solicitado = total - bon. isapre - otros seguros.
        newProvisionDetail.requiredAmount = getMaxRequiredAmount(newProvisionDetail)
        break;
      case 'requiredQuantity':
        // Tras el ingreso de la cantidad solicitada.
        // La cantidad aprobada se actualiza con el mismo valor
        newProvisionDetail.approvedQuantity = newProvisionDetail.requiredQuantity
        break;
    }
    setProvisionDetail(index, newProvisionDetail)
  }

  const checkErrors = () => {
    let resp = false
    switch (property) {
      case 'requiredAmount':
        if (provisionDetail.requiredAmount > getMaxRequiredAmount(provisionDetail)) {
          // Nótese que cambiar el valor solicitado a un monto más alto que
          // (valor total - bon. isapre - dcto otros seguros)
          // es un error de digitación y requiere ser corregido
          resp = true
        }
        break;
    }
    return resp
  }

  const getInputClassName = (hasError) => {
    return hasError ? 'is-invalid' : ''
  }

  const getErrorMsg = (hasError) => {
    let errorMsg = ''
    if (hasError) {
      switch (property) {
        case 'requiredAmount':
          errorMsg = <span>Valor solicitado no puede ser mayor a <strong>{numberToCurrency(getMaxRequiredAmount(provisionDetail), currency)}</strong></span>
          break;
      }
    }
    return errorMsg
  }

  let hasError = checkErrors(),
    errorMsg = getErrorMsg(hasError),
    inputClassName = getInputClassName(hasError)

  return (
    <>
      <div className={'w-100'}>
      {isEditable ? (<Input
          value={value}
          onChange={handleChange}
          type={'number'}
          className={inputClassName}
          min={'0'}
          disabled={property == "requiredAmount" && insuranceType == "dental"}
        />) : 
        (<div>
          {money ? numberToCurrency(value, currency) : value}
        </div>
        )}
        {errorMsg !== '' && (
          <div className={'invalid-feedback d-block w-100'}>
            {errorMsg}
          </div>
        )}
      </div>
    </>
  )
}

export default ProvisionDetailSimpleInput