import React from 'react'
import DocumentFooterAmount from './DocumentFooterAmount'

const DocumentFooterAmounts = (
  {
    document
  }
) => {

  let currency = document.currencyType,
    [totalRequired, totalApproved, totalRefund] = document.provisionDetailsTotals

  return (
    <>
      <DocumentFooterAmount
        label={'Total solicitado'}
        amount={totalRequired}
        currency={currency}
        className={' pr-3'}
      />
      <DocumentFooterAmount
        label={'Total aprobado'}
        amount={totalApproved}
        currency={currency}
        className={' pr-3'}
      />
      <DocumentFooterAmount
        label={'Total a reembolsar'}
        amount={totalRefund}
        currency={currency}
        className={' '}
      />
    </>
  )
}

export default DocumentFooterAmounts