import React, {useState} from 'react'
import Label from '../../../../../../shared/Label'
import Input from '../../../../../../shared/Input'

const DocumentHeaderDocumentType = (
  {
    document,
    setDocument,
    index,
    checkDuplicatedDocument,
    isEditable
  }
) => {

  const [externalCode, setExternalCode] = useState(document.externalCode)
  const [externalCodeDv, setExternalCodeDv] = useState(document.externalCodeDv)

  const handleBlurExternalCode = (event) => {
    let newDocument = document
    newDocument.externalCode = event.target.value
    if (newDocument.canBeSearched) {
      checkDuplicatedDocument(newDocument)
    }else {
      setDocument(index, newDocument, false, newDocument.canBeEvaluated, 100)
    }
  }

  const handleChangeExternalCode = (event) => {
    setExternalCode(event.target.value)
  }

  const handleBlurExternalCodeDv = (event) => {
    let newDocument = document
    newDocument.externalCodeDv = event.target.value
    setDocument(index, newDocument, false, newDocument.canBeEvaluated, 100)
  }

  const handleChangeExternalCodeDv = (event) => {
    setExternalCodeDv(event.target.value)
  }

  return (
    <>
      <div className={'row align-items-center'}>
        <Label htmlFor={'document_header_external_code_' + index} className={'col-12'}>Folio</Label>
        {isEditable ? (
          <>
            <div className={'col pr-1'}>
              <Input
                id={'document_header_external_code_' + index}
                value={externalCode}
                onChange={handleChangeExternalCode}
                onBlur={handleBlurExternalCode}
              />
            </div>
            <div className={'col p-0 flex-grow-0'}>
              -
            </div>
            <div className={'col flex-grow-0 pr-0 pl-1'}>
              <Input
                id={'document_header_external_code_dv_' + index}
                value={externalCodeDv}
                onChange={handleChangeExternalCodeDv}
                onBlur={handleBlurExternalCodeDv}
              />
            </div>
          </>
        ) : (
          <>
            <div className={'col pr-1'}>
              {externalCode || 'N/A'}
            </div>
            <div className={'col p-0 flex-grow-0'}>
              -
            </div>
            <div className={'col flex-grow-0 pr-0 pl-1'}>
              {externalCodeDv}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default DocumentHeaderDocumentType
