import React from 'react'
import DuplicatedDocumentReject from '../shared/DuplicatedDocumentReject'
import DuplicatedDocumentRejectButton from '../shared/DuplicatedDocumentRejectButton'
import DownloadAttachmentButton from '../shared/DownloadAttachmentButton'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import DocumentActionButton from '../shared/DocumentActionButton'

const AccountingDocument = (
  {
    document,
    index,
    setDocument,
    handleRemoveButtonClick,
    documents,
    setDocuments
  }
) => {
  const handleRemove = () => {
    handleRemoveButtonClick(document.systemId)
  }

  return (
    <div className={'accounting-document-resume-container w-100' + (document.hasDuplicatedDocumentReject ? ' accounting-document-resume-container__rejected' : '')}>
      <div className={'d-flex justify-content-between align-items-center accounting-document-resume w-100 p-3'}>
        <div className={'d-flex flex-column align-items-start'}>
          <div className={''}>
            {document.resume}
          </div>
          <DuplicatedDocumentReject
            {...
              {
                document
              }
            }
            className={''}
          />
        </div>
        <div className={'d-flex align-items-center justify-content-end'}>
          <DuplicatedDocumentRejectButton
            {...
              {
                document,
                index,
                setDocument,
                documents,
                setDocuments
              }
            }
          />
          <DownloadAttachmentButton
            {...
              {
                document
              }
            }
          />
          <DocumentActionButton
            onClickCallback={handleRemove}
            text={'Quitar'}
            icon={faTimes}
            className={'flex-column text-danger'}
          />
        </div>
      </div>
    </div>
  )
}

export default AccountingDocument