import React  from 'react'
import InitialFiles from './InitialFiles'
import ProtectedFiles from './ProtectedFiles'

const Files = (
  {
    protectedFiles,
    setProtectedFiles,
    initialProtectedFiles
  }
) => {

  const getProtectedFilesCount = () => {
    return protectedFiles.length + initialProtectedFiles.length
  }

  return (
    <div className={'form-row'}>
      {getProtectedFilesCount() > 0 ? (
        <>
          {initialProtectedFiles.length > 0 && (
            <div className={'col-12 form-row flex-nowrap overflow-auto mb-3'}>
              <InitialFiles
                initialProtectedFiles={initialProtectedFiles}
              />
            </div>
          )}
          {protectedFiles.length > 0 && (
            <div className={'col-12'}>
              <ProtectedFiles
                protectedFiles={protectedFiles}
                setProtectedFiles={setProtectedFiles}
              />
            </div>
          )}
        </>
      ) : (
        <p className={'empty_content '}>No hay adjuntos</p>
      )}
    </div>
  )
}

export default Files