import React, {useState} from 'react'
import Label from '../../../../../../shared/Label'
import Input from '../../../../../../shared/Input'
import dateToLiquidatorFormat from "../../../../../../utils/dateToLiquidatorFormat";
import dateToApiFormat from "../../../../../../utils/dateToApiFormat";

const DocumentHeaderDate = (
  {
    document,
    setDocument,
    index,
    checkDuplicatedDocument,
    isEditable
  }
) => {

  const [date, setDate] = useState(dateToLiquidatorFormat(document.date));

  const handleDateChange = (event) => {

    var formattedDate;
    const value = event.target.value.split("/").join("");
    if (value.length < 3) {
      formattedDate = value;
    } else if (value.length >= 3 && value.length < 5) {
      formattedDate = value.substring(0, 2) + "/" + value.substring(2);
    } else if (value.length >= 4 && value.length < 8) {
      formattedDate = value.substring(0, 2) + "/" + value.substring(2, 4) + "/" + value.substring(4);
    } else if (value.length >= 8) {
      formattedDate = value.substring(0, 2) + "/" + value.substring(2, 4) + "/" + value.substring(4);
    }
    setDate(formattedDate);
  }

  const handleDateBlur = () => {
    let newDocument = document
    newDocument.date = date ? dateToApiFormat(date) : ''
    if (newDocument.canBeSearched) {
      checkDuplicatedDocument(newDocument)
    }else {
      setDocument(index, newDocument, false, newDocument.canBeEvaluated, 100)
    }
  }

  return (
    <>
      <div className={'row'}>
        <Label htmlFor={'document_header_date_' + index} className={'col-12'}>Fecha</Label>
        <div className={'col-12'}>
          {isEditable ? (
            <Input
              id={"document_header_date_" + index}
              value={date}
              onChange={handleDateChange}
              onBlur={handleDateBlur}
              className={'form-control'}
              placeholder={'dd/mm/aaaa'}
            />
          ) : (
            <span id={"document_header_date_" + index} className="form-control">
              {date || 'N/A'}
            </span>
          )}
        </div>
      </div>
    </>
  )
}

export default DocumentHeaderDate
