export const duplicatedDocumentRejectCode = 5

export const lateDocumentRejectCode = 2

export const providerRejectCode = 3

export const backgroundsRejectCode = 19

export const provisionLimitRejectCode = 28

export const deductibleRejectCode = 22

export const provisionNotPerformedRejectCode = 26