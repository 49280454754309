import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const DocumentHeaderActionButton = (
  {
    onClickCallback,
    text,
    icon,
    className
  }
) => {
  return (
    <>
      <div
        className={'document-action-button d-flex justify-content-center align-items-center pointer-as-btn ' + className}
        onClick={onClickCallback}
        title={text}
      >
        <FontAwesomeIcon icon={icon} size={'xl'}/>
        <span className={'w-100 text-center'}>{text}</span>
      </div>
    </>
  )
}

export default DocumentHeaderActionButton