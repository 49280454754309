import rutFormat from './rutFormat'

const getInsureeInfoByBackendData = (data) => {
  let {rut, rut_dv, name, lastname, second_lastname} = data,
    resp = ''
  // Armemos la respuesta
  if (rut && rut !== '') {
    if (rut_dv && rut_dv !== '') {
      resp = rutFormat(rut + '' + rut_dv)
    }else {
      resp = rutFormat(rut)
    }
  }
  if (name && name !== '') {
    resp = resp !== '' ? (resp + ' - ' + name) : name
  }
  if (lastname && lastname !== '') {
    resp = resp !== '' ? (resp + ' ' + lastname) : lastname
  }
  if (second_lastname && second_lastname !== '') {
    resp = resp !== '' ? (resp + ' ' + second_lastname) : second_lastname
  }
  return resp
}

export default getInsureeInfoByBackendData