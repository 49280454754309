import React from 'react'

const Label = ({key, children, ...rest}) => {
  return (
    <label key={key} {...rest}>
      {children}
    </label>
  )
}

export default Label