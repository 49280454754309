import React from 'react'
import openInNewTab from '../../../../../../utils/openInNewTab'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExternalLink} from '@fortawesome/free-solid-svg-icons'

const DuplicatedDocumentReject = (
  {
    document,
    className
  }
) => {

  let reject = document.duplicatedDocumentRejects[0],
    requirement = reject !== undefined && reject !== null ? reject.requirement : null,
    active = reject !== undefined && reject !== null && reject.active

  const handleClick = () => {
    openInNewTab(requirement.url)
  }

  return (
    <>
      {document.hasDuplicatedDocumentReject && (
        <div className={className}>
          <div className={'duplicated-document-reject-container' + (active ? '' : ' duplicated-document-reject-container--manually-removed')}>
            <div className={'duplicated-document-reject-container__message'}>
              <strong>ATENCIÓN:</strong> el documento ya existe. Verificar si es un reingreso.
            </div>
            { requirement !== null && (
              <div className={'duplicated-document-reject-container__link'} onClick={handleClick}>
                Ver solicitud #{requirement.external_code} <FontAwesomeIcon icon={faExternalLink}/>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default DuplicatedDocumentReject