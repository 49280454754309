import React, {useCallback, useState} from 'react'
import axios from 'axios'
import AsyncSelect from 'react-select/async'
import {diagnosticsSearchApiUrl} from '../../../../routes/diagnostics'


const Diagnostics = (
  {
    requirement,
    setDiagnostic,
    diagnostic,
    setDiagnosticComment,
    diagnosticComment,
    isEditable
  }
) => {
  const [comment, setComment] = useState(diagnosticComment);
  const [inputValue, setInputValue] = useState('');
  let searchValue = inputValue,
  typingTimer,
  doneTypingInterval = 1000

  const promiseOptions = useCallback((inputValue) => {
    return new Promise((resolve) => {
      clearTimeout(typingTimer)
      typingTimer = setTimeout(() => {
        resolve(getDiagnostics(inputValue))
      }, doneTypingInterval)
    })
  }, [inputValue])
  
  const getDiagnostics = async (value) => {
    const body = { "icd_diagnostics": {"term": value} };
    // showLoading();
    return await axios({
      url: diagnosticsSearchApiUrl,
      method: 'POST',
      data: body
    })
      .then ((res) => {
        let data = res.data;
        let newDiagnostics = []
        newDiagnostics = getOptions(data)
        return newDiagnostics
      })
      .catch(err =>{
        // Error
        console.log(err);
      })
      .finally(() => {
        // closeAlert();
      })
  };

  const getOptions = (data) => {
    let options = [{value: '_destroy', label: 'Sin diagnóstico'}];
    data.forEach((diagnostic) => {
      options.push({
        value: diagnostic.id,
        label: diagnostic.code + ' - ' + diagnostic.name
      });
    });
    return options;
  };

  const handleInputChange = (value) => {
    setInputValue(value);
    // getDiagnostics(event.target.value);
  };

  const handleSelectedOptionChange = (selectedOption) => {
    setDiagnostic(selectedOption.value) // setear el id del diagnóstico

  };

  const handleDiagnosticCommentChange = (event) => {
    setComment(event.target.value);
    setDiagnosticComment(event.target.value);
  };

  return (
    <>
    <div className="d-flex justify-content-center">

      <div className='col-12 col-lg-6'>
        <div className="form-group">
          <label htmlFor="diagnostic">Diagnóstico</label>
          {isEditable ? (
              <AsyncSelect 
                placeholder="Busque un diagnóstico..."
                loadingMessage={() => "Cargando..."}
                noOptionsMessage={() => "No hay resultados"}
                cacheOptions
                loadOptions={promiseOptions}
                onInputChange={handleInputChange}
                onChange={handleSelectedOptionChange}
                defaultValue={diagnostic ? {value: diagnostic.id, label: `${diagnostic.code} - ${diagnostic.name}`} : null}
              />
            ) : (
              <p>{diagnostic ? `${diagnostic.code} - ${diagnostic.name}` : 'Sin diagnóstico'}</p>
            )}

            <label htmlFor="diagnostic-comments">Comentarios</label>
            {isEditable ? (
              <textarea 
                className="form-control" 
                id="diagnostic-comments" 
                onChange={handleDiagnosticCommentChange}
                defaultValue={comment}
              />
            ) : (
              <p>{comment || 'No hay comentarios'}</p>
            )}
        </div>
      </div>
    </div>
    </>
  );
}

export default Diagnostics;