import React from 'react'
import AccountingDocument from './AccountingDocument'
import AddAccountingDocumentButton from './AddAccountingDocumentButton'

const DocumentAccountingDocuments = (
  {
    document,
    setDocument,
    index,
    handleAddAccountingDocumentButtonClick,
    documents,
    setDocuments,
    isEditable
  }
) => {

  const handleRemoveButtonClick = (systemId) => {
    let newDocument = document,
      documentAccountingDocuments = newDocument.accountingDocuments
    newDocument.accountingDocuments = documentAccountingDocuments.filter((accountingDocument) => {
      return accountingDocument !== systemId
    })
    setDocument(index, newDocument)
  }

  const updateAccountingDocument = (newAccountingDocument) => {
    let newAccountingDocuments = documents.map((accountingDocument) => {
      if (accountingDocument.systemId === newAccountingDocument.systemId) {
        accountingDocument = newAccountingDocument
      }
      return accountingDocument
    })
    setDocuments(newAccountingDocuments)
  }

  return (
    <>
      {document.transformIdIntoAccountingDocuments(documents).map((accountingDocument) => (
        <AccountingDocument
          document={accountingDocument}
          index={accountingDocument.systemId}
          setDocument={updateAccountingDocument}
          handleRemoveButtonClick={handleRemoveButtonClick}
          key={accountingDocument.key}
          documents={documents}
          setDocuments={setDocuments}
        />
      ))}
      {isEditable &&
      <AddAccountingDocumentButton
        handleClick={handleAddAccountingDocumentButtonClick}
      />
      }
    </>
  )
}

export default DocumentAccountingDocuments