import React, {useState} from 'react'
import Async  from 'react-select/async'
import axios from 'axios'
import {
  provisionsApiUrl,
  provisionApiUrl
} from '../../../../../../routes/search'
import errorSwalAlert from '../../../../../../utils/errorSwalAlert'
import SelectLabelHighlighter from '../../../../../../shared/SelectLabelHighlighter'

const ProvisionDetailProvision = (
  {
    provisionDetail,
    setProvisionDetail,
    index,
    setShowProvisionDetailCommentsModal,
    insuranceType,
    isEditable
  }
) => {

  const insurerId = window.insurerId

  const [inputValue, setInputValue] = useState(provisionDetail.provisionName)

  let typingTimer,
    doneTypingInterval = 1000,
    searchValue = inputValue

  const buildFormData = (value) => {
    let formData = new FormData()
    formData.append('insurer_id', insurerId)
    formData.append('provision_name', value)
    formData.append('insurance_type', insuranceType)
    return formData
  }

  const getProvisions = async (value) => {
    return await axios({
      url: provisionsApiUrl,
      method: 'POST',
      data: buildFormData(value)
    })
    .then ((res) => {
      let data = res.data,
        newProvisions = []
      if (data.success) {
        newProvisions = getOptions(data.provisions)
      }
      return newProvisions
    })
    .catch(err =>{
      // Error
      errorSwalAlert('Ocurrió un error inesperado. Intente nuevamente')
      // Para trazabilidad
      console.log(err)
      return []
    })
  }

  const verifyNoCoveredProvision = async (provisionId) => {
    let formData = new FormData()
    formData.append('insurer_id', insurerId)
    formData.append('provision_id', provisionId)
    formData.append('insurance_type', insuranceType)
    return await axios({
      url: provisionApiUrl,
      method: 'POST',
      data: formData
    })
    .then ((res) => {
      let data = res.data
      return data.success && data.provision.isNoCoveredProvision
    })
    .catch(err =>{
      // Para trazabilidad
      console.log(err)
      return false
    })
  }

  const promiseOptions = (inputValue) => {
    clearTimeout(typingTimer)
    return new Promise((resolve) => {
      typingTimer = setTimeout(() => {
        resolve(getProvisions(inputValue))
      }, doneTypingInterval)
    })
  }

  const handleChange = (selected) => {
    let newProvisionDetail = provisionDetail,
      provisionId = selected.value,
      provisionName = selected.label.props.textToHighlight
    verifyNoCoveredProvision(provisionId).then((noCoveredProvision) => {
      newProvisionDetail.provisionId = provisionId
      newProvisionDetail.provisionName = provisionName
      newProvisionDetail.noCoveredProvision = noCoveredProvision
      setProvisionDetail(index, newProvisionDetail)
      setShowProvisionDetailCommentsModal(noCoveredProvision)
    })
  }

  const getOptions = (provisions) => {
    return provisions.map((provision) => {
      return {
        value: provision[1],
        label: <SelectLabelHighlighter
          searchWords={searchValue}
          textToHighlight={provision[0]}
          isRut={false}
        />
      }
    })
  }

  const handleInputChange = (value) => {
    searchValue = value
    setInputValue(value)
  }

  return (
    <>
      {isEditable ? (<Async
        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        className={'w-100 react-select-async'}
        placeholder={'Seleccione una prestación'}
        loadingMessage={() => 'Buscando prestaciones'}
        cacheOptions
        onChange={handleChange}
        loadOptions={promiseOptions}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        noOptionsMessage={()=> 'Prestación no encontrada'}
        classNamePrefix={'react-select'}
        defaultValue={provisionDetail.defaultProvisionSelectValues}
        />):(
          <div className={'w-100'}>
            {provisionDetail.provisionName}
          </div>
        )}
    </>
  )
}

export default ProvisionDetailProvision