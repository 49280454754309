import React, { useState, useEffect } from 'react'
import Quota from './Quota'
import IconButton from '@material-ui/core/IconButton'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import AddCircleIcon from "@material-ui/icons/AddCircle";

const PolicyClause = (props) => {

  const allProviders = props.providers
  const providerGroups = props.provider_groups
  const provisionGroups = props.provision_groups
  const evaluationTypes = props.evaluation_types

  const clause = props.policy_clause;
  const [quotas, setQuotas] = useState(clause.quotas ? clause.quotas : []);

  const [provider, setProvider] = useState(clause.provider_id ? clause.provider_id : null);
  const [insurerProvider, setInsurerProvider] = useState(clause.insurer_provider_group_id ? clause.insurer_provider_group_id : null);

  const [evaluationType, setEvaluationType] = useState(clause.evaluation_type ? clause.evaluation_type : 'all');
  
  const [providerOptions, setProviderOptions] = useState([]);
  const [providersQuantity, setProvidersQuantity] = useState('');
  const [disabledProviders, setDisabledProviders] = useState(null);

  const [provision, setProvision] = useState(clause.provision_id ? clause.provision_id : null);
  const [insurerProvisionGroup, setInsurerProvisionGroup] = useState(clause.insurer_provision_group_id ? clause.insurer_provision_group_id : null);
  const [provisionOptions, setProvisionOptions] = useState([]);
  
  const [provisionsCoverage, setProvisionsCoverage] = useState('');
  const [disabledProvisions, setDisabledProvisions] = useState(null);

  const insuranceType = clause.insurance_type;
  const [bond, setBond] = useState(clause.bond_coverage);
  const [refund, setRefund] = useState(clause.refund_coverage);
  const [noProvision, setNoProvision] = useState(clause.no_provision_coverage);
  const [tariffLimitFactor, setTariffLimitFactor] = useState(clause.tariff_limit_factor);
  const [limitCents, setLimitCents] = useState(clause.limit_cents);
  const [limitAmount, setLimitAmount] = useState(clause.limit_cents ? clause.limit_cents / props.subunit_to_unit : (clause.limit_cents == 0 ?"0.0" : clause.limit_cents));
  const [maxFrequency, setMaxFrequency] = useState(clause.max_frequency);
  const [bmi, setBmi] = useState(clause.bmi);
  const [fcp, setFcp] = useState(clause.free_choice_penalty);
  const [ageTo, setAgeTo] = useState(clause.age_to);
  const [ageFrom, setAgeFrom] = useState(clause.age_from);

  const [counter, setCounter] = useState(clause.quotas ? clause.quotas.length : 0);

  const getIndex = () => {
    const index_ = counter + 1;
    setCounter(index_ + 1);
    return index_;
  }

  const handleAddQuota = () => {
    const quota = {id: null, quota_type: 1, value_amount: null, index_: getIndex()}
    const newClauseObject = {
      ...clauseObject,
      quotas: [...quotas, quota]
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
    setQuotas([...quotas, quota])
  }

  const handleRemoveQuota = (e, index_) => {
    var position = quotas.findIndex(item => item && item.index_ === index_);
    if (position > -1){
      const newClauseObject = {
        ...clauseObject,
        quotas: quotas.filter(item => item.index_ != index_)
      }
      props.handleChangePolicyClause(props.index_, newClauseObject)
      setQuotas(quotas.filter(item => item.index_ != index_))
    }
  }

  const handleChangeQuota = (index_, element) => {
    var position = quotas.findIndex(item => item && item.index_ === index_);
    if (position > -1) {
      const newQuotas = quotas.map((q, i) => {
        if (i == position) {
          return element
        } else {
          return q
        }
      });
      const newClauseObject = {
        ...clauseObject,
        quotas: newQuotas
      }
      props.handleChangePolicyClause(props.index_, newClauseObject)
      setQuotas(newQuotas)
    }
  }

  const handleProvidersQuantity = (option) => {
    setProvidersQuantity(option)
    if (option == 'all') {
      setProviderOptions([])
      setDisabledProviders(true)
      handleProvider(null)
    } else if (option == 'one') {
      setProviderOptions(allProviders)
      setDisabledProviders(false)
      handleProvider(allProviders.length > 0 ? allProviders[0].id : null)
    } else if (option == 'group') {
      setProviderOptions(providerGroups)
      setDisabledProviders(false)
      handleInsurerProvider(providerGroups.length > 0 ? providerGroups[0].id : null)
    }
  }

  const handlePrevProvidersQuantity = (option) => {
    setProvidersQuantity(option)
    if (option == 'all') {
      setProviderOptions([])
      setDisabledProviders(true)
    } else if (option == 'one') {
      setProviderOptions(allProviders)
      setDisabledProviders(false)
    } else if (option == 'group') {
      setProviderOptions(providerGroups)
      setDisabledProviders(false)
    }
  }

  const handleProvisionsCoverage = (option) => {
    setProvisionsCoverage(option)
    if (option == 'all') {
      setProvisionOptions([])
      setDisabledProvisions(true)
      handleProvision(null)
    } else if (option == 'coverage') {
      setProvisionOptions(provisionGroups)
      setDisabledProvisions(false)
      let correct_provision_group = provisionGroups[0]
      handleProvisionGroup(insurerProvisionGroup ? insurerProvisionGroup : ( correct_provision_group ? correct_provision_group.id : null ))
    }
  }

  const handlePrevProvisionsCoverage = (option) => {
    setProvisionsCoverage(option)
    if (option == 'all') {
      setProvisionOptions([])
      setDisabledProvisions(true)
    } else if (option == 'coverage') {
      setProvisionOptions(provisionGroups)
      setDisabledProvisions(false)
    }
  }

  const deleteClause = (event, index) => {
    props.handleRemovePolicyClause(event, index);
  }

  const handleProvisionSelector = (value) => {
    if (provisionsCoverage == "coverage") {
      handleProvisionGroup(value)
    } else {
      handleProvision(value)
    }
  }

  const handleProvision = (value) => {
    setProvision(value)
    setInsurerProvisionGroup(null)
    const newClauseObject = {
      ...clauseObject,
      provision_id: value,
      insurer_provision_group_id: null
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleProvisionGroup = (value) => {
    setProvision(null)
    setInsurerProvisionGroup(value)
    const newClauseObject = {
      ...clauseObject,
      provision_id: null,
      insurer_provision_group_id: value
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleProviderSelector = (value) => {
    if (providersQuantity == "one") {
      handleProvider(value)
    } else if (providersQuantity == "group") {
      handleInsurerProvider(value)
    }
  }

  const handleProvider = (value) => {
    setProvider(value)
    setInsurerProvider(null)
    const newClauseObject = {
      ...clauseObject,
      provider_id: value,
      insurer_provider_group_id: null
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleInsurerProvider = (value) => {
    setProvider(null)
    setInsurerProvider(value)
    const newClauseObject = {
      ...clauseObject,
      provider_id: null,
      insurer_provider_group_id: value
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleEvaluationTypeSelector = (value) => {
    setEvaluationType(value);
    const newClauseObject = {
      ...clauseObject,
      evaluation_type: value
    }
    props.handleChangePolicyClause(props.index_, newClauseObject);
  };

  const handleBond = (value) => {
    setBond(value)
    const newClauseObject = {
      ...clauseObject,
      bond_coverage: value
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleRefund = (value) => {
    setRefund(value)
    const newClauseObject = {
      ...clauseObject,
      refund_coverage: value
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleNoProvision = (value) => {
    setNoProvision(value)
    const newClauseObject = {
      ...clauseObject,
      no_provision_coverage: value
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleTariffLimitFactor = (value) => {
    setTariffLimitFactor(value)
    const newClauseObject = {
      ...clauseObject,
      tariff_limit_factor: value
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleLimitCents = (value) => {
    let limit_cent = value ? value*props.subunit_to_unit : (value === "" ? null : 0)
    setLimitCents(limit_cent)
    setLimitAmount(value)
    const newClauseObject = {
      ...clauseObject,
      limit_cents: limit_cent
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleMaxFrequency = (value) => {
    setMaxFrequency(value)
    const newClauseObject = {
      ...clauseObject,
      max_frequency: value
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleFreeChoicePenalty = (value) => {
    setFcp(value)
    const newClauseObject = {
      ...clauseObject,
      free_choice_penalty: value
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleNotRepeatYourSelf = (key, value, setter) => {
    setter(value)
    const newClauseObject = {
      ...clauseObject,
    }
    newClauseObject[key] = value
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleAgeFrom = (value) => {
    setAgeFrom(value);
    const newClauseObject = {
      ...clauseObject,
      age_from: value
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  const handleAgeTo = (value) => {
    setAgeTo(value);
    const newClauseObject = {
      ...clauseObject,
      age_to: value
    }
    props.handleChangePolicyClause(props.index_, newClauseObject)
  }

  var clauseObject = {
    id: clause.id,
    insurance_type: insuranceType,
    provider_id: provider,
    insurer_provider_group_id: insurerProvider,
    provision_id: provision,
    insurer_provision_group_id: insurerProvisionGroup,
    bond_coverage: bond,
    refund_coverage: refund,
    no_provision_coverage: noProvision,
    bmi: bmi,
    free_choice_penalty: fcp,
    evaluation_type: evaluationType,
    tariff_limit_factor: tariffLimitFactor,
    limit_cents: limitCents,
    max_frequency: maxFrequency,
    age_to: ageTo,
    age_from: ageFrom,
    quotas: quotas,
    index_: props.index_
  }

  const selectedProvider = () => {
    if (provider != null){
      return provider
    } else if (insurerProvider != null){
      return insurerProvider
    } else {
      return ''
    }
  }

  const selectedProvision = () => {
    if (provision != null){
      return provision
    } else if (insurerProvisionGroup != null){
      return insurerProvisionGroup
    } else {
      return ''
    }
  }

  const selectedEvaluationType = () => {
    //return evaluationType
    if (evaluationType != null) {
      return evaluationType
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (provider != null) {
      handlePrevProvidersQuantity("one")
    } else if (insurerProvider != null) {
      handlePrevProvidersQuantity("group")
    } else {
      handlePrevProvidersQuantity("all")
    }
    if (provision != null || insurerProvisionGroup != null) {
      handlePrevProvisionsCoverage("coverage")
    } else {
      handlePrevProvisionsCoverage("all")
    }
  }, [])

  return (
    <div className='policy-clause-form mt-2'>
      <div className='conditioned-container'>
        <div className='conditioned-delete'>
          <IconButton onClick={e => deleteClause(e, props.index_)}>
            <DeleteForeverIcon className='clear-icon' />
          </IconButton>
        </div>
        <div className='conditioned-form'>
          <div className='providers-section input-group'>
            <select className='selector form-control' value={providersQuantity} onChange={e => handleProvidersQuantity(e.target.value)}>
              <option value="all" defaultValue={providersQuantity == "all" ? true : false}>Todos</option>
              <option value="group" defaultValue={providersQuantity == "group" ? true : false}>Grupo</option>
              {/*Se deja la siguiente opción comentada para incluirla posteriormente*/}
              {/*<option value="one" defaultValue={providersQuantity == "one" ? true : false}>Uno</option>*/}
            </select>
            <select className='provider-selector form-control' value={selectedProvider()} placeholder="Selecciona un prestador" disabled={disabledProviders} onChange={e => handleProviderSelector(e.target.value)}>
              {
                providerOptions.map((pv, index) => (
                  <option value={pv.id} key={index} defaultValue={pv.id == selectedProvider() ? true : false}>{pv.id} - {pv.name}</option>
                ))
              }
            </select>
          </div>
          <div className='provisions-section input-group'>
            <select className='selector form-control' value={provisionsCoverage} onChange={e => handleProvisionsCoverage(e.target.value)}>
              <option value="all" defaultValue={provisionsCoverage == "all" ? true : false}>Todas</option>
              <option value="coverage" defaultValue={provisionsCoverage == "coverage" ? true : false}>Cobertura</option>
            </select>
            <select className='provision-selector form-control' onChange={e => handleProvisionSelector(e.target.value)} disabled={disabledProvisions} value={selectedProvision()}>
              {
                provisionOptions.map((pv, index) => {
                  return <option value={pv.id} key={index} defaultValue={pv.id == selectedProvision() ? true : false}>{pv.code ? pv.code : pv.id} - {pv.name}</option>
                })
              }
            </select>
          </div>
          {insuranceType == "health" &&
            <div className='evaluation-type-section input-group'>
              <select className='evaluation-type-selector form-control' onChange={e => handleEvaluationTypeSelector(e.target.value)} value={selectedEvaluationType()}>
                {
                  evaluationTypes.map((pt, index) => {
                    return <option value={pt.id} key={index} defaultValue={pt.id == selectedEvaluationType() ? true : false}>{pt.name}</option>
                  })
                }
              </select>
            </div>
          }
          {insuranceType == "health" &&
            <div className='coverage-section input-group'>
              <input className='coverage-input form-control' type="number" value={bond} onChange={e => handleBond(e.target.value)} />
              <input className='coverage-input form-control' type="number" value={refund} onChange={e => handleRefund(e.target.value)} />
              <input className='coverage-input form-control' type="number" value={noProvision} onChange={e => handleNoProvision(e.target.value)} />
            </div>
          }

          {insuranceType == "dental" &&
            <div className='dental-coverage-section input-group'>
              <input className='coverage-input form-control' type="number" value={noProvision} onChange={e => handleNoProvision(e.target.value)} />
            </div>
          }

          {insuranceType == "health" &&
            <div className='bmi-section'>
              <input className='form-control' type="number" value={bmi} placeholder={"-"} onChange={e => handleNotRepeatYourSelf("bmi", e.target.value, setBmi)} />
            </div>
          }
          {insuranceType == "health" &&
            <div className='fcp-section'>
              <input className='form-control' type="number" value={fcp} placeholder={"-"} onChange={e => handleFreeChoicePenalty(e.target.value)} />
            </div>
          }
          <div className='arancel-section'>
            <input className='arancel-input form-control' type="number" value={tariffLimitFactor} placeholder={"-"} onChange={e => handleTariffLimitFactor(e.target.value)} />
          </div>
          <div className='limit-section'>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">{props.symbol}</span>
              </div>
              <input className='limit-input form-control' type="number" value={limitAmount } placeholder={"#.##"} onChange={e => handleLimitCents(e.target.value)} />
            </div>
          </div>
          <div className='maximum-section'>
            <input className='maximum-input form-control' type="number" value={maxFrequency} placeholder={"-"} onChange={e => handleMaxFrequency(e.target.value)} />
          </div>
          <div className='age-range-section'>
            <input className='age-from-input form-control' type="number" value={ageFrom} placeholder={"-"} onChange={e => handleAgeFrom(e.target.value)} />
            <p className='ml-1 mr-1 mt-1'>a</p>
            <input className='age-to-input form-control' type="number" value={ageTo} placeholder={"-"} onChange={e => handleAgeTo(e.target.value)} />
          </div>
        </div>
      </div>
      <div className='quota-section'>
        { quotas.map((quota, index) => (
          <div className='quota-field'>
            <Quota key={quota.index_} index_={quota.index_} quota_type={quota.quota_type} value_amount={quota.value_amount} symbol={props.symbol} handleRemoveQuota={handleRemoveQuota} handleChangeQuota={handleChangeQuota}/>
          </div>
        )) }
        <div className='quota-button mt-2'>
          <button type="button" className="btn action-btn btn-gerty-font" onClick={handleAddQuota}>
            <AddCircleIcon /> Agregar cupo
          </button>
        </div>
      </div>
    </div>
  )
}
export default PolicyClause;