import React, {useRef} from 'react'
import closeAlert from '../../../../../utils/closeAlert'
import showLoading from '../../../../../utils/showLoading'
import axios from 'axios'
import Swal from 'sweetalert2'
import Input from '../../../../../shared/Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCloudUpload,
  faFileDownload
} from '@fortawesome/free-solid-svg-icons'

const FileActionButtons = (
  {
    downloadConsolidatedUrl,
    protectedFiles,
    setProtectedFiles,
    initialProtectedFiles,
    isChilenaRojo
  }
) => {

  const inputRef = useRef(null)

  const handleAddFileBtnClick = () => {
    inputRef.current.click()
  }

  const addProtectedFile = (protectedFile) => {
    setProtectedFiles([...protectedFiles, protectedFile])
  }

  const uploadFile = async (file) => {
    let formData = new FormData()
    formData.append('protected_file[file]', file)
    formData.append('protected_file[owner_id]', window.requirementId)
    $('form input[name=utf8],form input[name=authenticity_token]').each(function () {
      let $elem = $(this)
      formData.append($elem.attr('name'),$elem.val())
    })
    showLoading()
    let error = false,
      errorMsg = 'Ocurrió un inconveniente al subir el archivo. Intente nuevamente'
    await axios({
      url: window.location.origin + '/insurers/' + window.insurerId + '/protected_files/' + (window.isBudget ? 'Budget' : 'Expense'),
      method: 'POST',
      data: formData,
      headers:{
        'Content-Type':'multipart/form-data',
        'Accept': 'application/json'
      }
    })
      .then ((res) => {
        let data = res.data
        addProtectedFile(data)
      })
      .catch(err =>{
        // Error, avisamos al usuario
        switch (err.code) {
          case 'ERR_BAD_REQUEST':
            errorMsg = 'No pudimos realizar la acción solicitada. Pudo haber perdido la sesión. Revise y vuelva a intentar'
            break
          case 'ERR_NETWORK':
            errorMsg = 'No pudimos realizar la acción solicitada. Revise su conexión a internet y vuelva a intentar'
            break
        }
        error = true
      })
      .finally(() => {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errorMsg,
            confirmButtonColor: '#0281c4'
          })
        }else {
          closeAlert()
        }
      })
  }

  const handleFileChange = (event) => {
    const file = event.target.files && event.target.files[0]
    if (!file) {
      return
    }
    // reseteamos el valor del input
    event.target.value = null
    // enviamos archivo
    uploadFile(file)
  }

  const handleReloadFromChilenaBtnClick = () => {
    // TODO: Preguntar que debe hacer este botón
  }

  return (
    <>
      <Input
        type={'file'}
        className={'d-none'}
        ref={inputRef}
        onChange={handleFileChange}
      />
      <div
        className={'btn btn-success'}
        onClick={handleAddFileBtnClick}
      >
        <FontAwesomeIcon icon={faCloudUpload}/> Subir archivo adjunto
      </div>
      {downloadConsolidatedUrl && initialProtectedFiles.length > 0 && (
        <a href={downloadConsolidatedUrl} className={'btn btn-primary ml-3'} target={'_blank'}>
          <FontAwesomeIcon icon={faFileDownload}/> Descargar todos
        </a>
      )}
      {isChilenaRojo && (
        <div
          className={'btn btn-warning ml-3'}
          onClick={handleReloadFromChilenaBtnClick}
        >
          Recargar desde Chilena
        </div>
      )}
    </>
  )
}

export default FileActionButtons