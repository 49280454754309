import React from 'react'

const FormAlert = (
  {
    message,
    className
  }
) => {
  return (
    <>
      {message !== '' && (
        <div className={'invalid-feedback d-block ' + (className || 'col-12')}>
          {message}
        </div>
      )}
    </>
  )
}

export default FormAlert