import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const FormActionButton = (
  {
    title,
    className,
    onClick,
    icon,
    content,
    disabled
  }
) => {

  return (
    <>
      <div className={'btn-container'} disabled={disabled}>
        <div
          className={'btn edit-form-button ' + className}
          title={title}
          onClick={onClick}
        >
          {icon && (
            <FontAwesomeIcon icon={icon} className={'mr-2'}/>
          )}<span>{content}</span>
        </div>
      </div>
    </>
  )
}

export default FormActionButton