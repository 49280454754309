import React from 'react'
import ImageWithPreview from '../../../../../shared/ImageWithPreview'
import openInNewTab from '../../../../../utils/openInNewTab'

const InitialFile = (
  {
    initialFile
  }
) => {

  const fileContentType = initialFile.file_content_type
  const fileFileName = initialFile.file_file_name
  const fileUrl = initialFile.url

  const handleOnClick = () => {
    openInNewTab(fileUrl)
  }

  return (
    <div
      className={'p-3 col'}
    >
      <div
        className={'d-flex flex-column justify-content-center align-items-center initial-file pointer-as-btn text-info'}
        onClick={handleOnClick}
      >
        <ImageWithPreview
          contentType={fileContentType}
          fileName={fileFileName}
          fileSrc={fileUrl}
        />
      </div>
    </div>
  )
}

export default InitialFile