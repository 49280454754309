import React, {useState} from 'react'
import DocumentActionButton from '../shared/DocumentActionButton'
import {
  faCalendarTimes,
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons'
import {
  backgroundsRejectCode,
  lateDocumentRejectCode
} from '../../../../../../utils/constants'
import DocumentFooterBackgroundsModal from './DocumentFooterBackgroundsModal'
import DuplicatedDocumentRejectButton from '../shared/DuplicatedDocumentRejectButton'

const DocumentFooterRejects = (
  {
    document,
    setDocument,
    index,
    formAlerts,
    backgroundOptions,
    documents,
    setDocuments,
    isEditable
  }
) => {

  let lateDocumentRejects = document.lateDocumentRejects

  const [showBackgroundsModal, setShowBackgroundsModal] = useState(false)

  const isDisabledLateDocument = () => {
    return lateDocumentRejects.length === 0
  }

  const isDisabledBackgrounds = () => {
    let activeRejects = document.rejectDetails.filter((reject) => reject.code !== backgroundsRejectCode && reject.active)
    return document.provisionDetails.length === 0 || activeRejects.length > 0
  }

  const updateProvisionDetails = (newDocument) => {
    newDocument.provisionDetails.map((item, index) => {
      setProvisionDetail(index, item)
    })
  }

  const handleLateDocumentClick = () => {
    if (!isDisabledLateDocument()) {
      let newDocument = document,
        rejectDetails = document.rejectDetails
      lateDocumentRejects[0].active = !lateDocumentRejects[0].active
      newDocument.rejectDetails = [... rejectDetails.filter((rejectDetail) => rejectDetail.code !== lateDocumentRejectCode), lateDocumentRejects[0]]
      setDocument(index, newDocument)
      updateProvisionDetails(newDocument)
    }
  }

  const handleBackgroundsClick = () => {
    if (!isDisabledBackgrounds() && isEditable) {
      setShowBackgroundsModal(true)
    }
  }

  const disabledClass = (button) => {
    let isDisabled
    switch (button) {
      case 'late_document':
        isDisabled = isDisabledLateDocument()
        break;
      case 'backgrounds':
        isDisabled = isDisabledBackgrounds()
        break;
      default:
        isDisabled = false
    }
    return isDisabled ? ' document-action-button--disabled' : ''
  }

  const isActiveLateDocument = () => {
    return lateDocumentRejects.length > 0 ? !!lateDocumentRejects[0].active : true
  }

  const activeClass = (button) => {
    let isActive
    switch (button) {
      case 'late_document':
        isActive = isActiveLateDocument()
        break;
      default:
        isActive = true
    }
    return isActive ? '' : ' document-action-button--inactive'
  }

  // Forzamos la actualización de las prestaciones cuando hay rechazo de documento
  const setProvisionDetail = (key, value) => {
    let newDocument = document,
      newEvaluation = false
    newDocument.provisionDetails = document.provisionDetails.map((item) => {
      newEvaluation = item.canBeEvaluated
      return value
    })
    setDocument(index, newDocument, false, newEvaluation)
  }

  return (
    <>
      <div className={'pr-3'}>
        Rechazos:
      </div>
      <DocumentActionButton
        onClickCallback={handleLateDocumentClick}
        text={'Doc. fuera de plazo'}
        icon={faCalendarTimes}
        className={'text-info flex-column pr-3 document-action-button--fit-content' + disabledClass('late_document') + activeClass('late_document')}
      />
      <DuplicatedDocumentRejectButton
        {...
          {
            document,
            index,
            setDocument,
            documents,
            setDocuments,
            setProvisionDetail
          }
        }
      />
      <DocumentActionButton
        onClickCallback={handleBackgroundsClick}
        text={'Antecedentes'}
        icon={faMagnifyingGlass}
        className={
          `text-info flex-column flex-grow-0 document-action-button--fit-content${disabledClass('backgrounds')}${!isEditable ? ' document-action-button--disabled' : ''}`
        }
      />
      <DocumentFooterBackgroundsModal
        {...
          {
            document,
            setDocument,
            index,
            backgroundOptions,
            showBackgroundsModal,
            setShowBackgroundsModal,
            setProvisionDetail
          }
        }
        title={'Rechazo: Falta de antecedentes / Observaciones'}
      />
    </>
  )
}

export default DocumentFooterRejects