import React, {useState} from 'react'
import Select from 'react-select'


const ProvisionDetailHealthProvisionTypeSelector = (
  {
    provisionDetail,
    setProvisionDetail,
    index,
    healthProvisionTypes,
    isEditable
  }
) => {

  const [healthProvisionType, setHealthProvisionType] = useState(provisionDetail.healthProvisionType)
  const handleOnChange = (value) => {
    let newProvisionDetail = provisionDetail
    setHealthProvisionType(value.value)
    newProvisionDetail.healthProvisionType = value.value
    setProvisionDetail(index, newProvisionDetail)
  }

  const getHealthProvisionTypeName = (healthProvisionType) => {
    let healthProvisionTypeName = ''
    healthProvisionTypes.forEach((type) => {
      if (type.value === healthProvisionType) {
        healthProvisionTypeName = type.label
      }
    })
    return healthProvisionTypeName
  }

  return (
    <>
      {isEditable ? (<Select
        options={healthProvisionTypes}
        value={healthProvisionTypes.filter(function(option) {
          return option.value === healthProvisionType
        })}
        onChange={handleOnChange}
        classNamePrefix={'react-select'}
        placeholder={'Seleccione'}
        styles={{
          menu: (base) => ({
            ...base,
            width: "max-content",
            minWidth: "100%",
            left: 0,
       }),
        }}
      />) : (
        <div>
          {getHealthProvisionTypeName(healthProvisionType)}
        </div>
      )}
    </>
  )
}

export default ProvisionDetailHealthProvisionTypeSelector