import React from 'react'
import ImageWithPreview from '../../../../../../shared/ImageWithPreview'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExternalLink} from '@fortawesome/free-solid-svg-icons'
import openInNewTab from '../../../../../../utils/openInNewTab'

const ShowAttachment = (
  {
    attachment,
    handleOnClick,
    selected
  }
) => {

  const fileContentType = attachment.file_content_type
  const fileFileName = attachment.file_file_name
  const fileUrl = attachment.url

  const getSelectedClass = () => {
    return selected ? ' initial-file--selected' : ''
  }

  const handleOnClickIcon = () => {
    openInNewTab(fileUrl)
  }

  return (
    <div
      className={'col'}
    >
      <div
        className={'d-flex flex-column justify-content-center align-items-center initial-file pointer-as-btn' + getSelectedClass()}
        onClick={handleOnClick}
        data-attachment={JSON.stringify(attachment)}
      >
        <ImageWithPreview
          contentType={fileContentType}
          fileName={fileFileName}
          fileSrc={fileUrl}
        />
      </div>
      <FontAwesomeIcon
        icon={faExternalLink}
        onClick={handleOnClickIcon}
        title={'Descargar'}
        className={'document-header__download-attachment-icon text-primary pointer-as-btn'}
      />
    </div>
  )
}

export default ShowAttachment