import removeAlertByField from './removeAlertByField'

const addAlert = (alerts, container, field_id, message, type) => {
  let newAlert = {
    'container': container,
    'field_id': field_id,
    'type': type,
    'message': message
  }
  let newAlerts = removeAlertByField(alerts, field_id)
  newAlerts = [...newAlerts, newAlert]
  return newAlerts
}

export default addAlert