import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Comments from '../../../../../../shared/Comments'

const DocumentHeaderCommentsModal = (
  {
    document,
    setDocument,
    showCommentsModal,
    setShowCommentsModal,
    index
  }
) => {

  const handleClose = () => setShowCommentsModal(false)

  let comments = document.comments

  const setComments = (newComments) => {
    let newDocument = document
    newDocument.comments = newComments
    setDocument(index, newDocument, false)
  }

  return (
    <>
      <Modal show={showCommentsModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Ingresar comentarios</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Este comentario aparecerá en algunas cartas, junto a información del documento</p>
          <Comments
            {...
              {
                comments,
                setComments
              }
            }
            placeholder={'Ingresa tus comentarios'}
          />
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={'btn btn-light'}
            onClick={handleClose}
          >
            Cerrar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DocumentHeaderCommentsModal