import simpleRequest from './simpleRequest'

const rejectRequest = async (data, url, redirect) => {
  simpleRequest(
    data,
    url,
    'Operación realizada con exito. Espere unos momentos mientras es redirigido.',
    'Ocurrió un error inesperado al intentar aplicar el rechazo. Intente nuevamente',
    redirect
  )
}

export default rejectRequest