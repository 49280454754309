import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import numberToCurrency from '../../../../../../utils/numberToCurrency'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const ProvisionDetailRejectsModal = (
  {
    provisionDetail,
    setProvisionDetail,
    index,
    showProvisionDetailRejectsModal,
    setShowProvisionDetailRejectsModal,
    currency,
    handleConfirm,
    isEditable
  }
) => {

  const [rejectDetails, setRejectDetails] = useState(provisionDetail.rejectDetails)

  const handleClose = () => {
    setShowProvisionDetailRejectsModal(false)
  }

  const handleToggleActiveButtonClick = (checked, reject) => {
    let newRejectDetails = rejectDetails
    newRejectDetails = newRejectDetails.map((item) => {
      if (item.systemId === reject.systemId) {
        item.active = checked
      }
      return item
    })
    setRejectDetails(newRejectDetails)
  }

  const handleConfirmBtnClick = () => {
    let newProvisionDetail = provisionDetail
    newProvisionDetail.rejectDetails = rejectDetails
    setProvisionDetail(index, newProvisionDetail)
    handleConfirm()
  }

  return (
    <>
      <Modal
        show={showProvisionDetailRejectsModal}
        onHide={handleClose}
        dialogClassName={'mw-100 w-100 px-3'}
        className={'provision-detail-rejects-modal'}
      >
        <Modal.Header>
          <Modal.Title>Rechazos</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <table className={'table table-bordered table-hover'}>
            <thead>
              <tr className={'bg-secondary text-light'}>
                <th scope={'col'}></th>
                <th scope={'col'}>Código</th>
                <th scope={'col'}>Descripción</th>
                <th scope={'col'}>Comentario</th>
                <th scope={'col'}>Monto<br></br>Rechazado</th>
              </tr>
            </thead>
            <tbody>
              {rejectDetails.length > 0 ? (
                <>
                  {rejectDetails.map((rejectDetail) => (
                    <tr key={rejectDetail.systemId}>
                      <td scope={'col'} className={'d-flex justify-content-center'}>
                        <BootstrapSwitchButton
                          onlabel={'Activo'}
                          offlabel={'Inactivo'}
                          checked={rejectDetail.active}
                          onstyle={'danger'}
                          offstyle={'secondary'}
                          width={150}
                          onChange={(checked) => {
                            handleToggleActiveButtonClick(checked, rejectDetail)
                          }}
                          disabled={!isEditable}
                        />
                      </td>
                      <td scope={'col'}>
                        {rejectDetail.code}
                      </td>
                      <td scope={'col'}>
                        {rejectDetail.description}
                      </td>
                      <td scope={'col'}>
                        {rejectDetail.comment}
                      </td>
                      <td scope={'col'}>
                        {numberToCurrency(rejectDetail.value || 0, currency)}
                      </td>
                    </tr>
                    )
                  )}
                </>
              ) : (
                <tr>
                  <td colSpan={5}>
                    <strong>No existen rechazos para esta linea</strong>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={'btn btn-primary'}
            onClick={handleConfirmBtnClick}
          >
            Aceptar
          </div>
          <div
            className={'btn btn-light'}
            onClick={handleClose}
          >
            Cerrar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ProvisionDetailRejectsModal