import React from 'react'
import ProvisionDetailRemove from './ProvisionDetailRemove'
import ProvisionDetailProvision from './ProvisionDetailProvision'
import ProvisionDetailSimpleInput from './ProvisionDetailSimpleInput'
import ProvisionDetailRejects from './ProvisionDetailRejects'
import ProvisionDetailCommentsModal from './ProvisionDetailCommentsModal'
import numberToCurrency from '../../../../../../utils/numberToCurrency'
import ProvisionDetailDentalFieldSelector from './ProvisionDetailDentalFieldSelector'
import ProvisionDetailDate from './ProvisionDetailDate'

const DentalProvisionDetail = (
  {
    provisionDetail,
    setProvisionDetail,
    removeProvisionDetail,
    index,
    currency,
    prescriptions,
    setPrescriptions,
    attachments,
    policyId,
    insuree,
    policy,
    requirementType,
    showProvisionDetailCommentsModal,
    setShowProvisionDetailCommentsModal,
    getApprovedValue,
    getTariffLimitFactor,
    getRefundValue,
    getReferenceValue,
    toothOptions,
    sideOptions,
    isEditable,
    isFullInsurer
  }
) => {

  return (
    <tr>
      <th className={'provision-details-table__row'} scope={'row'}>
        {isEditable && <ProvisionDetailRemove
          {...
            {
              removeProvisionDetail,
              index
            }
          }
        />}
      </th>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailProvision
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              setShowProvisionDetailCommentsModal,
              isEditable
            }
          }
          insuranceType={requirementType}
        />
      </td>
      <td className={'provision-details-table__col--dental-fields'}>
        <ProvisionDetailDentalFieldSelector
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              isEditable
            }
          }
          fieldOptions={toothOptions}
          field="tooth"
        />
      </td>
      <td className={'provision-details-table__col--dental-fields'}>
        <ProvisionDetailDentalFieldSelector
            {...
              {
                provisionDetail,
                setProvisionDetail,
                index,
                isEditable
              }
            }
          fieldOptions={sideOptions}
          field="sides" 
        />
      </td>
      <td className={'provision-details-table__row--date'}>
        <ProvisionDetailDate
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              isEditable
            }
          }
        />
      </td>
      <td className={'provision-details-table__row'}>{getTariffLimitFactor()}</td>
      <td className={'provision-details-table__row'}>{getReferenceValue()}</td>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailSimpleInput
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              currency,
              isEditable
            }
          }
          insuranceType={"dental"}
          property={'requiredAmount'}
          money={true}
        />
      </td>
      <td className={'provision-details-table__row'}>{numberToCurrency(0, currency)}</td>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailSimpleInput
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              currency,
              isEditable
            }
          }
          property={'totalAmount'}
          money={true}
        />
      </td>
      <td className={'provision-details-table__col--quantity-fields'}>
        <ProvisionDetailSimpleInput
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              currency,
              isEditable
            }
          }
          property={'requiredQuantity'}
          money={false}
        />
      </td>
      <td className={'provision-details-table__col--quantity-fields'}>
        <ProvisionDetailSimpleInput
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              currency,
              isEditable
            }
          }
          property={'approvedQuantity'}
          money={false}
        />
      </td>
      <td className={'provision-details-table__row'}>{getApprovedValue()}</td>
      <td className={'provision-details-table__row'}>{getRefundValue()}</td>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailRejects
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              prescriptions,
              setPrescriptions,
              currency,
              setShowProvisionDetailCommentsModal,
              attachments,
              policyId,
              insuree,
              policy,
              isEditable,
              isFullInsurer
            }
          }
        />
      </td>
      {showProvisionDetailCommentsModal && (
        <ProvisionDetailCommentsModal
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              showProvisionDetailCommentsModal,
              setShowProvisionDetailCommentsModal
            }
          }
        />
      )}
    </tr>
  )
}

export default DentalProvisionDetail