import React from 'react'
import DocumentActionButton from '../shared/DocumentActionButton'
import {faTimes} from '@fortawesome/free-solid-svg-icons'

const DocumentHeaderRemoveDocument = (
  {
    removeDocument,
    index
  }
) => {
  const handleOnClick = () => {
    removeDocument(index)
  }
  return (
    <>
      <DocumentActionButton
        onClickCallback={handleOnClick}
        text={'Eliminar'}
        icon={faTimes}
        className={'text-danger flex-column'}
      />
    </>
  )
}

export default DocumentHeaderRemoveDocument