import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faFileDownload
} from '@fortawesome/free-solid-svg-icons'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import convertStringToDate from '../../../../../../../utils/convertStringToDate'
import moment from 'moment/moment'

const PrescriptionsTable = (
  {
    prescriptions,
    newPrescription,
    setNewPrescription,
    selectedRowId,
    setSelectedRowId,
    selectedPage,
    setSelectedPage,
    getFirstPage,
    linkInsureePrescription,
    setLinkInsureePrescription,
    isEditable
  }
) => {

  const handleNewPrescriptionBtnClick = () => {
    setNewPrescription(true)
  }

  const handleLinkInsureePrescriptionBtnClick = () => {
    setLinkInsureePrescription(true)
  }

  const dateSorting = (a, b, order) => {
    let dateA = moment(a, 'DD/MM/YYYY'),
      dateB = moment(b, 'DD/MM/YYYY')
    if (order === "asc") {
      return dateA - dateB
    } else if (order === "desc") {
      return dateB - dateA
    }
  }

  const columns = [
    {
      dataField: "id",
      text: "Id",
      hidden: true,
      sort: true
    },
    {
      dataField: "date",
      text: "Fecha",
      sort: true,
      sortFunc: dateSorting
    },
    {
      dataField: "expiration_date",
      text: "Fecha de expiración",
      sort: true,
      sortFunc: dateSorting
    },
    {
      dataField: "provider_business_id",
      text: "Identificador Tributario Profesional",
      sort: true
    },
    {
      dataField: "provider_name",
      text: "Nombre",
      sort: true
    },
    {
      dataField: "description",
      text: "Descripción"
    },
    {
      dataField: "actions",
      text: "Acciones"
    }
  ]

  const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
  }]

  const getData = () => {
    return prescriptions.map((prescription) => {
      let attachment = prescription.attachment,
        actions = ''
      if (attachment) {
        actions = <a href={attachment.url} target={'_blank'}><FontAwesomeIcon icon={faFileDownload}/> Descargar</a>
      }
      return {
        id: prescription.id,
        date: moment(convertStringToDate(prescription.date)).format('DD/MM/YYYY'),
        expiration_date: moment(convertStringToDate(prescription.expiration_date)).format('DD/MM/YYYY'),
        provider_business_id: prescription.provider_business_id,
        provider_name: prescription.provider_name,
        description: prescription.description,
        actions: actions
      }
    })
  }

  const paginationTotalRenderer = () => {
    return (
      <>
        <div className={'w-100'}>
          {isEditable && !newPrescription && (
            <div
              className={'btn btn-success'}
              onClick={handleNewPrescriptionBtnClick}
            >
              <FontAwesomeIcon icon={faPlus}/> Nueva receta
            </div>
          )}
          {!linkInsureePrescription && (
          <div
            className={'btn btn-primary ml-1'}
            onClick={handleLinkInsureePrescriptionBtnClick}
          >
            Ver recetas del paciente
          </div>)}
        </div>
      </>
    )
  }

  const pagination = paginationFactory(
    {
      sizePerPage: 5,
      hideSizePerPage: true,
      withFirstAndLast: true,
      page: selectedPage,
      showTotal: true,
      paginationTotalRenderer: paginationTotalRenderer
    }
  )

  const handleOnRowSelect = (row, isSelect, rowIndex, e) => {
    setSelectedRowId(row.id)
    setSelectedPage(getFirstPage(row.id))
  }

  const selectRowProp = {
    mode: 'radio',
    selected: [selectedRowId],
    onSelect: handleOnRowSelect
  }

  return (
    <div className={'w-100'}>
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={getData()}
        columns={columns}
        pagination={pagination}
        defaultSorted={defaultSorted}
        selectRow={selectRowProp}
        striped={true}
        headerClasses={'bg-secondary text-light'}
        noDataIndication={() => <em>No se han creado recetas para este gasto</em>}
      />
    </div>
  )
}

export default PrescriptionsTable