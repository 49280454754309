import React, {useState} from 'react'
import Select from 'react-select'

const ProvisionDetailDentalFieldSelector = (
    {
      provisionDetail,
      setProvisionDetail,
      index,
      isEditable,
      fieldOptions,
      field
    }
  ) => {

    const [dentalOption, setDentalOption] = useState(field == "tooth" ? provisionDetail.tooth : provisionDetail.sides)
    const handleOnChange = (value) => {
      let newProvisionDetail = provisionDetail
      setDentalOption(value.value)
      if (field == "tooth") {
        newProvisionDetail.tooth = value.value
      } else {
        newProvisionDetail.sides = value.map((option) => option.value)
      }
      setProvisionDetail(index, newProvisionDetail)
    }

    const showFieldValue = () => {
      for (let i = 0; i < fieldOptions.length; i++) {
        if (field == "tooth"){
          if (fieldOptions[i].value == provisionDetail.tooth) {
            return fieldOptions[i].label
          }
        } else { //sides 
            return provisionDetail.sides_s
        }
      }
    }

    const getSidesValues = () => {
      let sides_selected = []
      if (provisionDetail.sides_s) { 
        for (let i = 0; i < fieldOptions.length; i++) {
          if (provisionDetail.sides_s.includes(fieldOptions[i].label)) { 
            sides_selected.push(fieldOptions[i])
          }
        }
      }
      return sides_selected
    }
  
    return (
      <>
        {isEditable ? (
          field == "tooth" ? 
          <Select
            options={fieldOptions}
            value={fieldOptions.filter(function(option) {
              return option.value === dentalOption
            })}
            onChange={handleOnChange}
            classNamePrefix={'react-select'}
            placeholder={'Seleccione'}
            styles={{
              menu: (base) => ({
                ...base,
                width: "max-content",
                minWidth: "100%",
                left: 0,
              }),
            }}/>:<Select
            defaultValue={getSidesValues()}
            isMulti
            name="sides"
            options={fieldOptions}
            className="basic-multi-select"
            onChange={handleOnChange}
            classNamePrefix="select"
            styles={{
              menu: (base) => ({
                ...base,
                width: "max-content",
                minWidth: "100%",
                left: 0,
              }),
            }}
          />) : (<div>{showFieldValue()}</div>)
        }
      </>
    )
  }
  
  export default ProvisionDetailDentalFieldSelector