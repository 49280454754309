import React  from 'react'

const CardHeaderTitle = (
  {
    title
  }
) => {

  return (
    <h5>
      {title}
    </h5>
  )
}

export default CardHeaderTitle