import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Checkbox } from '@mui/material';
import FormActionButton from '../../../../shared/FormActionButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import { baseInsurersUrl } from '../../../../routes/insurers';

const LettersModal = (
  {
    showLettersModal,
    setShowLettersModal,
    letterTypes,
    requirement
  }
) => {

  const formatLetterTypesOptions = () => {
    var letterOptions = {};
    letterTypes.forEach((letterType) =>  {
      letterOptions[letterType.id] = false;
    });
    return letterOptions;
  };

  const [selectedLetterTypes, setSelectedLetterTypes] = useState({});

  const handleClose = () => {
    setShowLettersModal(false);
  };

  const lettersUrl = () => {
    const requirementType = requirement.isBudget ? 'budgets' : 'expenses';
    const baseUrl = baseInsurersUrl + '/' + requirement.insurerId + '/' + requirementType + '/' + requirement.id + '/letters.pdf';
    const selectedOptions = JSON.stringify(getSelectedOptions());
    return baseUrl + '?t=' + encodeURIComponent(selectedOptions);
  };

  const getSelectedOptions = () => {
    const selectedOptions = [];
    for (const option in selectedLetterTypes) {
      if (selectedLetterTypes[option]) {
        selectedOptions.push(parseInt(option));
      }
    }
    return selectedOptions;
  };

  const handleClick = () => {
    window.open(lettersUrl(), '_blank');
    setShowLettersModal(false);
  };

  const handleCheck = (selectedValue) => {
    var selectedOptions = selectedLetterTypes;
    selectedOptions[selectedValue] = !selectedLetterTypes[selectedValue];
    setSelectedLetterTypes(selectedOptions);
  };

  useEffect(() => {
    setSelectedLetterTypes(formatLetterTypesOptions);
  }, []);

  return (
    <Modal
      show={showLettersModal}
      className={'letters-modal'}
    >
      <Modal.Header
        className={'letters-modal--header'}
      >
        <Modal.Title>Seleccione las cartas a generar</Modal.Title>
        <button type={'button'} className={'close'} onClick={handleClose}>
          <span aria-hidden={'true'}>&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body
        className={'letters-modal--body'}
      >
        {
          letterTypes.map((letterType) => (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!letterType.available}
                  defaultChecked={selectedLetterTypes[letterType.id] || false}
                  value={letterType.id}
                  onChange={() => handleCheck(letterType.id)}
                />
              }
              label={letterType.name}
              className={'letters-modal--body-control'}
            />
          ))
        }
      </Modal.Body>
      <Modal.Footer
        className={'letters-modal-footer'}
      >
        <FormActionButton
          title={'Generar cartas de aseguradora'}
          className={'btn-success'}
          content={'Generar Cartas'}
          onClick={() => handleClick()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default LettersModal;