import React from 'react'
import Select from './Select'
import Input from './Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const BackgroundItem = (
  {
    background,
    backgroundOptions,
    backgroundKey,
    handleBackgroundRemove,
    updateBackground,
    showBackgroundRemoveButton
  }
) => {

  const newBackground = (key, value) => {
    return {
      id: background.id,
      reject_detail_id: background.reject_detail_id,
      preceding_id: key === 'preceding_id' ? value : background.preceding_id,
      details: key === 'details' ? value : background.details
    }
  }

  const updateBackgroundItem = (key, value) => {
    updateBackground(backgroundKey, newBackground(key, value))
  }

  const handleExternalCodeStateChange = (event) => {
    updateBackgroundItem('preceding_id', event.target.value)
  }

  const handleDetailsChange = (event) => {
    updateBackgroundItem('details', event.target.value)
  }

  const handleBackgroundRemoveClick = () => {
    handleBackgroundRemove(backgroundKey)
  }

  const getOptions = () => {
    return backgroundOptions.map((option) => {
      return {
        value: option.id,
        label: option.name
      }
    })
  }

  return (
    <div
      className={'background mb-2 d-flex align-items-center'}
    >
      {showBackgroundRemoveButton === undefined || showBackgroundRemoveButton && (
        <div
          className={'text-error text-danger pointer-as-btn'}
          title={'Eliminar antecedente'}
          onClick={handleBackgroundRemoveClick}
        >
          <FontAwesomeIcon icon={faTrashAlt} className={'icon-remove mr-2'}/>
        </div>
      )}
      <Select
        name={'preceding_id'}
        value={background.preceding_id}
        onChange={handleExternalCodeStateChange}
        options={getOptions()}
      />
      <Input
        name={'details'}
        type={'text'}
        placeholder={'Detalle (opcional)'}
        value={background.details}
        onChange={handleDetailsChange}
      />
    </div>
  )
}

export default BackgroundItem