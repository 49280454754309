import React, {useState} from 'react'
import Label from '../../../../../../shared/Label'
import Input from '../../../../../../shared/Input'
import dateToLiquidatorFormat from "../../../../../../utils/dateToLiquidatorFormat";
import dateToApiFormat from "../../../../../../utils/dateToApiFormat";

const ProvisionDetailDate = (
  {

    provisionDetail,
    setProvisionDetail,
    index,
    isEditable
  }
) => {

  const [date, setDate] = useState(dateToLiquidatorFormat(provisionDetail.attentionDate));

  const handleDateChange = (event) => {

    var formattedDate;
    const value = event.target.value.split("/").join("");
    if (value.length < 3) {
      formattedDate = value;
    } else if (value.length >= 3 && value.length < 5) {
      formattedDate = value.substring(0, 2) + "/" + value.substring(2);
    } else if (value.length >= 4 && value.length < 8) {
      formattedDate = value.substring(0, 2) + "/" + value.substring(2, 4) + "/" + value.substring(4);
    } else if (value.length >= 8) {
      formattedDate = value.substring(0, 2) + "/" + value.substring(2, 4) + "/" + value.substring(4);
    }
    setDate(formattedDate);
  
    let newProvisionDetail = provisionDetail,
      newValue = formattedDate

    newProvisionDetail['attentionDate'] = newValue
    setProvisionDetail(index, newProvisionDetail)
  }

  return (
    <>
      <div className={'w-100'}>
      {isEditable ? (<Input
          value={date}
          onChange={handleDateChange}
          className={'form-control'}
          placeholder={'dd/mm/aaaa'}
        />) : (<div>{provisionDetail.attentionDate}</div>)}
      </div>
    </>
  )
}

export default ProvisionDetailDate
