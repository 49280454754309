import React, {useCallback, useRef, useState} from 'react'
import Label from '../../../../../../shared/Label'
import Async  from 'react-select/async'
import axios from 'axios'
import {providersApiUrl} from '../../../../../../routes/search'
import errorSwalAlert from '../../../../../../utils/errorSwalAlert'
import rutFormat from '../../../../../../utils/rutFormat'
import SelectLabelHighlighter from '../../../../../../shared/SelectLabelHighlighter'

const DocumentHeaderProvider = (
  {
    document,
    setDocument,
    index,
    checkDuplicatedDocument,
    requirementType,
    countryIso,
    isEditable
  }
) => {

  const insurerId = window.insurerId
  const [selectMode, setSelectMode] = useState(undefined)
  const [inputValue, setInputValue] = useState(document.providerName)

  let searchValue = inputValue,
    typingTimer,
    doneTypingInterval = 1000

  const buildFormData = (value) => {
    let formData = new FormData(),
      dni = '',
      name = ''
    switch (selectMode) {
      case 'rut':
        let justRut = value.replace(/[^0-9kK]/g, '');
        dni = justRut;
        break;
      case 'name':
        name = value;
        break;
    }
    formData.append('insurer_id', insurerId);
    formData.append('dni', dni);
    formData.append('name', name);
    formData.append('insurance_type', requirementType);
    return formData;
  };

  const getProviders = async (value) => {
    searchValue = value
    return await axios({
      url: providersApiUrl,
      method: 'POST',
      data: buildFormData(value)
    })
    .then ((res) => {
      let data = res.data,
        newProviders = []
      if (data.success) {
        newProviders = getOptions(data.providers)
      }
      return newProviders
    })
    .catch(err =>{
      // Error
      errorSwalAlert('Ocurrió un error inesperado. Intente nuevamente')
      // Para trazabilidad
      console.log(err)
      return []
    })
  }

  const promiseOptions = useCallback((inputValue) => {
    if (selectMode === undefined) {
      return new Promise((resolve) => {
        resolve([])
      })
    }
    return new Promise((resolve) => {
      clearTimeout(typingTimer)
      typingTimer = setTimeout(() => {
        resolve(getProviders(inputValue))
      }, doneTypingInterval)
    })
  }, [inputValue])

  const handleChange = (selected) => {
    let newDocument = document
    newDocument.providerId = selected.value
    newDocument.providerName = selected.label.props.textToHighlight
    if (newDocument.canBeSearched) {
      checkDuplicatedDocument(newDocument)
    }else {
      setDocument(index, newDocument, false, newDocument.canBeEvaluated, 100)
    }
  }

  const getOptions = (providers) => {
    return providers.map((provider) => {
      return {
        value: provider[1],
        label: <SelectLabelHighlighter
          searchWords={searchValue}
          textToHighlight={provider[0]}
          isRut={selectMode === 'rut'}
        />
      }
    })
  }

  const isRutValid = (keyCode) => {
    return ((keyCode === 32) || //space
      (keyCode === 46) || //delete
      (keyCode === 8) || //backspace
      (keyCode >= 48 && keyCode <= 57) || //0-9
      (keyCode >= 96 && keyCode <= 111) || //numpad
      (keyCode >= 186 && keyCode <= 192) || //;=,-./`
      (keyCode === 75)); //k
  }

  const handleKeyDown = (event) => {
    switch (selectMode) {
      case 'rut':
        // Bloqueamos letras que no sean 'k'
        if (!isRutValid(event.keyCode || event.which)) {
          event.preventDefault()
        }
        break;
      case 'name':
      default:
        // Do nothing
    }
  }

  const handleInputChange = (inputValue) => {
    if (countryIso === 'CL') {
      handleChileanSearch(inputValue);
    } else {
      setSelectMode('name');
      setInputValue(inputValue);
    }
  }

  const handleChileanSearch = (inputValue) => {
    let valueLength = inputValue.length,
      newInputValue = inputValue
    if (valueLength === 0 || (valueLength === 1 && selectMode !== undefined)) {
      setSelectMode(undefined)
    }else if (valueLength === 2) {
      if (inputValue.replace(/[^0-9]/g, '').length === 2) {
        // Son números
        setSelectMode('rut')
        newInputValue = rutFormat(inputValue)
      }
    }else if (valueLength === 3 && selectMode === undefined) {
      // Buscaremos por nombre
      setSelectMode('name')
    }else if (selectMode === 'rut') {
      // Formateamos a rut
      newInputValue = rutFormat(inputValue)
    }
    setInputValue(newInputValue)
  }

  return (
    <>
      <div className={'row'}>
        <Label htmlFor={'document_header_provider_' + index} className={'col-12 px-0'}>Prestador</Label>
        {isEditable ? (<Async
          id={'document_header_provider_' + index}
          className={'w-100'}
          placeholder={'Seleccione un prestador'}
          loadingMessage={() => 'Buscando prestadores'}
          cacheOptions
          onChange={handleChange}
          loadOptions={promiseOptions}
          noOptionsMessage={()=> 'Prestador no encontrado'}
          onKeyDown={handleKeyDown}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          defaultValue={document.defaultProviderSelectValues}
        />):(<p>{document.providerName}</p>)}
      </div>
    </>
  )
}

export default DocumentHeaderProvider