import React, {useEffect} from 'react'
import axios from 'axios'
import {requirePreapprovalApiUrl} from '../../../../routes/search'
import HiddenInput from '../../../../shared/HiddenInput'
import closeAlert from '../../../../utils/closeAlert'
import showLoading from '../../../../utils/showLoading'

const RequirePreapproval = (
  {
    shipment,
    policyId,
    requirePreapproval,
    setRequirePreapproval
  }
) => {

  const insurerId = window.insurerId

  const searchRequirePreapproval = async (startDateShipment) => {
    let formData = new FormData()
    formData.append('policy_id', policyId)
    formData.append('receipt_date', startDateShipment)
    formData.append('insurer_id', insurerId)
    showLoading()
    await axios({
      url: requirePreapprovalApiUrl,
      method: 'POST',
      data: formData
    })
      .then ((res) => {
        let value = res.data
        setRequirePreapproval(!!value)
      })
      .catch(err =>{
        setRequirePreapproval(false)
      })
      .finally(() => {
        closeAlert()
      })
  }

  useEffect(() => {
    if (shipment && shipment.receipt_date) {
      searchRequirePreapproval(shipment.receipt_date)
    }
  }, [shipment, policyId])

  return (
    <HiddenInput id={'require_preaproval_value'} value={requirePreapproval} />
  )
}

export default RequirePreapproval