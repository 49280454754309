import React from 'react'
import ProvisionDetailRemove from './ProvisionDetailRemove'
import ProvisionDetailProvision from './ProvisionDetailProvision'
import ProvisionDetailSimpleInput from './ProvisionDetailSimpleInput'
import ProvisionDetailRejects from './ProvisionDetailRejects'
import ProvisionDetailCommentsModal from './ProvisionDetailCommentsModal'
import ProvisionDetailHealthProvisionTypeSelector from './ProvisionDetailHealthProvisionTypeSelector'

const HealthProvisionDetail = (
  {
    provisionDetail,
    setProvisionDetail,
    removeProvisionDetail,
    index,
    currency,
    prescriptions,
    setPrescriptions,
    attachments,
    healthProvisionTypes,
    policyId,
    insuree,
    policy,
    requirementType,
    showProvisionDetailCommentsModal,
    setShowProvisionDetailCommentsModal,
    getApprovedValue,
    getCoveragePercentage,
    getRefundValue,
    isEditable
  }
) => {

  return (
    <tr>
      <th className={'provision-details-table__row'} scope={'row'}>
        {isEditable && <ProvisionDetailRemove
          {...
            {
              removeProvisionDetail,
              index
            }
          }
        />}
      </th>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailProvision
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              setShowProvisionDetailCommentsModal,
              isEditable
            }
          }
          insuranceType={requirementType}
        />
      </td>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailHealthProvisionTypeSelector
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              healthProvisionTypes,
              isEditable
            }
          }
        />
      </td>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailSimpleInput
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              currency,
              isEditable
            }
          }
          property={'totalAmount'}
          money={true}
        />
      </td>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailSimpleInput
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              currency,
              isEditable
            }
          }
          property={'isapreBonus'}
          money={true}
        />
      </td>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailSimpleInput
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              currency,
              isEditable
            }
          }
          property={'otherInsurancesDiscounts'}
          money={true}
        />
      </td>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailSimpleInput
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              currency,
              isEditable
            }
          }
          property={'requiredAmount'}
          money={true}
        />
      </td>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailSimpleInput
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              currency,
              isEditable
            }
          }
          property={'requiredQuantity'}
          money={false}
        />
      </td>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailSimpleInput
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              currency,
              isEditable
            }
          }
          property={'approvedQuantity'}
          money={false}
        />
      </td>
      <td className={'provision-details-table__row'}>{getApprovedValue()}</td>
      <td className={'provision-details-table__row'}>{getCoveragePercentage()}</td>
      <td className={'provision-details-table__row'}>{getRefundValue()}</td>
      <td className={'provision-details-table__row'}>
        <ProvisionDetailRejects
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              prescriptions,
              setPrescriptions,
              currency,
              setShowProvisionDetailCommentsModal,
              attachments,
              policyId,
              insuree,
              policy,
              isEditable
            }
          }
        />
      </td>
      {showProvisionDetailCommentsModal && (
        <ProvisionDetailCommentsModal
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              showProvisionDetailCommentsModal,
              setShowProvisionDetailCommentsModal
            }
          }
        />
      )}
    </tr>
  )
}

export default HealthProvisionDetail