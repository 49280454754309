import React, { useState, useEffect } from 'react'
import PolicyClause  from './form_header/PolicyClause'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Header from './form_header/Header'
import { updateClausesApiUrl } from '../../routes/policies'
import { baseInsurerPolicyClausesAppUrl } from '../../utils/railsUrls'
import { Alert } from '@mui/material'
import axios from 'axios'

const PolicyClausesForm = (props) => {
  const insuranceType = props.insurance_type || "health";
  const [policyClauseSection, setPolicyClauseSection] = useState([]);
  const handleAddPolicyClause = () => {
    checkPreviousClauses();
    const section = policyClauseSection
    const policyClause = {
      id: null,
      insurance_type: insuranceType,
      provider_id: null,
      insurer_provider_group_id: null,
      evaluation_type: null,
      provision_id: null,
      insurer_provision_group_id: null,
      bond_coverage: null,
      refund_coverage: null,
      no_provision_coverage: null,
      bmi: null,
      free_choice_penalty: null,
      tariff_limit_factor: null,
      limit_cents: null,
      max_frequency: null,
      imed_accepted: null,
      quotas: [],
      index_: getIndex(),
      age_from: null,
      age_to: null
    }
    section.push(policyClause)
    setPolicyClauseSection(section)
  }

  const goBackToShow = () => {
    const showUrl = props.go_back_route
    window.location.replace(showUrl)
  }

  const handleRemovePolicyClause = (event, index_) => {
    setWarning(false);
    if (policyClauseSection.length == 1){
      setPolicyClauseSection([])
    } else {
    setPolicyClauseSection(policyClauseSection.filter(item => item.index_ != index_));
    }
  }

  const handleChangePolicyClause = (index_, element) => {
    var position = policyClauseSection.findIndex(item => item && item.index_ === index_);
    if (position > -1) {
      const newSection = policyClauseSection.map((c, i) => {
        if (i == position) {
          return element
        } else {
          return c
        }
      })
      setPolicyClauseSection(newSection)
    }
  }

  const [counter, setCounter] = useState(props.policy_clauses ? props.policy_clauses.length : 0);
  
  const getIndex = () => {
    const index_ = counter + 1;
    setCounter(counter + 1);
    return index_;
  }

  const getQuotaType = (value) => {
    if (value == "familiar") {
      return 1
    } else if (value == "individual") {
      return 2
    } else {
      return null
    }
  }

  const formatPolicyClauses = (clauses) => {
    setPolicyClauseSection(clauses.map((pc, index) => (
      {
        id: pc.policy_clause.id,
        provider_id: pc.policy_clause.provider_id,
        insurer_provider_group_id: pc.policy_clause.insurer_provider_group_id,
        provision_id: pc.policy_clause.provision_id,
        evaluation_type: pc.policy_clause.evaluation_type,
        insurer_provision_group_id: pc.policy_clause.insurer_provision_group_id,
        bond_coverage: pc.policy_clause.bond_coverage,
        refund_coverage: pc.policy_clause.refund_coverage,
        no_provision_coverage: pc.policy_clause.no_provision_coverage,
        bmi: pc.policy_clause.bmi,
        free_choice_penalty: pc.policy_clause.free_choice_penalty,
        tariff_limit_factor: pc.policy_clause.tariff_limit_factor,
        limit_cents: pc.policy_clause.limit_cents,
        max_frequency: pc.policy_clause.max_frequency,
        imed_accepted: pc.policy_clause.imed_accepted,
        insurance_type: pc.policy_clause.insurance_type || insuranceType,
        age_from: pc.policy_clause.age_from,
        age_to: pc.policy_clause.age_to,
        quotas: pc.quotas.map((quota, index) => {
          return {
            id: quota.id,
            index_: index + 1,
            value_amount: quota.value_amount,
            quota_type: getQuotaType(quota.quota_type)
          }
        }),
        index_: index + 1
      }
    )))
  }

  const readyToSave = () => {
    var falseCounter = 0
    const insurer_provision_groups_ids = policyClauseSection.map(pc => {
      return {
        ipg_id: pc.insurer_provision_group_id ? Number(pc.insurer_provision_group_id) : null,
        pc_id: pc.id,
        insurer_provider_group_id: pc.insurer_provider_group_id ? Number(pc.insurer_provider_group_id) : null
        }
    })
    policyClauseSection.forEach((policyClause) => {
      const ready = checkClause(policyClause, insurer_provision_groups_ids)
      if (ready == false){
        falseCounter += 1
      }
    })
    if (falseCounter > 0){
      return false
    } else {
      return true
    }
  }

  const checkPreviousClauses = () => {
    policyClauseSection.map((policyClause, index) => {
      if ((policyClause.bond_coverage == null || policyClause.bond_coverage == "" || 
            policyClause.refund_coverage == null || policyClause.refund_coverage == ""  ||
            policyClause.no_provision_coverage == null || policyClause.no_provision_coverage == "")
             && (policyClause.provision_id != null ||  policyClause.provider_id!= null )  )
        {
          setWarningMessage('No se recomienda dejar los campos de Bono - Reembolso - Sin previsión (S / Prev) vacíos');
          setWarning(true);
        }
    })
  }

  const checkClause = (policyClause, insurer_provision_groups_ids) => {
    var nullCounter = 0
    if (policyClause.bond_coverage == null || policyClause.bond_coverage == ""){
      policyClause.bond_coverage = null
      // setMessage("Error: El campo de bono no puede estar vacio")
      // return false;
    }
    if (policyClause.refund_coverage == null || policyClause.refund_coverage == ""){
      policyClause.refund_coverage = null
      // setMessage("Error: El campo de reembolso no puede estar ser vacio")
      // return false;
    }
    if (policyClause.no_provision_coverage == null || policyClause.no_provision_coverage == ""){
      policyClause.no_provision_coverage = null
      // setMessage("Error: El campo de sin prevision (S / Prev) no puede estar ser vacio")
      // return false;
    }
    const ipgFilter = insurer_provision_groups_ids.filter(
      (pc) => pc.ipg_id === policyClause.insurer_provision_group_id && pc.insurer_provider_group_id === policyClause.insurer_provider_group_id
      )
    if (ipgFilter.length > 1 ){
      setMessage("Error: No puedes repetir el grupo de prestaciones para el mismo prestador")
      return false;
    }
    policyClause.quotas.forEach((quota) => {
      if (quota.quota_type == null || quota.value_amount == null || quota.value_amount == ""){
        nullCounter += 1
      }
    })
    if (nullCounter > 0){
      setMessage("Error: El valor del cupo no puede estar vacio")
      return false
    } else {
      return true
    }
  }

  const updateClauses = async () => {
    const ready = readyToSave()
    setWarning(false);
    if (ready == true){
      try {
        const res = await axios.put(
          updateClausesApiUrl,
          {
            form_fields: policyClauseSection,
            policy_instance_id: props.policy_instance_id,
          }
        )
        if (res.status == 200){
          goBackToShow()
        }
      } catch (e) {
        setMessage(`Error al guardar, revisa los campos e intenta nuevamente ${e}`)
        setAlert(true)
      }
    } else {
      setAlert(true)
    }
  }

  const [alert, setAlert] = useState(false)
  const [message, setMessage] = useState(null)

  const [warning, setWarning] = useState(false)
  const [warningMessage, setWarningMessage] = useState(null)

  useEffect(() => {
    if (policyClauseSection.length == 0 && props.policy_clauses.length != 0){
      formatPolicyClauses(props.policy_clauses)
    }
  }, [])

  return (
    <>
      <div className='conditioned-form-container'>
        { alert && (
          <Alert severity="error">{message}</Alert>
        )}
        { warning && (
          <Alert severity="warning"className='mt-1' >{warningMessage}</Alert>
        )}
        <Header insuranceType={insuranceType}/>
        { policyClauseSection.map((policyClause, index) => {
          if (policyClause){
            return (
              <PolicyClause
                key={policyClause.index_}
                symbol={props.symbol}
                subunit_to_unit={props.subunit_to_unit}
                providers={props.providers}
                provider_groups={props.provider_groups}
                provisions={props.provisions}
                provision_groups={props.provision_groups}
                evaluation_types={props.evaluation_types}
                index_={policyClause.index_}
                policy_clause={policyClause}
                handleRemovePolicyClause={handleRemovePolicyClause}
                handleChangePolicyClause={handleChangePolicyClause}
              />
            )
          }
        }) }
      </div>
      <div className='conditioned-form-actions'>
        <button type="button" className="btn action-btn btn-gerty-font" onClick={handleAddPolicyClause}><AddCircleIcon /> Agregar condicionado</button>
        <button type="button" className="btn btn-primary-gerty" onClick={updateClauses}>Guardar</button>
        <button type="button" className="btn btn-light btn-gerty-font" onClick={goBackToShow}>Volver</button>
      </div>
    </>
  )
}

export default PolicyClausesForm;