import Swal from 'sweetalert2'

const blockedByFormHeaderAlerts = (alerts) => {
  let resp = false
  if (alerts.length > 0) {
    // Existen errores, ahora veremos cuales
    resp = true
    Swal.fire(
      'No podemos continuar',
      'Debe corregir los errores en el formulario antes de realizar otra operación.',
      'error'
    ).then(() => {
      $('.is-invalid').first().focus()
    })
  }
  return resp
}

export default blockedByFormHeaderAlerts