import React, {useEffect, useState} from 'react'
import showLoading from '../../../../../../../utils/showLoading'
import axios from 'axios'
import closeAlert from '../../../../../../../utils/closeAlert'
import {
  faEye
} from '@fortawesome/free-solid-svg-icons'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import convertStringToDate from "../../../../../../../utils/convertStringToDate";
import paginationFactory from "react-bootstrap-table2-paginator";
import Alert from 'react-bootstrap/Alert';

const InsureePrescriptions = (
  {
    selectedRowId,
    setSelectedRowId,
    selectedPage,
    setSelectedPage,
    getFirstPage,
    setLinkInsureePrescription,
    policyId,
    insuree,
    policy
  }
) => {

  const expirationDateOptions = [
    {
      value: 0,
      label: '3 meses'
    },
    {
      value: 1,
      label: '6 meses'
    },
    {
      value: 2,
      label: '1 año'
    }
  ]

  const providerBusinessIdTypeOptions = [
    {
      value: 1,
      label: 'RUT (CL)'
    },
    {
      value: 2,
      label: 'CURP (MX)'
    }
  ]

  const {insurerId, requirementId} = window
  const [getPrescriptions, setGetPrescriptions] = useState(true);
  const [insureePrescriptions, setInsureePrescriptions] = useState([]);

  const getPatientPrescriptions = async () => {
    if (window.insurerId && policy && insuree) {
        showLoading()
        await axios({
            url: window.location.origin + '/insurers/' + window.insurerId + '/policies/' + policy.value +  '/insurees/' + insuree.data.id + '/prescriptions',
            method: 'GET',
            headers:{
              'Content-Type':'multipart/form-data',
              'Accept': 'application/json'
            }
        })
            .then ((res) => {
                let data = res.data
                setInsureePrescriptions(data)
                setGetPrescriptions(false)
            })
            .catch(err =>{
                // Error
                console.log(err)
                setGetPrescriptions(true)
            })
            .finally(() => {
                closeAlert()
            })
    }
  }

  const getData = () => {
    return insureePrescriptions.map((prescription) => {
        let attachment = prescription.attachment,
            actions = ''
        if (attachment) {
            actions = <a href={attachment.url} className='mr-3' target={'_blank'}><FontAwesomeIcon icon={faEye}/> Ver</a>
        }
        return {
            id: prescription.id,
            date: moment(convertStringToDate(prescription.date)).format('DD/MM/YYYY'),
            expiration_date: moment(convertStringToDate(prescription.expiration_date)).format('DD/MM/YYYY'),
            provider_business_id: prescription.provider_business_id,
            provider_name: prescription.provider_name,
            description: prescription.description,
            actions: actions
        }
    })
  }

  const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
  }]

  const handleCancelBtnClick = () => {
      setLinkInsureePrescription(false)
  }


  const dateSorting = (a, b, order) => {
    let dateA = moment(a, 'DD/MM/YYYY'),
        dateB = moment(b, 'DD/MM/YYYY')
    if (order === "asc") {
        return dateA - dateB
    } else if (order === "desc") {
        return dateB - dateA
    }
  }

  const pagination = paginationFactory(
    {
      sizePerPage: 5,
      hideSizePerPage: true,
      withFirstAndLast: true,
      page: selectedPage,
      showTotal: true
    }
  )

  const handleOnRowSelect = (row, isSelect, rowIndex, e) => {
        setSelectedRowId(row.id)
        setSelectedPage(getFirstPage(row.id))
  }

  const selectRowProp = {
        mode: 'radio',
        selected: [selectedRowId],
        onSelect: handleOnRowSelect
  }

  const columns = [
    {
        dataField: "id",
        text: "Id",
        hidden: true,
        sort: true
    },
    {
        dataField: "date",
        text: "Fecha",
        sort: true,
        sortFunc: dateSorting
    },
    {
        dataField: "expiration_date",
        text: "Fecha de expiración",
        sort: true,
        sortFunc: dateSorting
    },
    {
        dataField: "provider_business_id",
        text: "Identificador Tributario Profesional",
        sort: true
    },
    {
        dataField: "provider_name",
        text: "Nombre",
        sort: true
    },
    {
        dataField: "description",
        text: "Descripción"
    },
    {
        dataField: "actions",
        text: "Acciones"
    }
  ]

  useEffect(() => {
    if (getPrescriptions) {
      getPatientPrescriptions()
    }
  }, [getPrescriptions])

  return (
    <div className={'py-3 px-3 d-flex justify-content-between align-items-start row'}>

        <div className={'w-100'}>
          {insureePrescriptions.length > 0 ? ( 
            <>
              <h5>Recetas del paciente</h5>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={getData()}
                columns={columns}
                pagination={pagination}
                defaultSorted={defaultSorted}
                selectRow={selectRowProp}
                striped={true}
                headerClasses={'bg-secondary text-light'}
              />
            </>) : (
              <div>
                <Alert variant="warning">
                  <p>
                    El paciente no cuenta con recetas registradas en el sistema
                  </p>
                </Alert>
              </div>
            )}
        </div>
        <div
          className={'btn btn-light'}
          onClick={handleCancelBtnClick}
        >
          Ocultar
        </div>

    </div>
  )
}

export default InsureePrescriptions