import React, {useRef, useState} from 'react'
import axios from 'axios'
import DatePicker, {setDefaultLocale} from 'react-datepicker'
import {shipmentApiUrl} from '../../../../../routes/search'
import es from 'date-fns/locale/es'
import addErrorAlert from '../../../../../utils/addErrorAlert'
import convertDateToString from '../../../../../utils/convertDateToString'
import getInputErrorClass from '../../../../../utils/getInputErrorClass'
import getInputErrorMessage from '../../../../../utils/getInputErrorMessage'
import notFoundRespData from '../../../../../utils/notFoundRespData'
import removeAlertByContainer from '../../../../../utils/removeAlertByContainer'
import showLoading from '../../../../../utils/showLoading'
import closeAlert from '../../../../../utils/closeAlert'
import FormAlert from '../shared/FormAlert'
import Label from '../../../../../shared/Label'
import Input from '../../../../../shared/Input'
import EditableValue from '../shared/EditableValue'

setDefaultLocale('es', es)

const Shipment = (
  {
    shipment,
    setShipment,
    formAlerts,
    setFormAlerts,
    containerName,
    startDateShipment,
    setStartDateShipment,
    shipmentCode,
    setShipmentCode,
    isEditable
  }
) => {

  const [editable, setEditable] = useState(shipmentCode === '')
  const insurerId = window.insurerId
  const shipmentCodeInput = useRef(null)

  const updateShipment = (field, value) => {
    let shipmentHash = {
      id: shipment.id,
      code: shipment.code,
      receipt_date: shipment.receipt_date
    }
    shipmentHash = {...shipmentHash, [field]: value}
    setShipment(shipmentHash)
  }

  const errorResponse = () => {
    setFormAlerts(addErrorAlert(formAlerts, containerName, 'shipment_code', <span><strong>Error:</strong> no se encontró la remesa '{shipmentCode}'</span>))
    shipmentCodeInput.current.focus()
  }

  const searchShipmentByCode = async () => {
    let formData = new FormData()
    formData.append('shipment_code', shipmentCode)
    formData.append('insurer_id', insurerId)
    showLoading()
    await axios({
      url: shipmentApiUrl,
      method: 'POST',
      data: formData
    })
      .then ((res) => {
        let data = res.data
        if (notFoundRespData(data)) {
          // No encontramos un shipment con ese codigo.
          return errorResponse()
        }
        let newShipment = shipment
        newShipment.id = data.id
        newShipment.code = shipmentCode
        newShipment.receipt_date = data.receipt_date
        setShipment(newShipment)
        setFormAlerts(removeAlertByContainer(formAlerts, containerName))
        setEditable(false)
      })
      .catch(err =>{
        // Error
        errorResponse()
      })
      .finally(() => {
        closeAlert()
      })
  }

  const handleShipmentCodeChange = (event) => {
    setShipmentCode(event.target.value)
  }

  const handleBlur = () => {
    if (shipmentCode !== '') {
      searchShipmentByCode()
    }else {
      setFormAlerts(removeAlertByContainer(formAlerts, containerName))
    }
  }

  const handleShipmentDateChange = (value) => {
    let date = value ? convertDateToString(value) : ''
    updateShipment('receipt_date', date)
    setStartDateShipment(value)
  }

  return (
    <>
      <div className={'form-group col form-row justify-content-center align-items-center'}>
        <Label htmlFor={'shipment_code'} className={'col flex-grow-0'}>Remesa:</Label>
        {isEditable && editable ? (
          <>
            <Input
              name={'shipment_code'}
              type={'text'}
              placeholder={'Folio'}
              value={shipmentCode}
              onBlur={handleBlur}
              onChange={handleShipmentCodeChange}
              ref={shipmentCodeInput}
              className={'col' + getInputErrorClass(formAlerts, 'shipment_code')}
            />
            <div className={'col'}>
              <DatePicker
                selected={startDateShipment}
                onChange={handleShipmentDateChange}
                placeholderText={'Fecha'}
                selectsStart
                disabled={!!shipmentCode}
                startDate={startDateShipment}
                dateFormat={'dd/MM/yyyy'}
                className={'form-control'}
              />
            </div>
            <FormAlert
              message={getInputErrorMessage(formAlerts, 'shipment_code')}
            />
          </>
        ) : (
          <EditableValue
            setEditable={setEditable}
          >
            <Label
              className={'col'}
            >
              <strong>{shipmentCode}</strong>
            </Label>
            {startDateShipment && (
              <Label
                className={'col flex-grow-0'}
              >
                <strong>{convertDateToString(startDateShipment)}</strong>
              </Label>
            )}
          </EditableValue>
        )}
      </div>
    </>
  )
}

export default Shipment