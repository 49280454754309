import React, {useState} from 'react'
import axios from 'axios'
import {requirementById} from '../../../../../routes/search'
import addErrorAlert from '../../../../../utils/addErrorAlert'
import closeAlert from '../../../../../utils/closeAlert'
import getInputErrorClass from '../../../../../utils/getInputErrorClass'
import getInputErrorMessage from '../../../../../utils/getInputErrorMessage'
import notFoundRespData from '../../../../../utils/notFoundRespData'
import removeAlertByField from '../../../../../utils/removeAlertByField'
import showLoading from '../../../../../utils/showLoading'
import FormAlert from '../shared/FormAlert'
import Input from '../../../../../shared/Input'
import Label from '../../../../../shared/Label'
import EditableValue from '../shared/EditableValue'

const OldReferenceId = (
  {
    formAlerts,
    setFormAlerts,
    containerName,
    externalCode,
    reentryCode,
    setReentryCode,
    setOldReferenceId,
    isEditable
  }
) => {

  const [editable, setEditable] = useState(reentryCode === '')
  const insurerId = window.insurerId
  const requirementType = window.isBudget ? 'budget' : 'expense'

  const getRequirementType = () => {
    return requirementType === 'budget' ? 'Presupuesto' : 'Gasto'
  }

  const requirementByIdErrorResponse = () => {
    setFormAlerts(addErrorAlert(formAlerts, containerName, 'reentry_code', <span><strong>Error:</strong> no se encontró el {getRequirementType()} <strong>'{reentryCode}'</strong>.</span>))
  }

  const getRequirementById = async () => {
    if (reentryCode === '') {
      // Limpiamos
      setFormAlerts(removeAlertByField(formAlerts, 'reentry_code'))
      return
    }
    let formData = new FormData()
    formData.append('requirement_id', reentryCode)
    formData.append('requirement_type', requirementType)
    formData.append('insurer_id', insurerId)
    showLoading()
    await axios({
      url: requirementById,
      method: 'POST',
      data: formData
    })
    .then ((res) => {
      let data = res.data
      if (notFoundRespData(data)) {
        // No encontramos un requirement con este id
        return requirementByIdErrorResponse()
      }
      setFormAlerts(removeAlertByField(formAlerts, 'reentry_code'))
      if (externalCode === reentryCode) {
        // Limpiamos cualquier error dado a external code
        setFormAlerts(removeAlertByField(formAlerts, 'external_code'))
      }
      setOldReferenceId(data.id)
      setEditable(false)
    })
    .catch(err =>{
      // Error
      requirementByIdErrorResponse()
    })
    .finally(() => {
      closeAlert()
    })
  }

  const handleOldReferenceIdChange = (event) => {
    setReentryCode(event.target.value)
  }

  const handleOldReferenceIdBlur = () => {
    getRequirementById()
  }

  return (
    <>
      <div className={'form-group col form-row justify-content-center align-items-center'}>
        <Label
          className={'tooltipable col flex-grow-0'}
          htmlFor={'new_reentry_code'}
          title={'Folio externo'}
        >
          Reingreso:
        </Label>
        {isEditable && editable ? (
          <>
            <Input
              id={'new_reentry_code'}
              type={'text'}
              value={reentryCode}
              onChange={handleOldReferenceIdChange}
              onBlur={handleOldReferenceIdBlur}
              className={'col' + getInputErrorClass(formAlerts, 'reentry_code')}
            />
            <FormAlert
              message={getInputErrorMessage(formAlerts, 'reentry_code')}
            />
          </>
        ) : (
          <EditableValue
            setEditable={setEditable}
          >
            <Label
              className={'col flex-grow-0'}
            >
              <strong>{reentryCode}</strong>
            </Label>
          </EditableValue>
        )}
      </div>
    </>
  )
}

export default OldReferenceId