import React from 'react';
import Label from '../../../../../shared/Label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

const EditableValue = ({children, setEditable, isEditable}) => {
  const handleClick = () => {
    setEditable(true);
  };
  return (
    isEditable ? (
      <>
        {children}
        <Label onClick={handleClick} className="col">
          <FontAwesomeIcon icon={faPencil} className="pointer-as-btn"/>
        </Label>
      </>
    ):(
      <>
        {children}
      </>
    )
  );
};

export default EditableValue;
