import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Comments from './Comments'
import BackgroundItems from './BackgroundItems'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const BackgroundsModal = (
  {
    initialBackgrounds,
    backgrounds,
    setBackgrounds,
    showBackgroundsModal,
    setShowBackgroundsModal,
    comments,
    setComments,
    formAlerts,
    backgroundOptions,
    handleFinishBackgroundBtnClick,
    removedBackgrounds,
    setRemovedBackgrounds,
    title
  }
) => {

  const handleClose = () => setShowBackgroundsModal(false)
  const [showFinishBtn, setShowFinishBtn] = useState(true)

  const initialBackground = () => {
    return {
      id: null,
      reject_detail_id: null,
      preceding_id: backgroundOptions[0].id,
      details: ''
    }
  }

  const handleAddBackgroundBtnClick = () => {
    addBackground(initialBackground())
    setShowFinishBtn(false)
  }

  const addBackground = (background) => {
    setBackgrounds([...backgrounds, background])
  }

  return (
    <>
      <Modal show={showBackgroundsModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Comments
            {...
              {
                comments,
                setComments,
                formAlerts
              }
            }
          />
          <BackgroundItems
            {...
              {
                initialBackgrounds,
                backgrounds,
                setBackgrounds,
                backgroundOptions,
                removedBackgrounds,
                setRemovedBackgrounds
              }
            }
          />
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={'btn btn-success'}
            onClick={handleAddBackgroundBtnClick}
          >
            <FontAwesomeIcon icon={faPlus} className={'icon-upload-alt'} /> Agregar antecedente
          </div>
          <div
            className={showFinishBtn ? 'btn btn-primary disabled pe-none' : 'btn btn-primary'}
            onClick={showFinishBtn ? null : handleFinishBackgroundBtnClick}
          >
            Terminar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BackgroundsModal