import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import PrescriptionsTable from './PrescriptionsTable'
import PrescriptionsNewPrescription from './PrescriptionsNewPrescription'
import InsureePrescriptions from './PrescriptionsFromInsuree';

const PrescriptionsModal = (
  {
    provisionDetail,
    setProvisionDetail,
    index,
    showProvisionDetailPrescriptionsModal,
    setShowProvisionDetailPrescriptionsModal,
    prescriptions,
    setPrescriptions,
    attachments,
    policyId,
    insuree,
    policy,
    isEditable
  }
) => {


  const [newPrescription, setNewPrescription] = useState()
  const [linkInsureePrescription, setLinkInsureePrescription] = useState()
  const [selectedRowId, setSelectedRowId] = useState(provisionDetail.prescriptionId)
  const [tableKey, setTableKey] = useState(selectedRowId ? selectedRowId : 'table-key')
  const getFirstPage = (prescriptionId) => {
    let resp = 1,
      selectedId = prescriptionId ? prescriptionId : selectedRowId
    prescriptions.sort(function(a, b) {
      return parseFloat(b.id) - parseFloat(a.id)
    }).forEach((prescription, index) => {
      if (prescription.id === selectedId) {
        resp = Math.ceil((index + 1) / 5)
      }
    })
    return resp
  }
  const [selectedPage, setSelectedPage] = useState(getFirstPage())

  const handleClose = () => {
    setShowProvisionDetailPrescriptionsModal(false)
  }

  const handleConfirm = () => {
    let newProvisionDetail = provisionDetail
    newProvisionDetail.prescriptionId = selectedRowId
    setProvisionDetail(index, newProvisionDetail)
    setShowProvisionDetailPrescriptionsModal(false)
  }

  return (
    <>
      <Modal
        show={showProvisionDetailPrescriptionsModal}
        onHide={handleClose}
        dialogClassName={'mw-100 w-100 px-3'}
        className={'prescriptions-modal'}
      >
        <Modal.Header>
          <Modal.Title>Recetas</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <PrescriptionsTable
            {...
              {
                prescriptions,
                provisionDetail,
                setProvisionDetail,
                newPrescription,
                setNewPrescription,
                selectedRowId,
                setSelectedRowId,
                selectedPage,
                setSelectedPage,
                getFirstPage,
                linkInsureePrescription,
                setLinkInsureePrescription,
                isEditable
              }
            }
            key={tableKey}
          />
          {isEditable && newPrescription && (
            <PrescriptionsNewPrescription
              {...
                {
                  prescriptions,
                  provisionDetail,
                  setProvisionDetail,
                  setNewPrescription,
                  setPrescriptions,
                  setSelectedRowId,
                  setSelectedPage,
                  getFirstPage,
                  setTableKey,
                  attachments,
                  insuree
                }
              }
            />
          )}
          {linkInsureePrescription && (
              <InsureePrescriptions
                  {...
                      {
                        selectedRowId,
                        setSelectedRowId,
                        selectedPage,
                        setSelectedPage,
                        getFirstPage,
                        setLinkInsureePrescription,
                        policyId,
                        insuree,
                        policy
                      }
                  }
              />
          )}
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={'btn btn-primary'}
            onClick={handleConfirm}
          >
            Aceptar
          </div>
          <div
            className={'btn btn-light'}
            onClick={handleClose}
          >
            Cerrar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PrescriptionsModal