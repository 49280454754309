import React, {useState} from 'react'
import Label from '../../../../../../shared/Label'
import Input from '../../../../../../shared/Input'
import Select from '../../../../../../shared/Select'
import getCurrencyTypes from '../../../../../../utils/getCurrencyTypes'

const DocumentHeaderAmount = (
  {
    document,
    setDocument,
    index,
    isEditable
  }
) => {

  const [amount, setAmount] = useState(document.amount)

  const handleAmountBlur = (event) => {
    let newDocument = document
    newDocument.amount = event.target.value
    setDocument(index, newDocument, false, newDocument.canBeEvaluated, 100)
  }

  const handleAmountChange = (event) => {
    setAmount(event.target.value)
  }

  const handleCurrencyTypeChange = (event) => {
    let newDocument = document
    newDocument.documentCurrencyType = event.target.value
    setDocument(index, newDocument, false, newDocument.canBeEvaluated, 100)
  }

  const getOptions = () => {
    return getCurrencyTypes().map((currencyOption) => {
      return currencyOption
    })
  }

  return (
    <>
      <div className={'row'}>
        <Label htmlFor={'document_header_amount_' + index} className={'col-12'}>Valor</Label>
        <div className={'col-9 pr-0'}>
          {isEditable ? (
              <Input
                id={'document_header_amount_' + index}
                value={amount}
                onChange={handleAmountChange}
                onBlur={handleAmountBlur}
                type={'number'}
                min={0}
              />
            ) : (
              <span className="form-control">{amount || 'N/A'}</span>
            )}
        </div>
        <div className={'col-3 px-0'}>
        {isEditable ? (
            <Select
              id={'document_header_currency_type_' + index}
              value={document.documentCurrencyType}
              onChange={handleCurrencyTypeChange}
              options={getOptions()}
              className={'w-auto'}
            />
          ) : (
            <span className="form-control">
              {getOptions().find(option => option.value === document.documentCurrencyType)?.label || 'N/A'}
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default DocumentHeaderAmount