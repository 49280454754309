import React, {useState} from 'react'
import Principal from './Principal'
import Policies from './Policies'
import Insurees from './Insurees'
import InsureeInfo from './InsureeInfo'
import EditableValue from '../shared/EditableValue'
import isEmpty from '../../../../../utils/isEmpty'

const PolicyAndInsuree = (
  {
    shipment,
    policyId,
    setPolicyId,
    countryIso,
    insureeId,
    setInsureeId,
    initialInsuree,
    formAlerts,
    setFormAlerts,
    containerName,
    setShowRejectButton,
    principalDni,
    setPrincipalDni,
    principalDniType,
    setPrincipalId,
    setInsuree,
    setPolicy,
    setAllowedInsurances,
    isEditable,
    isFullInsurer
  }
) => {

  const [policies, setPolicies] = useState([])
  const [insurees, setInsurees] = useState([])
  const [editable, setEditable] = useState(isEmpty(insureeId) || insureeId === '-1')
  const [firstRun, setFirstRun] = useState(true)

  return (
    <div className={'form-row'}>
      <div className={'col-11'}>
        <div className={'form-row'}>
          <div className={'col-12'}>
            <div className={'form-row'}>
              <Principal
                {...
                  {
                    countryIso,
                    principalDni,
                    setPrincipalDni,
                    principalDniType,
                    setPrincipalId,
                    setPolicies,
                    policyId,
                    setPolicyId,
                    policies,
                    insureeId,
                    formAlerts,
                    setFormAlerts,
                    containerName,
                    editable,
                    firstRun,
                    setFirstRun,
                    setShowRejectButton
                  }
                }
              />
              <Policies
                {...
                  {
                    policies,
                    policyId,
                    setPolicyId,
                    formAlerts,
                    insureeId,
                    setShowRejectButton,
                    editable,
                    setPolicy,
                    setAllowedInsurances,
                    isFullInsurer
                  }
                }
              />
              <Insurees
                {...
                  {
                    policies,
                    policyId,
                    insurees,
                    insureeId,
                    setInsurees,
                    setInsureeId,
                    formAlerts,
                    setShowRejectButton,
                    editable,
                    setEditable,
                    firstRun,
                    setInsuree,
                    isFullInsurer
                  }
                }
              />
              {(initialInsuree && (parseInt(initialInsuree.id) !== parseInt(insureeId))) && (
                <div className={'col-12'}>
                  <div className={'alert alert-danger'}>
                    <strong>Atención!</strong> Cambio de asegurado. El asegurado original era {initialInsuree.full_name}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={'col-12'}>
            <div className={'form-row justify-content-end align-items-center mb-2'}>
              <InsureeInfo
                {...
                  {
                    insurees,
                    insureeId,
                    shipment,
                    initialInsuree,
                    isFullInsurer
                  }
                }
              />
            </div>
          </div>
        </div>
      </div>
      {!editable && (
        <div className={'col-1'}>
          <EditableValue setEditable={setEditable} isEditable={isEditable}/>
        </div>
      )}
    </div>
  )
}

export default PolicyAndInsuree