import React, {useState} from 'react'
import Select from '../../../../shared/Select'
import Label from "../../../../shared/Label";

const PayTo = (
  {
    payTo,
    setPayTo,
    isEditable
  }
) => {

  const [payToState, setPayToState] = useState(payTo || "B");

  const selectionHandler = (e) => {
    let value = e.target.value
    setPayToState(value);
    setPayTo(value);
  }

  const getOptions = () => {
    return [
      {value: "B", label: "Beneficiario"},
      {value: "P", label: "Prestador"},
      {value: "E", label: "Empresa"}
    ]
  }

  const getCurrentLabel = () => {
    const currentOption = getOptions().find(option => option.value === payToState);
    return currentOption ? currentOption.label : '';
  }

  return (
    <div className="col form-row justify-content-center align-items-center">
      <Label className="col text-right">Pagar a:</Label>
      {isEditable ? (
        <Select
          name="payTo"
          value={payToState}
          className="form-control col"
          onChange={selectionHandler}
          options={getOptions()}
        />
      ) : (
        <Label className="form-control col">{getCurrentLabel()}</Label>
      )}
    </div>
  );
}

export default PayTo