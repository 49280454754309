import React from 'react'
import getContainerErrorClass from '../utils/getContainerErrorClass'

const StepContainer = (
  {
    className,
    cardHeader,
    containerName,
    formAlerts,
    children,
    cardFooter,
    showContent,
    containerClassName,
    scrollClass,
    ...props
  }
) => {
  return (
    <div className={containerClassName !== undefined ? containerClassName : 'pb-4 col-12'}>
      <div className={getContainerErrorClass(formAlerts, containerName) + ' card h-100 requirement-form-step-container ' + className} {...props}>
        {cardHeader && (
          <div className={'card-header'}>
            {cardHeader}
          </div>
        )}
        {children && (showContent === undefined || showContent) && (
          <div className={'card-body'}>
            {children}
          </div>
        )}
        {cardFooter && (
          <div className={'card-footer'}>
            {cardFooter}
          </div>
        )}
      </div>
    </div>
  )
}

export default StepContainer