const dateToLiquidatorFormat = (docDate) => {
  var formattedDate;
  const dateArray = docDate.split('-');
  if (dateArray.length == 3) {
    formattedDate = dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0];
  } else {
    formattedDate = "";
  }
  return formattedDate;
}

export default dateToLiquidatorFormat;