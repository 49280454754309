import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import ShowAttachment from './ShowAttachment'

const SelectAttachmentModal = (
  {
    showSelectAttachmentModal,
    setShowSelectAttachmentModal,
    attachments,
    handleConfirm,
    selectedAttachment
  }
) => {

  const [attachment, setAttachment] = useState(selectedAttachment)

  const getAttachmentId = () => {
    return attachment ? parseInt(attachment.id) : 0
  }

  const handleClose = () => setShowSelectAttachmentModal(false)

  const handleClickAttachment = (event) => {
    let newAttachment = JSON.parse(event.currentTarget.dataset.attachment),
      newAttachmentId = parseInt(newAttachment.id)
    if (newAttachmentId === getAttachmentId()) {
      // Soporte para deseleccionar al doble click
      newAttachment = null
    }
    setAttachment(newAttachment)
  }

  const isSelected = (id) => {
    return parseInt(id) === getAttachmentId()
  }

  const handleConfirmBtnClick = () => {
    handleConfirm(attachment)
  }

  return (
    <>
      <Modal show={showSelectAttachmentModal} onHide={handleClose} className={'requirement_form_attachment_modal'}>
        <Modal.Header>
          <Modal.Title>Seleccionar adjunto</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className={'row'}>
            {attachments.map((attachment) => (
              <ShowAttachment
                attachment={attachment}
                handleOnClick={handleClickAttachment}
                selected={isSelected(attachment.id)}
                key={attachment.id}
              />
            ))}
            {attachments.length == 0 &&
            <em className='ml-3'>No hay archivos para seleccionar</em>}
          </div>
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={'btn btn-primary'}
            onClick={handleConfirmBtnClick}
          >
            Confirmar
          </div>
          <div
            className={'btn btn-light'}
            onClick={handleClose}
          >
            Cerrar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SelectAttachmentModal