import React from 'react'
import DocumentFooterAmounts from './DocumentFooterAmounts'
import DocumentFooterRejects from './DocumentFooterRejects'
import {faFileCirclePlus} from '@fortawesome/free-solid-svg-icons'
import FormActionButton from '../../../../../../shared/FormActionButton'
import DuplicatedDocumentReject from '../shared/DuplicatedDocumentReject'

const DocumentFooter = (
  {
    document,
    setDocument,
    handleActionButtonClick,
    index,
    formAlerts,
    setFormAlerts,
    backgroundOptions,
    isAccountingDocument,
    documents,
    setDocuments,
    isEditable
  }
) => {

  const getActionButtonClassAndText = () => {
    let className,
      text,
      icon = faFileCirclePlus
    if (!!isAccountingDocument) {
      text = 'Eliminar'
      className = 'btn-danger'
      icon = false
    }else if (document.isAccountingDocument) {
      if (window.insurerIsPrimaryInsurance) {
        text = 'Agregar linea'
        className = 'btn--bonus'
      } else {
        text = 'Agregar lineas sin previsión'
        className = 'btn--without_prevision'
      }
    }else {
      text = 'Agregar linea'
      className = 'btn--bonus'
    }
    return {
      className: className,
      text: text,
      icon: icon
    }
  }

  let {className, text, icon} = getActionButtonClassAndText()

  return (
    <div className={'row d-flex justify-content-between align-items-center w-100'}>
      <div className={'col form-action-buttons justify-content-start w-100 d-flex align-items-center'}>
        {document.provisionDetails.length > 0 ? (
          <DocumentFooterAmounts
            {...
              {
                document
              }
            }
          />
        ) : (
          <div className={'d-flex justify-content-start align-items-center w-100'}>
            <FormActionButton
              title={text}
              className={className}
              onClick={handleActionButtonClick}
              content={text}
              icon={icon}
            />
            <DuplicatedDocumentReject
              {...
                {
                  document
                }
              }
              className={'d-flex px-3 justify-content-start align-items-center footer-reject'}
            />
          </div>
        )}
      </div>
      <div className={'row col justify-content-end d-flex align-items-center'}>
        <DocumentFooterRejects
          {...
            {
              document,
              setDocument,
              index,
              formAlerts,
              backgroundOptions,
              documents,
              setDocuments,
              isEditable
            }
          }
        />
      </div>
    </div>
  )
}

export default DocumentFooter