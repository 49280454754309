import React, {useRef, useState} from 'react'
import Input from '../../../../../../../shared/Input'
import getInputErrorClass from '../../../../../../../utils/getInputErrorClass'
import FormAlert from '../../../../form_header/shared/FormAlert'
import getInputErrorMessage from '../../../../../../../utils/getInputErrorMessage'
import Label from '../../../../../../../shared/Label'
import DatePicker from 'react-datepicker'
import rutFormat from '../../../../../../../utils/rutFormat'
import Textarea from '../../../../../../../shared/Textarea'
import showLoading from '../../../../../../../utils/showLoading'
import axios from 'axios'
import Swal from 'sweetalert2'
import closeAlert from '../../../../../../../utils/closeAlert'
import {
  faCloudUpload,
  faFileCircleCheck
} from '@fortawesome/free-solid-svg-icons'
import ProtectedFile from '../../../../form_header/files/ProtectedFile'
import convertDateToString from '../../../../../../../utils/convertDateToString'
import {requirementCreatePrescription} from '../../../../../../../routes/requirements'
import isEmpty from '../../../../../../../utils/isEmpty'
import addErrorAlert from '../../../../../../../utils/addErrorAlert'
import removeAlertByField from '../../../../../../../utils/removeAlertByField'
import Select from '../../../../../../../shared/Select'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import SelectAttachmentModal from '../../shared/SelectAttachmentModal'
import FormActionButton from '../../../../../../../shared/FormActionButton'

const PrescriptionsNewPrescription = (
  {
    prescriptions,
    setNewPrescription,
    setPrescriptions,
    setSelectedRowId,
    setSelectedPage,
    getFirstPage,
    setTableKey,
    attachments,
    insuree
  }
) => {

  const expirationDateOptions = [
    {
      value: 0,
      label: '3 meses'
    },
    {
      value: 1,
      label: '6 meses'
    },
    {
      value: 2,
      label: '1 año'
    }
  ]

  const providerBusinessIdTypeOptions = [
    {
      value: 1,
      label: 'RUT (CL)'
    },
    {
      value: 2,
      label: 'CURP (MX)'
    }
  ]

  const [date, setDate] = useState(new Date())
  const [providerBusinessId, setProviderBusinessId] = useState('')
  const [providerBusinessIdType, setProviderBusinessIdType] = useState(1)
  const [providerName, setProviderName] = useState('')
  const [description, setDescription] = useState('')
  const [expirationDate, setExpirationDate] = useState(expirationDateOptions[0].value)
  const [showSelectAttachmentModal, setShowSelectAttachmentModal] = useState(false)
  const [attachment, setAttachment] = useState('')
  const [formAlerts, setFormAlerts] = useState([])
  const {insurerId, requirementId} = window
  const containerName = 'prescriptions'

  const sendPrescription = async () => {
    let formData = new FormData(),
      error = false
    formData.append('insurer_id', insurerId)
    formData.append('insuree_id', insuree?.value)
    formData.append('requirement_id', requirementId)
    formData.append('issue_date', convertDateToString(date))
    formData.append('provider_business_id', providerBusinessId)
    formData.append('provider_business_id_type', providerBusinessIdType)
    formData.append('provider_name', providerName)
    formData.append('description', description)
    formData.append('expiration_date', expirationDate)
    if (attachment) {
      formData.append('protected_file_id', attachment.id)
    }
    showLoading()
    await axios({
      url: requirementCreatePrescription,
      method: 'POST',
      data: formData,
      headers:{
        'Content-Type':'multipart/form-data',
        'Accept': 'application/json'
      }
    })
    .then ((res) => {
      let data = res.data,
        {prescription} = data
      setNewPrescription(false)
      setPrescriptions([... prescriptions, prescription])
      setSelectedRowId(prescription.id)
      setSelectedPage(getFirstPage(prescription.id))
      setTableKey('table-key-' + prescription.id)
    })
    .catch(err =>{
      // Error, avisamos al usuario
      error = true
    })
    .finally(() => {
      if (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocurrió un inconveniente al subir el archivo. Intente nuevamente',
          confirmButtonColor: '#0281c4'
        })
      } else {
        closeAlert()
      }
    })
  }

  const addEmptyFieldError = (field_id, field, alerts) => {
    return addErrorAlert(alerts, containerName, field_id, <span>El campo {field} es requerido.</span>)
  }

  const validData = () => {
    let resp = true,
      newFormAlerts = formAlerts
    if (isEmpty(date)) {
      newFormAlerts = addEmptyFieldError('date', 'Fecha', newFormAlerts)
      resp = false
    }
    if (isEmpty(providerBusinessId)) {
      newFormAlerts = addEmptyFieldError('provider_business_id', 'Identificador Tributario Profesional', newFormAlerts)
      resp = false
    }
    if (isEmpty(providerName)) {
      newFormAlerts = addEmptyFieldError('provider_name', 'Nombre', newFormAlerts)
      resp = false
    }
    if (isEmpty(description)) {
      newFormAlerts = addEmptyFieldError('description', 'Descripción', newFormAlerts)
      resp = false
    }
    if (isEmpty(attachment)) {
      newFormAlerts = addEmptyFieldError('attachment', 'Archivo adjunto', newFormAlerts)
      resp = false
    }
    if (!resp) {
      setFormAlerts(newFormAlerts)
    }
    return resp
  }

  const handleSaveBtnClick = () => {
    if (validData()) {
      // Enviamos nueva receta a crear y asociar al paciente
      sendPrescription()
    }
  }

  const handleCancelBtnClick = () => {
    setNewPrescription(false)
  }

  const handleDateChange = (value) => {
    setDate(value)
    setFormAlerts(removeAlertByField(formAlerts, 'date'))
  }

  const handleProviderBusinessIdChange = (event) => {
    if (providerBusinessIdType == 1){
      setProviderBusinessId(rutFormat(event.target.value))
    } else {
      // TODO: Crear extensiones que formateen otros identificadores
      setProviderBusinessId(event.target.value)
    }
    setFormAlerts(removeAlertByField(formAlerts, 'provider_business_id'))
  }

  const handleProviderNameChange = (event) => {
    setProviderName(event.target.value)
    setFormAlerts(removeAlertByField(formAlerts, 'provider_name'))
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
    setFormAlerts(removeAlertByField(formAlerts, 'description'))
  }

  const inputRef = useRef(null)

  const handleAddFileBtnClick = () => {
    inputRef.current.click()
  }

  const uploadFile = async (file) => {
    let formData = new FormData()
    formData.append('protected_file[file]', file)
    $('form input[name=utf8],form input[name=authenticity_token]').each(function () {
      let $elem = $(this)
      formData.append($elem.attr('name'),$elem.val())
    })
    showLoading()
    let error = false
    await axios({
      url: window.location.origin + '/insurers/' + window.insurerId + '/protected_files/Prescription',
      method: 'POST',
      data: formData,
      headers:{
        'Content-Type':'multipart/form-data',
        'Accept': 'application/json'
      }
    })
      .then ((res) => {
        let data = res.data
        setAttachment(data)
      })
      .catch(err =>{
        // Error, avisamos al usuario
        error = true

        // Para trazabilidad
        console.log(err)
      })
      .finally(() => {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un inconveniente al subir el archivo. Intente nuevamente',
            confirmButtonColor: '#0281c4'
          })
        }else {
          closeAlert()
        }
      })
  }

  const handleFileChange = (event) => {
    const file = event.target.files && event.target.files[0]
    if (!file) {
      return
    }
    setFormAlerts(removeAlertByField(formAlerts, 'attachment'))
    // reseteamos el valor del input
    event.target.value = null
    // enviamos archivo
    uploadFile(file)
  }

  const handleProtectedFileRemove = () => {
    setAttachment('')
  }

  const handleExpirationDateChange = (event) => {
    setExpirationDate(event.target.value)
  }

  const handleProviderBusinessIdTypeChange = (event) => {
    setProviderBusinessIdType(event.target.value)
  }

  const handleConfirmSelectAttachmentModal = (attachment) => {
    setAttachment(attachment)
    setShowSelectAttachmentModal(false)
  }

  const handleSelectAttachmentButtonClick = () => {
    setShowSelectAttachmentModal(true)
  }

  return (
    <div className={'py-3 d-flex justify-content-between align-items-start row'}>

      <div className={'row col-12 col-lg-4 mb-3'}>
        <Label htmlFor={'new_prescriptions_date'} className={'col-12'}>Fecha:</Label>
        <div className={'col-12'}>
          <DatePicker
            selected={date}
            onChange={handleDateChange}
            dateFormat={'dd/MM/yyyy'}
            className={'form-control' + getInputErrorClass(formAlerts, 'date')}
          />
          <FormAlert
            message={getInputErrorMessage(formAlerts, 'date')}
            className={'w-100'}
          />
        </div>
      </div>

      <div className={'row col-12 col-lg-4 mb-3'}>
        <Label htmlFor={'new_prescriptions_provider_business_id_type'} className={'col-12'}>Tipo de Identificador Tributario Profesional:</Label>
        <div className={'col-12'}>
          <Select
            id={'provider_business_id_type'}
            options={providerBusinessIdTypeOptions}
            value={providerBusinessIdType}
            onChange={handleProviderBusinessIdTypeChange}
            placeholder={'Seleccione un tipo de identificador tributario'}
          />
          <FormAlert
            message={getInputErrorMessage(formAlerts, 'provider_business_id_type')}
            className={'w-100'}
          />
        </div>
      </div>

      <div className={'row col-12 col-lg-4 mb-3'}>
        <Label htmlFor={'new_prescriptions_provider_business_id'} className={'col-12'}>Identificador Tributario Profesional:</Label>
        <div className={'col-12'}>
          <Input
            id={'new_prescriptions_provider_business_id'}
            type={'text'}
            placeholder={'Identificador tributario profesional'}
            value={providerBusinessId}
            onChange={handleProviderBusinessIdChange}
            className={'form-control' + getInputErrorClass(formAlerts, 'provider_business_id')}
          />
          <FormAlert
            message={getInputErrorMessage(formAlerts, 'provider_business_id')}
            className={'w-100'}
          />
        </div>
      </div>

      <div className={'row col-12 col-lg-4 mb-3'}>
        <Label htmlFor={'new_prescriptions_provider_name'} className={'col-12'}>Nombre del Profesional:</Label>
        <div className={'col-12'}>
          <Input
            id={'new_prescriptions_provider_name'}
            type={'text'}
            placeholder={'Nombre'}
            value={providerName}
            onChange={handleProviderNameChange}
            className={'form-control' + getInputErrorClass(formAlerts, 'provider_name')}
          />
          <FormAlert
            message={getInputErrorMessage(formAlerts, 'provider_name')}
            className={'w-100'}
          />
        </div>
      </div>

      <div className={'row col-12 col-lg-4 mb-3'}>
        <Label htmlFor={'new_prescriptions_description'} className={'col-12'}>Descripción:</Label>
        <div className={'col-12'}>
          <Textarea
            placeholder={'Descripción'}
            value={description}
            onChange={handleDescriptionChange}
            className={'form-control' + getInputErrorClass(formAlerts, 'description')}
          />
          <FormAlert
            message={getInputErrorMessage(formAlerts, 'description')}
            className={'w-100'}
          />
        </div>
      </div>

      <div className={'row col-12 col-lg-4 mb-3'}>
        <Label htmlFor={'new_prescriptions_description'} className={'col-12'}>Vigencia de la Receta:</Label>
        <div className={'col-12'}>
          <Select
            value={expirationDate}
            onChange={handleExpirationDateChange}
            className={'col'}
            options={expirationDateOptions}
          />
          <FormAlert
            message={getInputErrorMessage(formAlerts, 'expiration_date')}
            className={'w-100'}
          />
        </div>
      </div>

      <div className={'row col-12 col-lg-4 mb-3'}>
        <Label htmlFor={'new_prescriptions_description'} className={'col-12'}>Archivo Adjunto:</Label>
        <div className={'col-12'}>
          <Input
            type={'file'}
            className={'d-none'}
            ref={inputRef}
            onChange={handleFileChange}
          />
          {attachment && (
            <ProtectedFile
              {...
                {
                  handleProtectedFileRemove
                }
              }
              protectedFile={attachment}
            />
          )}
          <FormAlert
            message={getInputErrorMessage(formAlerts, 'attachment')}
            className={'w-100'}
          />
          <div className={'row'}>
            <div className={'col-6'}>
              <FormActionButton
                title={'Subir adjunto'}
                className={'btn btn-success w-100'}
                onClick={handleAddFileBtnClick}
                content={'Subir adjunto'}
                icon={faCloudUpload}
              />
            </div>
            <div className={'col-6'}>
              <FormActionButton
                title={'Seleccionar adjunto'}
                className={'btn btn-info w-100'}
                onClick={handleSelectAttachmentButtonClick}
                content={'Seleccionar adjunto'}
                icon={faFileCircleCheck}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={'col-12'}>
        <div
          className={'btn btn-primary mr-3'}
          onClick={handleSaveBtnClick}
        >
          Guardar nueva receta
        </div>
        <div
          className={'btn btn-light'}
          onClick={handleCancelBtnClick}
        >
          Cancelar
        </div>
      </div>

      <SelectAttachmentModal
        {...
          {
            showSelectAttachmentModal,
            setShowSelectAttachmentModal,
            attachments
          }
        }
        handleConfirm={handleConfirmSelectAttachmentModal}
        selectedAttachment={attachment}
      />

    </div>
  )
}

export default PrescriptionsNewPrescription