import addSimpleRejectReason from './addSimpleRejectReason'

const buildSimpleRejectFormData = (formData, action, reject_type, array, count, rejectReasonId, rejectType, comments, active) => {
  if (array.length === 0 && action === 'reject' && reject_type === rejectType) {
    count = addSimpleRejectReason(0, null, formData, comments, rejectReasonId, count, true, active)
  }else {
    array.forEach((hospitableRejectDetail, index) => {
      count = addSimpleRejectReason(index, hospitableRejectDetail.id, formData, comments, rejectReasonId, count, true, active)
    })
  }
  return count
}

export default buildSimpleRejectFormData