import baseApiV2Requirements from './baseApiV2Requirements'

export const requirementSaveHeader = baseApiV2Requirements + '/save_header'

export const requirementRejectByBackgrounds = baseApiV2Requirements + '/reject_by_backgrounds'

export const requirementRejectByHospitable = baseApiV2Requirements + '/reject_by_hospitable'

export const requirementRejectByNI = baseApiV2Requirements + '/reject_by_no_incorporated'

export const requirementSetDoing = window.location.origin + '/real_time_registry/registry_usage'

export const requirementRelease = baseApiV2Requirements + '/release'

export const requirementCreatePrescription = baseApiV2Requirements + '/create_prescription'

export const requirementEvaluate = baseApiV2Requirements + '/evaluate'

export const requirementInsureeLogs = baseApiV2Requirements + '/insuree_logs'