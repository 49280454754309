import React from 'react'

const HiddenInput = ({id, value, ...rest}) => {
  return (
    <input
      type={'hidden'}
      id={id}
      value={value}
      {...rest}
    />
  )
}

export default HiddenInput