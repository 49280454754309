import Swal from 'sweetalert2'

const showLoading = () => {
  Swal.fire({
    html: '<div class="loading-swal">' +
      '<div class="fa-container">' +
      '<i class="fa fa-spinner fa-spin"></i>' +
      '</div>' +
      '</div>',
    showCloseButton: false,
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    customClass: 'pretty-loading'
  })
}

export default showLoading