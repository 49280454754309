import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import BackgroundItem from '../../../../../../shared/BackgroundItem'
import {backgroundsRejectCode} from '../../../../../../utils/constants'

const DocumentFooterBackgroundsModal = (
  {
    document,
    setDocument,
    index,
    backgroundOptions,
    showBackgroundsModal,
    setShowBackgroundsModal,
    setProvisionDetail
  }
) => {

  const baseBackground = () => {
    return {
      id: null,
      reject_detail_id: null,
      preceding_id: backgroundOptions[0].id,
      details: ''
    }
  }

  const documentBackgroundRejects = document.backgroundsRejects

  const [background, setBackground] = useState(useState(documentBackgroundRejects.length > 0 ? documentBackgroundRejects[0].background : baseBackground()))

  const handleClose = () => setShowBackgroundsModal(false)

  const handleRejectBtnClick = () => {
    let newDocument = document,
      rejectDetails = document.rejectDetails
    newDocument.rejectDetails = [... rejectDetails.filter((rejectDetail) => rejectDetail.code !== backgroundsRejectCode), document.buildDocumentBackgroundsRejectDetail(background)]
    setDocument(index, newDocument)
    updateProvisionDetails(newDocument)
  }

  const updateBackground = (_, value) => {
    setBackground(value)
  }

  const updateProvisionDetails = (newDocument) => {
    newDocument.provisionDetails.map((item, index) => {
      setProvisionDetail(index, item)
    })
  }

  return (
    <>
      <Modal show={showBackgroundsModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Rechazo: Falta de antecedentes</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h4 className={'mt-3'}>Antecedentes:</h4>
          <BackgroundItem
            background={background}
            backgroundKey={0}
            backgroundOptions={backgroundOptions}
            updateBackground={updateBackground}
            handleBackgroundRemove={() => {}}
            showBackgroundRemoveButton={false}
          />
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={'btn btn-danger'}
            onClick={handleRejectBtnClick}
          >
            Rechazar documento por falta de antecedentes
          </div>
          <div
            className={'btn btn-default'}
            onClick={handleClose}
          >
            Cerrar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DocumentFooterBackgroundsModal