import numberToThousands from './numberToThousands'

const numberToCurrency = (number, currency) => {
  switch (currency) {
    case 'CLP':
      currency = '$'
      break;
    case 'CLF':
      currency = 'UF'
      break;
  }
  return currency + ' ' + numberToThousands(number)
}

export default numberToCurrency