import Swal from 'sweetalert2'

const alertWhileRedirect = (icon, title, text, redirect_to) => {
  Swal.fire({
    icon: icon,
    title: title,
    text: text,
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
      Swal.showLoading()
    }
  }).then(() => {
    window.location.href = redirect_to
  })
}

export default alertWhileRedirect