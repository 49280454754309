const getCurrencyTypes = () => {
  let resp = [
    {
      value: 'CLP',
      label: '$'
    },
    {
      value: 'CLF',
      label: 'UF'
    }
  ]
  switch (window.language) {
    // TODO: devolver los posibles tipos de moneda de acuerdo al language del browser
  }
  return resp
}

export default getCurrencyTypes