import React, {useState, useEffect} from 'react'
import FormActionButton from '../../../../../shared/FormActionButton'
import SimpleRejectModal from './SimpleRejectModal'
import Alert from 'react-bootstrap/Alert'
import {
  requirementRejectByHospitable,
  requirementRejectByNI
} from '../../../../../routes/requirements'
import {
  faTooth,
  faAmbulance,
  faHospital,
  faMailReplyAll
} from '@fortawesome/free-solid-svg-icons'

const HeaderFormActionButtons = (
  {
    showRejectButton,
    comments,
    setComments,
    formAlerts,
    setShowHealthForm,
    saveHeader,
    buildHeaderFormData,
    principalDni,
    setShowBackgroundsModal,
    setShowDental,
    setShowDentalV2,
    insurerDentalV2Enabled,
    allowedInsurances
  }
) => {

  const [showHospitableRejectModal, setShowHospitableRejectModal] = useState(false)
  const [showNIRejectModal, setShowNIRejectModal] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [disabledHealth, setDisableHealth] = useState(true)
  const [disabledDental, setDisableDental] = useState(insurerDentalV2Enabled) //Si tiene V2 habilitado, el botón parte deshabilitado. Si no tiene V2 habilitado se da la opción de crear el gasto dental V1

  const showHealthForm = () => {
    setShowHealthForm(true)
  }

  const showDental = () => {
    // Según el parámetro de evaluación permitida
    if (insurerDentalV2Enabled) {
      setShowDentalV2(true)
    } else {
      setShowDental(true)
    }
  }


  const handleDentalBtnClick = () => {
    // Guardamos encabezado
    saveHeader(true, true, showDental, 'dental')
    // cambiamos el estado de los botones
    setDisabledButton(true)
  }

  const handleAmbulatoryBtnClick = () => {
    // Guardamos encabezado y Renderizamos salud
    saveHeader(false, false, showHealthForm, 'health')
    // cambiamos el estado de los botones
    setDisabledButton(true)
  }

  const handleHospitableBtnClick = () => {
    // Mostramos modal para ingresar comentario
    setShowHospitableRejectModal(true)
  }

  const handleRejectBackgroundBtnClick = () => {
    // Mostramos modal para ingresar comentario y antecedentes
    setShowBackgroundsModal(true)
  }

  const handleRejectNiBtnClick = () => {
    // Mostramos modal para ingresar comentario
    setComments('Titular: ' + principalDni);
    setShowNIRejectModal(true)
  }

  useEffect(() => {
    if (allowedInsurances.length > 0) {
      // Guardamos encabezado y Rechazamos total por falta de antecedentes
      setDisableHealth(!allowedInsurances.includes('health'))
      setDisableDental(!allowedInsurances.includes('dental'))
    }
  }, [allowedInsurances])

  return (
    <>
      <div className={'form-action-buttons'}>
        <FormActionButton
          title={'Gasto Dental'}
          className={'btn-success'}
          onClick={handleDentalBtnClick}
          content={'Gasto Dental'}
          icon={faTooth} 
          disabled={disabledButton || disabledDental}
        />
        <FormActionButton
          title={window.insurerIsPrimaryInsurance ? 'Gasto Salud' : 'Gasto Salud Ambulatorio'}
          className={'btn-info'}
          onClick={handleAmbulatoryBtnClick}
          content={window.insurerIsPrimaryInsurance ? 'Gasto Salud' : 'Gasto Salud Ambulatorio'}
          icon={faAmbulance}
          disabled={disabledButton || disabledHealth}
        />
        { !window.insurerIsPrimaryInsurance && (
          <FormActionButton
            title={'Gasto Salud Hospitalario'}
            className={'btn-danger'}
            onClick={handleHospitableBtnClick}
            content={'Gasto Salud Hospitalario'}
            icon={faHospital}
            disabled={disabledButton || disabledHealth}
          />
        )}

        { showRejectButton && (
          <>
            <FormActionButton
              title={'Rechazo total: Asegurado no incorporado'}
              className={'btn-danger'}
              onClick={handleRejectNiBtnClick}
              content={'Asegurado No Incorporado'}
              disabled={disabledButton}
            />
            <SimpleRejectModal
              {...
                {
                  comments,
                  setComments,
                  saveHeader,
                  buildHeaderFormData,
                  formAlerts
                }
              }
              showModal={showNIRejectModal}
              setShowModal={setShowNIRejectModal}
              title={'Rechazo total: Asegurado no incorporado en póliza'}
              rejectType={'no_incorporated'}
              rejectUrl={requirementRejectByNI}
            />
          </>
        )}
        <FormActionButton
          title={'Rechazo total: Falta de Antecedentes'}
          className={'btn-danger'}
          onClick={handleRejectBackgroundBtnClick}
          content={'Falta de Antecedentes'}
          icon={faMailReplyAll}
          disabled={disabledButton}
        />
        <SimpleRejectModal
          {...
            {
              comments,
              setComments,
              saveHeader,
              buildHeaderFormData,
              formAlerts
            }
          }
          showModal={showHospitableRejectModal}
          setShowModal={setShowHospitableRejectModal}
          title={'Rechazo total: Gasto Salud Hospitalario / Observaciones'}
          rejectType={'hospitable'}
          rejectUrl={requirementRejectByHospitable}
        />
      </div>
    </>
  )
}

export default HeaderFormActionButtons