import React from 'react'
import getIconByContentType from '../../../../../utils/getIconByContentType'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import openInNewTab from '../../../../../utils/openInNewTab'

const ProtectedFile = (
  {
    protectedFile,
    handleProtectedFileRemove
  }
) => {

  const fileContentType = protectedFile.file_content_type
  const fileFileName = protectedFile.file_file_name
  const fileUrl = protectedFile.url
  const contentTypeIcon = getIconByContentType(fileContentType)

  const handleOnClick = () => {
    openInNewTab(fileUrl)
  }

  return (
    <div
      className={'mb-2 d-flex align-items-center'}
    >
      <div
        className={'d-flex mr-3 align-items-center justify-content-center text-info pointer-as-btn'}
        onClick={handleOnClick}
      >
        <FontAwesomeIcon icon={contentTypeIcon} className={'mr-2 mb-0'} size={'xl'}/>
        {fileFileName}
      </div>
      <div
        className={'text-error text-danger remove_fields dynamic pointer-as-btn'}
        title={'Eliminar documento'}
        data-id={protectedFile.id}
        onClick={handleProtectedFileRemove}
      >
        <FontAwesomeIcon icon={faTrashAlt} className={'icon-remove'}/>
      </div>
    </div>
  )
}

export default ProtectedFile