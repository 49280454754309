import React, {useState} from 'react'
import getIconByContentType from '../utils/getIconByContentType'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import itsAnImage from '../utils/itsAnImage'

const ImageWithPreview = (
  {
    contentType,
    fileName,
    fileSrc
  }
) => {

  const [imageNotFound, setImageNotFound] = useState(false)
  const contentTypeIcon = getIconByContentType(contentType)

  return (
    <>
      <div className={'initial-file-preview'}>
        {itsAnImage(contentType) && !imageNotFound ? (
          <img
            className={'initial-file-preview__preview initial-file-preview__preview--img'}
            src={fileSrc}
            alt={'Sin vista previa'}
            onError={() => setImageNotFound(true)}
          />
        ) : (
          <div className={'initial-file-preview__preview'}>
            <FontAwesomeIcon icon={contentTypeIcon} size={'2xl'}/>
            Sin vista previa
          </div>
        )}
      </div>
      <span>{fileName}</span>
    </>
  )
}

export default ImageWithPreview