import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Comments from '../../../../../shared/Comments'
import rejectRequest from '../../../../../utils/rejectRequest'

const SimpleRejectModal = (
  {
    comments,
    setComments,
    saveHeader,
    showModal,
    setShowModal,
    buildHeaderFormData,
    formAlerts,
    title,
    rejectType,
    rejectUrl
  }
) => {

  const handleClose = () => setShowModal(false)

  const reject = async () => {
    let formData = buildHeaderFormData('reject', rejectType, 'dental')
    rejectRequest(formData, rejectUrl)
  }

  const handleFinishBtnClick = () => {
    // Guardamos encabezado y Rechazamos total por gasto salud hospitalario
    saveHeader(true, false, reject)
  }

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Comments
            {...
              {
                comments,
                setComments,
                formAlerts
              }
            }
          />
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={'btn btn-primary'}
            onClick={handleFinishBtnClick}
          >
            Terminar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SimpleRejectModal