import React from 'react'

const Textarea = ({value, className, ...rest}) => {
  return (
    <textarea
      value={value}
      className={'form-control ' + (className !== undefined ? className : '')}
      {...rest}
    />
  )
}

export default Textarea