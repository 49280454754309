const addSimpleRejectReason = (index, id, formData, comments, rejectReasonId, count, plusCount, active) => {
  let realIndex = plusCount ? index + count : index
  formData.append('requirement[reject_details_attributes][' + realIndex + '][_destroy]', 'false')
  formData.append('requirement[reject_details_attributes][' + realIndex + '][active]', active)
  formData.append('requirement[reject_details_attributes][' + realIndex + '][value]', 'TOTAL')
  formData.append('requirement[reject_details_attributes][' + realIndex + '][reject_reason_id]', rejectReasonId)
  formData.append('requirement[reject_details_attributes][' + realIndex + '][comment]', comments)
  if (id) {
    formData.append('requirement[reject_details_attributes][' + realIndex + '][id]', id)
  }
  count = count + 1
  return count
}

export default addSimpleRejectReason