import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Textarea from '../../../../shared/Textarea'
import errorSwalAlert from '../../../../utils/errorSwalAlert'

const HoldBackModal = (
  {
    requirement,
    updateRequirement,
    showHoldBackModal,
    setShowHoldBackModal,
    holdBackRequirementCallback
  }
) => {

  const [holdBackComments, setHoldBackComments] = useState(requirement.holdBackComments)

  const handleHoldBack = () => {
    if (holdBackComments === '') {
      // No puede seguir hasta haber ingresado un mótivo de retención antes de continuar
      errorSwalAlert('Debe ingresar un mótivo de retención antes de continuar')
    }else {
      holdBackRequirementCallback(holdBackComments)
      // Cerramos modal
      setShowHoldBackModal(false)
    }
  }

  const handleClose = () => {
    setShowHoldBackModal(false)
  }

  const handleChange = (event) => {
    setHoldBackComments(event.target.value)
  }

  return (
    <>
      <Modal
        show={showHoldBackModal}
        onHide={handleClose}
        className={'accounting-documents-modal'}
      >
        <Modal.Header>
          <Modal.Title>Retener gasto</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Textarea
            placeholder={'Motivo de retención'}
            value={holdBackComments}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={'btn btn-warning'}
            onClick={handleHoldBack}
          >
            Retener
          </div>
          <div
            className={'btn btn-light'}
            onClick={handleClose}
          >
            Cancelar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default HoldBackModal