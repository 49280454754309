import React from 'react'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const ProvisionDetailRemove = (
  {
    removeProvisionDetail,
    index
  }
) => {
  const handleOnClick = () => {
    removeProvisionDetail(index)
  }
  return (
    <>
      <FontAwesomeIcon onClick={handleOnClick} icon={faTimes} size={'sm'} className={'text-danger flex-column pointer-as-btn'}/>
    </>
  )
}

export default ProvisionDetailRemove