import React from 'react'
import {faEnvelopeOpen} from '@fortawesome/free-solid-svg-icons'
import DocumentActionButton from './DocumentActionButton'
import {duplicatedDocumentRejectCode} from '../../../../../../utils/constants'

const DuplicatedDocumentRejectButton = (
  {
    document,
    index,
    setDocument,
    documents,
    setDocuments,
    setProvisionDetail
  }
) => {

  let duplicatedDocumentRejects = document.duplicatedDocumentRejects,
    accountingDocumentsWithRejects = document.getAccountingDocumentsWithDuplicatedDocumentRejects(documents)

  const isDisabledDuplicatedDocument = () => {
    return duplicatedDocumentRejects.length === 0 && accountingDocumentsWithRejects.length === 0
  }

  const isActiveDuplicatedDocument = () => {
    return (duplicatedDocumentRejects.length > 0 ? !!duplicatedDocumentRejects[0].active : true) && (accountingDocumentsWithRejects.length > 0 ? !!accountingDocumentsWithRejects[0].duplicatedDocumentRejects[0].active : true)
  }

  const handleDuplicatedDocumentClick = () => {
    let active = isActiveDuplicatedDocument()
    if (!isDisabledDuplicatedDocument()) {
      if (duplicatedDocumentRejects.length > 0) {
        let newDocument = document,
          rejectDetails = document.rejectDetails
        duplicatedDocumentRejects[0].active = !active
        newDocument.rejectDetails = [... rejectDetails.filter((rejectDetail) => rejectDetail.code !== duplicatedDocumentRejectCode), duplicatedDocumentRejects[0]]
        setDocument(index, newDocument)
        updateProvisionDetails(newDocument)

      }
      if (accountingDocumentsWithRejects.length > 0) {
        let filteredAccountingDocuments = documents.filter((accountingDocument) => {
            return !document.accountingDocuments.includes(accountingDocument.systemId)
          }),
          mappedArray = accountingDocumentsWithRejects.map((accountingDocument) => {
            let newDocument = accountingDocument,
              rejectDetails = accountingDocument.rejectDetails,
              ddr = accountingDocument.duplicatedDocumentRejects
            ddr[0].active = !active
            newDocument.rejectDetails = [... rejectDetails.filter((rejectDetail) => rejectDetail.type !== duplicatedDocumentRejectCode), ddr[0]]
            return newDocument
          }),
          newAccountingDocuments = [... filteredAccountingDocuments, ...mappedArray]
        setDocuments(newAccountingDocuments)
      }
    }
  }

  const updateProvisionDetails = (newDocument) => {
    newDocument.provisionDetails.map((item, index) => {
      setProvisionDetail(index, item)
    })
  }

  const activeClass = () => {
    return isActiveDuplicatedDocument() ? '' : ' document-action-button--inactive'
  }

  const disabledClass = () => {
    return isDisabledDuplicatedDocument() ? ' document-action-button--disabled' : ''
  }

  return (
    <DocumentActionButton
      onClickCallback={handleDuplicatedDocumentClick}
      text={'Doc. duplicado'}
      icon={faEnvelopeOpen}
      className={'text-danger flex-column pr-3 document-action-button--fit-content' + disabledClass('duplicated_document') + activeClass()}
    />
  )
}

export default DuplicatedDocumentRejectButton