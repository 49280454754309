import {v4} from 'uuid'
import convertStringToDate from '../utils/convertStringToDate'
import getToday from '../utils/getToday'

export default class Shipment {

  constructor(props) {
    this.systemId = v4()
    this.id = props.id || null
    this.code = props.code || ''
    this.receipt_date = props.receipt_date || getToday()
  }

  // Getters
  get startDateShipment () {
    return this.receipt_date ? convertStringToDate(this.receipt_date) : null
  }

  get asJson () {
    return {
      id: this.id,
      code: parseInt(this.code),
      receipt_date: this.receipt_date
    }
  }

}