import React from 'react'
import Option from './Option'

const Select = ({id, value, name, onChange, className, options, ...rest}) => {
  return (
    <select
      name={name}
      className={'form-control ' + (className !== undefined ? className : '')}
      value={value}
      onChange={onChange}
      {...rest}
    >
      {options.map((option, index) => (
        <Option key={option.value + '_' + index} value={option.value}>
          {option.label}
        </Option>
      ))}
    </select>
  )
}

export default Select