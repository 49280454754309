import React from 'react'
import {faFileDownload} from '@fortawesome/free-solid-svg-icons'
import DocumentActionButton from '../shared/DocumentActionButton'
import openInNewTab from '../../../../../../utils/openInNewTab'

const DownloadAttachmentButton = (
  {
    document,
    withoutText
  }
) => {

  const handleDownloadButtonClick = () => {
    openInNewTab(document.attachment.url)
  }

  return (
    <>
      {document.attachment && (
        <DocumentActionButton
          onClickCallback={handleDownloadButtonClick}
          text={'Descargar'}
          icon={faFileDownload}
          className={'text-info flex-column pr-3' + (withoutText ? 'pr-2' : 'pr-3')}
        />
      )}
    </>
  )
}

export default DownloadAttachmentButton