import React from 'react'
import FormActionButton from '../../../../../../shared/FormActionButton'
import {faFileCirclePlus} from '@fortawesome/free-solid-svg-icons'

const AddAccountingDocumentButton = (
  {
    handleClick
  }
) => {
  return (
    <>
      <div className={'w-100 form-action-buttons justify-content-start'}>
        <FormActionButton
          title={'Asociar documento contable'}
          className={'btn-success'}
          onClick={handleClick}
          content={'Asociar documento contable'}
          icon={faFileCirclePlus}
        />
      </div>
    </>
  )
}

export default AddAccountingDocumentButton