import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {liquidatorsApiUrl} from '../../../../../routes/search'
import addErrorAlert from '../../../../../utils/addErrorAlert'
import closeAlert from '../../../../../utils/closeAlert'
import getInputErrorClass from '../../../../../utils/getInputErrorClass'
import getInputErrorMessage from '../../../../../utils/getInputErrorMessage'
import removeAlertByContainer from '../../../../../utils/removeAlertByContainer'
import showLoading from '../../../../../utils/showLoading'
import FormAlert from '../shared/FormAlert'
import Select from '../../../../../shared/Select'
import Label from '../../../../../shared/Label'
import EditableValue from '../shared/EditableValue'

const Liquidator = (
  {
    liquidator,
    setLiquidator,
    formAlerts,
    setFormAlerts,
    containerName,
    isEditable
  }
) => {

  const [editable, setEditable] = useState(liquidator === '')
  const [liquidators, setLiquidators] = useState([])
  const [getLiquidators, setGetLiquidators] = useState(true)
  const insurerId = window.insurerId
  let retryTimesRequest = 3

  const searchLiquidators = async () => {
    let formData = new FormData()
    formData.append('insurer_id', insurerId)
    showLoading()
    await axios({
      url: liquidatorsApiUrl,
      method: 'POST',
      data: formData
    })
      .then ((res) => {
        let data = res.data
        setLiquidators(data)
        setGetLiquidators(false)
        setFormAlerts(removeAlertByContainer(formAlerts, containerName))
      })
      .catch(err =>{
        if (retryTimesRequest === 0) {
          setLiquidators([])
          setGetLiquidators(false)
          setFormAlerts(addErrorAlert(formAlerts, containerName, 'liquidator_id', <span><strong>Error:</strong> No pudimos encontrar un listado de liquidadores. Haga click <span className={'pointer-as-btn'} onClick={searchLiquidators}><strong>aquí</strong></span> para volver a intentar</span>))
        }else {
          retryTimesRequest = retryTimesRequest - 1
          searchLiquidators()
        }
      })
      .finally(() => {
        closeAlert()
      })
  }

  const handleChange = (event) => {
    setLiquidator(JSON.parse(event.target.value))
    setEditable(false)
  }

  const getOptions = () => {
    let options = liquidators.map((liquidator) => {
        return {
          value: JSON.stringify(liquidator),
          label: liquidator.name
        }
      })
    options = [{value: '', label: ''}, ...options]
    return options
  }

  useEffect(() => {
    if (getLiquidators) {
      searchLiquidators()
    }
  }, [liquidators, liquidator])

  return (
    <>
      <div className={'form-group col form-row justify-content-center align-items-center'}>
        <Label
          className={'tooltipable col flex-grow-0'}
          htmlFor={'new_liquidator_id'}
          title={'Liquidador'}
        >
          Liquidador:
        </Label>
        {isEditable && (editable || !liquidator) ? (
          <>
            <Select
              id={'new_liquidator_id'}
              className={'col' + getInputErrorClass(formAlerts, 'liquidator_id')}
              placeholder={''}
              value={liquidator ? JSON.stringify(liquidator) : ''}
              onChange={handleChange}
              options={getOptions()}
            />
            <FormAlert
              message={getInputErrorMessage(formAlerts, 'liquidator_id')}
            />
          </>
        ) : (
          <EditableValue
            setEditable={setEditable}
            isEditable={false}
          >
            <Label
              className={'col'}
            >
              <strong>{liquidator?.name || "N/A"}</strong>
            </Label>
          </EditableValue>
        )}
      </div>
    </>
  )
}

export default Liquidator