import {v4} from 'uuid'
import moment from 'moment/moment'
import {
  backgroundsRejectCode,
  duplicatedDocumentRejectCode,
  lateDocumentRejectCode
} from '../utils/constants'
import numberToCurrency from '../utils/numberToCurrency'
import isEmpty from '../utils/isEmpty'
import Reject from './Reject'
import ProvisionDetail from './ProvisionDetail'
import asFloatNumber from '../utils/asFloatNumber'

export default class Document {

  constructor(props) {
    this.systemId = v4()
    this.key = v4()
    this.id = props.id
    this.providerId = props.providerId || ''
    this.providerName = props.providerName || ''
    this.documentType = props.documentType
    this.documentTypeName = props.documentTypeName
    this.documentTypeGroup = props.documentTypeGroup
    this.externalCode = props.externalCode || ''
    this.externalCodeDv = props.externalCodeDv || ''
    this.date = props.date || moment(Date.now()).format('YYYY/MM/DD')
    this.amount = props.amount || 0
    this.attachment = props.attachment || null
    this.currencyType = props.currency || 'CLP'
    this.documentCurrencyType = props.total_value_money_currency || 'CLP'
    this.provisionDetails = props.provisionDetails && props.provisionDetails.length > 0 ? this.buildProvisionDetails(props.provisionDetails) : []
    this.accountingDocuments = props.accountingDocuments || []
    this.rejectDetails = props.rejectDetails && props.rejectDetails.length > 0 ? this.buildRejects(props.rejectDetails) : []
    this.totalApproved = props.totalApproved || 0
    this.totalRefund = props.totalRefund || 0
    this.comment = props.comment || ''
    this.requiredAudit = props.requiredAudit || false
  }

  // Getters
  get backgroundsRejects () {
    return this.rejectDetails.filter((rejectDetail) => {
      return rejectDetail.code === backgroundsRejectCode
    })
  }

  get isAccountingDocument () {
    return ['bill', 'electronic', 'invoice', 'voucher'].includes(this.documentType)
  }

  get borderClass () {
    let resp = ''
    switch (this.documentTypeGroup) {
      case 'bonus':
        resp = 'document-container__bonus'
        break;
      case 'refund':
        resp = 'document-container__refund'
        break;
      default:
        resp = 'document-container__without_prevision'
    }
    return resp
  }

  get resume () {
    let resp = this.providerName
    if (this.documentTypeName !== '') {
      resp = this.concatResumeText(resp, ' - ', this.documentTypeName)
    }
    if (this.externalCode !== '') {
      resp = this.concatResumeText(resp, ' ', '#' + this.externalCode)
    }
    if (this.externalCodeDv !== '') {
      resp = this.concatResumeText(resp, ' - ', this.externalCodeDv)
    }
    if (this.amount !== '') {
      resp = this.concatResumeText(resp, ' - ', numberToCurrency(this.amount, this.currencyType))
    }
    return resp
  }

  get duplicatedDocumentRejects () {
    return this.rejectDetails.filter((rejectDetail) => {
      return rejectDetail.code === duplicatedDocumentRejectCode && rejectDetail.requirement !== undefined && rejectDetail.requirement !== null
    })
  }

  get lateDocumentRejects ()  {
    return this.rejectDetails.filter((rejectDetail) => {
      return rejectDetail.code === lateDocumentRejectCode
    })
  }

  get totalRequired () {
    let total = 0
    this.provisionDetails.map((provisionDetail) => {
      total = parseFloat(total) + parseFloat(provisionDetail.requiredAmount)
    })
    return total
  }

  get provisionDetailsTotals () {
    return [this.totalRequired, this.totalApproved, this.totalRefund]
  }

  get hasBackgroundsRejects () {
    return this.backgroundsRejects.length > 0
  }

  get hasLateDocumentRejects () {
    return this.lateDocumentRejects.length > 0
  }

  get hasDuplicatedDocumentReject () {
    return this.duplicatedDocumentRejects.length > 0
  }

  get hasProvisionDetails () {
    return this.provisionDetails.length > 0
  }

  get hasAccountingDocuments () {
    return this.accountingDocuments.length > 0
  }

  get canBeSearched () {
    let resp = false,
    providerId = this.providerId,
    externalCode = this.externalCode,
    date = this.date
    if (!isEmpty(providerId) && !isEmpty(externalCode) && !isEmpty(date) && !this.hasCurrentValuesDuplicatedRejects) {
      resp = true
      // Tenemos los datos para buscar el documento.
      let rejectDetails = this.rejectDetails.filter((rejectDetail) => {
        return rejectDetail.code === duplicatedDocumentRejectCode
      })
      if (rejectDetails.length > 0) {
        // Tenemos razon de rechazo para documento duplicado
        // Ahora vamos a ver si corresponde a estos datos
        let duplicatedDocumentReject = rejectDetails[0]
        if (
          duplicatedDocumentReject.providerId === providerId
          && duplicatedDocumentReject.externalCode === externalCode
          && duplicatedDocumentReject.date === date
        ) {
          // Pertenece al mismo rechazo
          resp = false
        }
      }
    }
    return resp
  }

  get canAssociateDocuments () {
    return !this.isAccountingDocument && (this.hasProvisionDetails || this.hasAccountingDocuments)
  }

  get defaultProviderSelectValues () {
    return this.providerId ? {
      label: this.providerName,
      value: this.providerId
    } : null
  }

  get rejectsDetailsAsJson () {
    return this.rejectDetails.map((rejectDetail) => {
      return rejectDetail.asJson
    })
  }

  get provisionDetailsAsJson () {
    return this.provisionDetails.map((provisionDetail) => {
      return {... provisionDetail.asJson, currency: this.currencyType}
    })
  }

  get childDocumentsFrontIds () {
    return this.accountingDocuments.map((accountingDocument) => {
      return accountingDocument.systemId
    })
  }

  get asJson () {
    return {
      id: this.id,
      front_id: this.systemId,
      provider_id: this.providerId,
      doctype: this.documentType,
      currency: this.currencyType,
      issue_date: this.date,
      total_value_money: asFloatNumber(this.amount),
      number: this.externalCode,
      prefix: this.externalCodeDv,
      comment: this.comment,
      attachment_id: this.attachment ? this.attachment.id : null,
      child_document_front_ids: this.childDocumentsFrontIds,
      rejects: this.rejectsDetailsAsJson,
      provision_details: this.provisionDetailsAsJson
    }
  }

  get canBeEvaluated () {
    return !isEmpty(this.providerId) &&
      !isEmpty(this.documentType) &&
      !isEmpty(this.currencyType) &&
      !isEmpty(this.date) &&
      !isEmpty(this.externalCode) &&
      !isEmpty(this.amount)
  }

  get hasCurrentValuesDuplicatedRejects () {
    if (this.hasDuplicatedDocumentReject) {
      return this.duplicatedDocumentRejects.filter((rejectDetail) => {
        return rejectDetail.externalCode === this.externalCode &&
          rejectDetail.date === this.date &&
          rejectDetail.providerId === this.providerId
      }).length > 0
    }
    return false
  }
  // Methods
  transformIdIntoAccountingDocuments = (accountingDocuments) => {
    return this.accountingDocuments.map((systemID) => {
      let filteredAccountingDocuments = accountingDocuments.filter((accountingDocument) => {
        return accountingDocument.systemId === systemID
      })
      return filteredAccountingDocuments.length > 0 ? filteredAccountingDocuments[0] : null
    }).filter((accountingDocument) => {
      return accountingDocument
    })
  }

  getAccountingDocumentsWithDuplicatedDocumentRejects (accountingDocuments) {
    return this.transformIdIntoAccountingDocuments(accountingDocuments).filter((accountingDocument) => {
      return accountingDocument.hasDuplicatedDocumentReject
    })
  }

  buildDocumentRejectDetail = (hash) => {
    return new Reject(hash)
  }

  buildDocumentBackgroundsRejectDetail = (background) => {
    return this.buildDocumentRejectDetail({
      code: backgroundsRejectCode,
      active: true,
      background: background
    })
  }

  buildEmptyDuplicatedDocumentRejectDetail = (justHash) => {
    let hash = {
      providerId: this.providerId,
      externalCode: this.externalCode,
      date: this.date,
      code: duplicatedDocumentRejectCode,
      active: false
    }
    return justHash ? hash : this.buildDocumentRejectDetail(hash)
  }

  buildDuplicatedDocumentRejectDetail = (requirement) => {
    return this.buildDocumentRejectDetail({
      ... this.buildEmptyDuplicatedDocumentRejectDetail(true),
      requirement: requirement,
      active: true
    })
  }

  changeKey = () => {
    this.key = v4()
  }

  concatResumeText = (resumeText, prefix, string) => {
    return resumeText === '' ? string : (resumeText + prefix + string)
  }

  buildProvisionDetails = (provisionDetails) => {
    return provisionDetails.map((provisionDetail) => {
      return new ProvisionDetail(provisionDetail)
    })
  }

  buildRejects = (rejectDetails) => {
    return rejectDetails.map((rejectDetail) => {
      return this.buildDocumentRejectDetail(rejectDetail)
    })
  }

  buildRejectsFromEvaluation = (rejectDetails) => {
    return rejectDetails.map((rejectDetail) => {
      let originalReject = this.rejectDetails.filter((originalRejectDetail) => {
        return originalRejectDetail.systemId === rejectDetail.front_id
      })[0]
      if (originalReject) {
        // Lo encontramos, así que lo modificaremos con los nuevos datos
        return originalReject.buildFromEvaluation(rejectDetail)
      }
      // Si no lo encontramos, quiere decir que es un nuevo rechazo
      return this.buildDocumentRejectDetail(rejectDetail)
    })
  }

  buildFromEvaluation = (evaluatedDocument) => {
    this.id = evaluatedDocument.id
    this.currencyType = evaluatedDocument.total_value_money_currency || this.currencyType
    this.totalApproved = evaluatedDocument.total_approved_money || 0
    this.totalRefund = evaluatedDocument.total_refund_money || 0
    this.comment = evaluatedDocument.comment || ''
    // Ahora debemos modificar los rejectDetails para el documento
    this.rejectDetails = evaluatedDocument.rejects && evaluatedDocument.rejects.length > 0 ? this.buildRejectsFromEvaluation(evaluatedDocument.rejects) : []
    // Una vez modificado el documento, debemos modificar sus provision details
    this.provisionDetails = evaluatedDocument.provision_details
      .filter((evaluatedProvisionDetail) => {
        // 1.- Buscamos el provision detail respectivo dentro de nuestro arreglo original
        let originalProvisionDetail = this.provisionDetails.filter((provisionDetail) => {
          return provisionDetail.systemId === evaluatedProvisionDetail.front_id
        })[0]
        return !!originalProvisionDetail
      })
      .map((evaluatedProvisionDetail) => {
        // 1.- Buscamos el provision detail respectivo dentro de nuestro arreglo original
        let originalProvisionDetail = this.provisionDetails.filter((provisionDetail) => {
          return provisionDetail.systemId === evaluatedProvisionDetail.front_id
        })[0]
        return originalProvisionDetail.buildFromEvaluation(evaluatedProvisionDetail)
      })
    return this
  }

}