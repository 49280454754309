import Highlighter from 'react-highlight-words'
import React from 'react'
import findHighlighterChunks from '../utils/findHighlighterChunks'

const SelectLabelHighlighter = (
  {
    searchWords,
    textToHighlight,
    isRut
  }
) => {

  const findChunks = ({searchWords, textToHighlight}) => {
    return findHighlighterChunks({searchWords, textToHighlight, isRut})
  }

  return (
    <Highlighter
      searchWords={[searchWords]}
      textToHighlight={textToHighlight}
      autoEscape={true}
      highlightTag={'strong'}
      findChunks={findChunks}
    />
  )
}

export default SelectLabelHighlighter