import React  from 'react'
import RejectDetail from './RejectDetail'

const RejectDetails = (
  {
    rejectDetails,
    setRejectDetails
  }
) => {

  const setRejectDetail = (newRejectDetail) => {
    let newRejectDetails = rejectDetails.map((rejectDetail) => {
      if (rejectDetail.systemId === newRejectDetail.systemId) {
        rejectDetail = newRejectDetail
      }
      return rejectDetail
    })
    setRejectDetails(newRejectDetails)
  }

  return (
    <div className={'form-row'}>
      <table className={'table table-bordered table-hover'}>
        <thead>
        <tr className={'bg-secondary text-light'}>
          <th scope={'col'}></th>
          <th scope={'col'}>Código</th>
          <th scope={'col'}>Descripción</th>
          <th scope={'col'}>Comentario</th>
        </tr>
        </thead>
        <tbody>
          {rejectDetails.map((rejectDetail) => (
            <RejectDetail
              {...
                {
                  rejectDetail,
                  setRejectDetail
                }
              }
              key={rejectDetail.key}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default RejectDetails