import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import errorSwalAlert from '../../../../utils/errorSwalAlert'
import Select from 'react-select'

const AuditorsModal = (
  {
    requirement,
    updateRequirement,
    showAuditorsModal,
    setShowAuditorsModal,
    auditRequirementCallback
  }
) => {

  const [selectedAuditors, setSelectedAuditors] = useState(requirement.selectedAuditors)

  const handleAuditoryBtnClick = () => {
    if (selectedAuditors.length === 0) {
      // No puede seguir hasta haber seleccionado al menos 1 auditor
      errorSwalAlert('Seleccione uno o más contralores para terminar')
    }else {
      auditRequirementCallback(selectedAuditors.map((selectedAuditor) => {
        return selectedAuditor.value
      }))
    }
  }

  const handleClose = () => {
    setShowAuditorsModal(false)
  }

  const getOptions = () => {
    return requirement.auditors.map((auditors) => {
      return {
        label: auditors[0],
        options: auditors[1].map((auditor) => {
          return {
            value: auditor.id,
            label: auditor.name
          }
        })
      }
    })
  }

  const handleChange = (selected) => {
    setSelectedAuditors(selected)
  }

  return (
    <>
      <Modal
        show={showAuditorsModal}
        onHide={handleClose}
        className={'accounting-documents-modal'}
      >
        <Modal.Header>
          <Modal.Title>Seleccione los contralores</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Select
            value={selectedAuditors}
            onChange={handleChange}
            options={getOptions()}
            classNamePrefix={'react-select'}
            closeMenuOnSelect={false}
            placeholder={'Seleccione uno o más contralores'}
            isMulti
          />
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={'btn btn-primary'}
            onClick={handleAuditoryBtnClick}
            title={'Envíar a contraloría'}
          >
            Terminar
          </div>
          <div
            className={'btn btn-light'}
            onClick={handleClose}
          >
            Cancelar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AuditorsModal