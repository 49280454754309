import {v4} from 'uuid'

export default class Reject {

  constructor(props) {
    this.systemId = v4()
    this.key = v4()
    this.id = props.id
    this.code = props.code
    this.value = props.value || 0
    this.comment = props.comment
    this.description = props.description
    this.active = props.active
    this.background = props.background
    this.providerId = props.providerId
    this.externalCode = props.externalCode
    this.date = props.date
    this.requirement = props.requirement
  }

  //Getters

  get isBackgroundRejectDetail () {
    return parseInt(this.code) === 19
  }

  get isHospitableRejectDetail () {
    return parseInt(this.code) === 27
  }

  get asJson () {
    return {
      id: this.id,
      front_id: this.systemId,
      code: this.code,
      value: this.value,
      description: this.description,
      active: this.active,
      comment: this.comment
    }
  }

  get isNiRejectDetail () {
    return parseInt(this.code) === 1
  }

  // Methods

  buildFromEvaluation = (evaluatedReject) => {
    this.id = evaluatedReject.id
    this.code = evaluatedReject.code
    this.value = evaluatedReject.value
    this.description = evaluatedReject.description
    this.active = evaluatedReject.active
    this.comment = evaluatedReject.comment
    return this
  }

}