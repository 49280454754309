import React  from 'react'
import ProtectedFile from './ProtectedFile'

const ProtectedFiles = (
  {
    protectedFiles,
    setProtectedFiles
  }
) => {

  const handleProtectedFileRemove = (event) => {
    let protectedFileId = event.currentTarget.dataset.id,
      filteredProtectedFiles = protectedFiles.filter((protectedFile) => {
        return parseInt(protectedFile.id) !== parseInt(protectedFileId)
      })
    setProtectedFiles(filteredProtectedFiles)
  }

  return (
    <>
      {protectedFiles.length > 0 && (
        <>
          {protectedFiles.map((protectedFile) => (
            <ProtectedFile
              protectedFile={protectedFile}
              handleProtectedFileRemove={handleProtectedFileRemove}
              key={protectedFile.id}
            />
          ))}
        </>
      )}
    </>
  )
}

export default ProtectedFiles