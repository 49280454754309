import React from 'react'
import numberToCurrency from '../../../../../../utils/numberToCurrency'

const DocumentFooterAmount = (
  {
    label,
    amount,
    currency,
    className
  }
) => {

  return (
    <div className={'d-flex flex-column' + (amount <= 0 ? ' text-danger' : '') + className}>
      <div>
        {label}
      </div>
      <div>
        {numberToCurrency(parseFloat(amount), currency)}
      </div>
    </div>
  )
}

export default DocumentFooterAmount