import React, {useState} from 'react'
import {faComment, faFileCircleCheck} from '@fortawesome/free-solid-svg-icons'
import DocumentActionButton from '../shared/DocumentActionButton'
import DocumentHeaderCommentsModal from './DocumentHeaderCommentsModal'
import openInNewTab from '../../../../../../utils/openInNewTab'
import DownloadAttachmentButton from '../shared/DownloadAttachmentButton'
import SelectAttachmentModal from '../shared/SelectAttachmentModal'

const DocumentHeaderActionButtons = (
  {
    document,
    setDocument,
    attachments,
    index
  }
) => {

  const [showCommentsModal, setShowCommentsModal] = useState(false)
  const [showSelectAttachmentModal, setShowSelectAttachmentModal] = useState(false)

  const handleCommentButtonClick = () => {
    setShowCommentsModal(true)
  }

  const handleDownloadButtonClick = () => {
    openInNewTab(document.attachment.url)
  }

  const handleSelectAttachmentButtonClick = () => {
    setShowSelectAttachmentModal(true)
  }

  const handleConfirmSelectAttachmentModal = (attachment) => {
    let newDocument = document
    newDocument.attachment = attachment
    setDocument(index, newDocument, false)
    setShowSelectAttachmentModal(false)
  }

  return (
    <>
      <DocumentActionButton
        onClickCallback={handleCommentButtonClick}
        text={'Comentario'}
        icon={faComment}
        className={'text-info flex-column pr-2'}
      />
      <DownloadAttachmentButton
        {...
          {
            document
          }
        }
        withoutText={true}
      />
      <DocumentActionButton
        onClickCallback={handleSelectAttachmentButtonClick}
        text={'Seleccionar adjunto'}
        icon={faFileCircleCheck}
        className={'text-info flex-column flex-grow-0'}
      />
      <DocumentHeaderCommentsModal
        {...
          {
            document,
            setDocument,
            showCommentsModal,
            setShowCommentsModal,
            index
          }
        }
      />
      <SelectAttachmentModal
        {...
          {
            showSelectAttachmentModal,
            setShowSelectAttachmentModal,
            attachments
          }
        }
        handleConfirm={handleConfirmSelectAttachmentModal}
        selectedAttachment={document.attachment ? document.attachment : null}
      />
    </>
  )
}

export default DocumentHeaderActionButtons