import buildTotalRejectFormData from './buildTotalRejectFormData'
import buildSimpleRejectFormData from './buildSimpleRejectFormData'

const buildHeader = (
  requirement,
  action,
  reject_type,
  initialProtectedFiles,
  protectedFiles,
  requirementId,
  insurerId,
  requirementType,
  beginningTime,
  shipmentId,
  shipmentDate,
  externalCode,
  liquidator,
  oldReferenceId,
  policyId,
  insureeId,
  comments,
  initialBackgrounds,
  removedBackgrounds,
  precedingRejectReasonId,
  backgrounds,
  initialHospitableRejectDetails,
  hospitableRequirementRejectReasonId,
  initialNIRejectDetails,
  nIRejectReasonId,
  insuranceType
) => {
  let formData = new FormData(),
    allProtectedFiles = [...initialProtectedFiles, ...protectedFiles],
    rejectDetailsCount = 0
  formData.append('requirement_id', requirementId)
  formData.append('insurer_id', insurerId)
  formData.append('requirement_type', requirementType)
  formData.append('save-action', action)
  formData.append('user[beginning_time]', beginningTime)
  formData.append('requirement[shipment_id]', shipmentId)
  formData.append('shipment[date]', shipmentDate)
  formData.append('requirement[external_code]', externalCode)
  formData.append('requirement[insurance_type]', insuranceType ? insuranceType : 'dental')
  formData.append('requirement[external_liquidator_id]', liquidator ? liquidator.id : null)
  formData.append('requirement[old_reference_id]', oldReferenceId)
  formData.append('requirement[policy_id]', policyId !== '' ? policyId : null)
  formData.append('requirement[insuree_id]', insureeId !== '' ? insureeId : null)
  formData.append('requirement[comments]', comments)
  allProtectedFiles.forEach((protectedFile, index) => {
    formData.append('requirement[protected_files_attributes][' + index + '][_destroy]', 'false')
    formData.append('requirement[protected_files_attributes][' + index + '][id]', protectedFile.id)
  })
  rejectDetailsCount = buildTotalRejectFormData(requirement, formData, action, reject_type, initialBackgrounds, removedBackgrounds, rejectDetailsCount, precedingRejectReasonId, backgrounds, comments)
  rejectDetailsCount = buildSimpleRejectFormData(formData, action, reject_type, initialHospitableRejectDetails, rejectDetailsCount, hospitableRequirementRejectReasonId, 'hospitable', comments, requirement.activeHospitableReject)
  buildSimpleRejectFormData(formData, action, reject_type, initialNIRejectDetails, rejectDetailsCount, nIRejectReasonId, 'no_incorporated', comments, requirement.activeNiReject)
  return formData
}

export default buildHeader