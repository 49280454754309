import Swal from 'sweetalert2'

const basicSwalAlert = (title, message, type) => {
  Swal.fire(
    title,
    message,
    type
  )
}

export default basicSwalAlert