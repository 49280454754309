import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import {provisionNotPerformedRejectCode} from "../../../../../../utils/constants";
import Reject from "../../../../../../models/Reject";

const NotPerformedRejectModal = (
  {
    provisionDetail,
    setProvisionDetail,
    index,
    showNotPerformedRejectModal,
    setShowNotPerformedRejectModal,
    handleConfirm
  }
) => {

  const [rejectDetails, setRejectDetails] = useState(provisionDetail.rejectDetails)

  const handleClose = () => {
    setShowNotPerformedRejectModal(false)
  }

  const handleConfirmBtnClick = () => {
    // Creamos el rechazo una vez que el usuario llo aceptó
    createNotPerformedReject()

    // Cerramos el modal
    handleConfirm()
  }

  const createNotPerformedReject = () => {
    // Código para crear el rechazo
    if (provisionDetail.rejectDetails.filter((rejectDetail) => rejectDetail.code === provisionNotPerformedRejectCode).length === 0) {
      let newProvisionDetail = provisionDetail,
      rejectDetails = provisionDetail.rejectDetails,
      newRejectDetail = new Reject({
        code: provisionNotPerformedRejectCode,
        value: provisionDetail.totalAmount,
        comment: '',
        description: 'La compañía de seguros no reembolsa tratamientos no efectuados. Una vez realizado reingrese gasto adjuntando certificado emitido por odontologo tratante indicando fecha de cada prestación realizada.',
        active: true,
        providerId: ''
      })
      newProvisionDetail.rejectDetails = [... rejectDetails.filter((rejectDetail) => rejectDetail.code !== provisionNotPerformedRejectCode), newRejectDetail]
      setProvisionDetail(index, newProvisionDetail)
      setRejectDetails(newProvisionDetail.rejectDetails)
    }
  }

  return (
    <>
      <Modal
        show={showNotPerformedRejectModal}
        onHide={handleClose}
        dialogClassName={'mw-100 w-50 px-3'}
        className={'provision-not-performed-reject-modal'}
      >
        <Modal.Header>
          <Modal.Title>Rechazos</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {rejectDetails.length > 0 ? (
              <>
                {rejectDetails.map((rejectDetail) => {
                  if (rejectDetail.code === 26) {
                    return (
                      <Alert variant="info">
                        <Alert.Heading>Atención</Alert.Heading>
                        <p>Ya existe un rechazo por prestación no realizada para esta línea.</p>
                        {rejectDetail.active ? '' : <p>Puedes activarlo en la sección <em>Rechazos</em></p>}
                      </Alert>
                    )
                  }
                })}
              </>
          ) : (
              <Alert variant="warning">
                <Alert.Heading>Atención</Alert.Heading>
                <p>¿Desea rechazar esta línea por ser un tratamiento no realizado?</p>
              </Alert>
          )}
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={'btn btn-primary'}
            onClick={handleConfirmBtnClick}
          >
            Aceptar
          </div>
          <div
            className={'btn btn-light'}
            onClick={handleClose}
          >
            Cerrar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NotPerformedRejectModal