import addSimpleRejectReason from './addSimpleRejectReason'

const buildTotalRejectFormData = (requirement, formData, action, reject_type, initialBackgrounds, removedBackgrounds, rejectDetailsCount, precedingRejectReasonId, backgrounds, comments) => {
  let rejectDetailsIndex = -1
  if (initialBackgrounds.length > 0) {
    let initialRejectDetailsAttributeId = null
    initialBackgrounds.forEach((background, index) => {
      let backgroundId = background.id
      if (!removedBackgrounds.includes(backgroundId) || action === 'save') {
        let rejectDetailId = background.reject_detail_id
        if (rejectDetailId !== initialRejectDetailsAttributeId) {
          initialRejectDetailsAttributeId = rejectDetailId
          rejectDetailsIndex = rejectDetailsIndex + 1
          rejectDetailsCount = addSimpleRejectReason(rejectDetailsIndex, rejectDetailId, formData, comments, precedingRejectReasonId, rejectDetailsCount, false, requirement.activeBackgroundReject)
        }
        formData.append('requirement[reject_details_attributes][' + rejectDetailsIndex + '][preceding_details_attributes][' + index + '][_destroy]', removedBackgrounds.includes(backgroundId) && action === 'save' ? 'true' : 'false')
        formData.append('requirement[reject_details_attributes][' + rejectDetailsIndex + '][preceding_details_attributes][' + index + '][id]', backgroundId)
        formData.append('requirement[reject_details_attributes][' + rejectDetailsIndex + '][preceding_details_attributes][' + index + '][preceding_id]', background.preceding_id)
        formData.append('requirement[reject_details_attributes][' + rejectDetailsIndex + '][preceding_details_attributes][' + index + '][details]', background.details)
      }
    })
  }
  if (backgrounds.length > 0 && action === 'reject' && reject_type === 'backgrounds') {
    rejectDetailsIndex = initialBackgrounds.length
    rejectDetailsCount = addSimpleRejectReason(rejectDetailsIndex, null, formData, comments, precedingRejectReasonId, rejectDetailsCount, false, requirement.activeBackgroundReject)
    backgrounds.forEach((background, index) => {
      formData.append('requirement[reject_details_attributes][' + rejectDetailsIndex +'][preceding_details_attributes][' + index + '][_destroy]', 'false')
      formData.append('requirement[reject_details_attributes][' + rejectDetailsIndex +'][preceding_details_attributes][' + index + '][preceding_id]', background.preceding_id)
      formData.append('requirement[reject_details_attributes][' + rejectDetailsIndex +'][preceding_details_attributes][' + index + '][details]', background.details)
    })
  }
  return rejectDetailsCount
}

export default buildTotalRejectFormData