import React from 'react'

const Header = (props) => {
  const insuranceType = props.insuranceType;

  return (
    <div className='conditioned-header'>
      <div className='providers-section'>Prestador</div>
      <div className='provisions-section'>Prestaciones</div>
      {insuranceType == "health" &&
        <div className='evaluation-type-section'>Tipo de evaluación</div>
      }
      {insuranceType == "health" &&
        <div className='coverage-section'>
          <div className='coverage-title'>% Cobertura reembolso</div>
          <div className='coverage-body'>
            <div className='coverage-label'>Bono</div>
            <div className='coverage-label'>Reem</div>
            <div className='coverage-label'>S / Prev</div>
          </div>
        </div>
      }
      {insuranceType == "dental" &&
        <div className='dental-coverage-section'>
          <div className='coverage-title'>% Cobertura</div>
        </div>
      }

      {insuranceType == "health" &&
        <div className='bmi-section'>% BMI</div>
      }
      {insuranceType == "health" &&
        <div className='fcp-section'>% Castigo S / Prev</div>
      }
      <div className='arancel-section'>% Tope arancel</div>
      <div className='limit-section'>Tope prestación</div>
      <div className='maximum-section'>Máximo anual</div>
      <div className='age-range-section'>Rango de edad</div>
    </div>
  )
}

export default Header;