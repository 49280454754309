import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

const CustomLogsTable = ({
    data,
    columns
}) => {
    const { SearchBar } = Search;
    const columnsBudgetLogs = [{
        dataField: 'folio',
        text: 'Folio I./E.',
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        }
      }, {
        dataField: 'lender',
        text: 'Prestador',
        sort: true
      }, {
        dataField: 'n_doc',
        text: 'Nº Doc.',
        sort: true,
        headerStyle: () => {
          return { width: "5%" };
        }
      }, {
        dataField: 'service',
        text: 'Prestación',
        sort: true
      }, {
        dataField: 'description',
        text: 'Descripción',
        headerStyle: () => {
          return { width: "10%" };
        }
      }, {
        dataField: 'piece',
        text: 'Pieza' ,
        headerStyle: () => {
          return { width: "5%" };
        }
      }, {
        dataField: 'date',
        text: 'Fecha',
        sort: true
      }, {
        dataField: 'request',
        text: 'Solicitud',
        sort: true,
        headerStyle: () => {
          return { width: "8%" };
        }
      }, {
        dataField: 'approval',
        text: 'Aprobación',
        sort: true
      }, {
        dataField: 'balance',
        text: 'Saldo',
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        }
      }, {
        dataField: 'comments',
        text: 'Comentarios'
      }
      ];
    const columnsExpenseLogs = [{
      dataField: 'folio',
      text: 'Folio I./E.',
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      }
    }, {
      dataField: 'lender',
      text: 'Prestador',
      sort: true
    }, {
      dataField: 'doc',
      text: 'Doc.' ,
      headerStyle: () => {
        return { width: "6%" };
      }
    },{
      dataField: 'n_doc',
      text: 'Nº Doc.' ,
      sort: true,
      headerStyle: () => {
        return { width: "6%" };
      }
    }, {
      dataField: 'service',
      text: 'Prestación',
      sort: true
    }, {
      dataField: 'description',
      text: 'Descripción'
    }, {
      dataField: 'piece',
      text: 'Pieza',
      headerStyle: () => {
        return { width: "5%" };
      }
    }, {
      dataField: 'date',
      text: 'Fecha',
      sort: true,
      headerStyle: () => {
        return { width: "9%" };
      }
    }, {
      dataField: 'request',
      text: 'Solicitud',
      sort: true,
      headerStyle: () => {
        return { width: "8%" };
      }
    }, {
      dataField: 'approval',
      text: 'Aprobación',
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      }
    }, {
      dataField: 'comments',
      text: 'Comentarios',
      headerStyle: () => {
        return { width: "9%" };
      }
    }
    ];
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total mt-2">
        Mostrando registro del { from } al { to } de un total de { size } registros
      </span>
    );
    const options = paginationFactory({
      sizePerPage: 10,
      hideSizePerPage: true,
      withFirstAndLast: true,
      showTotal: true,
      paginationTotalRenderer: customTotal
    });

    const getBudgetData = () => {
      return data.map((data) => {
        return {
          folio: <div dangerouslySetInnerHTML={{__html: data[0]}} />,
          lender: data[1],
          n_doc: data[2],
          service: data[3],
          description: data[4],
          piece: data[5],
          date: data[6],
          request: data[7],
          approval: data[8],
          balance: data[9],
          comments: data[10]
        }
      })
    }

    const getExpenseData = () => {
      return data.map((data) => {
        return {
          folio: <div dangerouslySetInnerHTML={{__html: data[0]}} />,
          lender: data[1],
          doc: data[2],
          n_doc: data[3],
          service: data[4],
          description: data[5],
          piece: data[6],
          date: data[7],
          request: data[8],
          approval: data[9],
          comments: data[10]
        }
      })
    }

  return (
    <>
    <ToolkitProvider
      bootstrap4
      data={ columns == 'budget' ? getBudgetData() : getExpenseData() } 
      columns={ columns == 'budget' ? columnsBudgetLogs : columnsExpenseLogs }
      search
    >
      {
        props => (
          <div>
            <div className="row justify-content-end">
              <div className="col-2">
                <SearchBar
                { ...props.searchProps }
                className="custome-search-field"
                style={ { color: 'black' } }
                delay={ 1000 }
                placeholder="Buscar"
              />
              </div>
            </div>
            <BootstrapTable
              { ...props.baseProps }
              pagination={ options } 
              striped
              keyField='Folio'
              condensed
              bordered={ false } 
            />
          </div>
        )
      }
    </ToolkitProvider>
    </>
  )
}

export default CustomLogsTable