const findHighlighterChunks = ({searchWords, textToHighlight, isRut}) => {
  let chunks = [],
    textLow = textToHighlight.toLowerCase(),
    singleTextWords = textLow.split(''),
    fromIndex = 0,
    singleTextWordsWithPos = singleTextWords.map(s => {
      let indexInWord = textLow.indexOf(s, fromIndex)
      fromIndex = indexInWord
      return {
        word: s,
        index: indexInWord
      }
    })
  searchWords.forEach(sw => {
    let swLow = sw.toLowerCase()
    if (isRut) {
      let replacedSearchWord = sw.replace(/[^0-9kK]/g, ''),
        maxToHighlight = replacedSearchWord.length
      singleTextWordsWithPos.forEach(s => {
        if (maxToHighlight > 0 && replacedSearchWord.includes(s.word)) {
          const start = s.index,
            end = s.index + 1
          chunks.push({
            start,
            end
          })
          maxToHighlight = maxToHighlight - 1
        }
      })
    }else {
      if (textLow.includes(swLow)) {
        let start = textLow.indexOf(swLow),
          end = start + swLow.length
        chunks.push({
          start,
          end
        })
      }
    }
  })
  return chunks
}

export default findHighlighterChunks