import {
  faFileExcel,
  faFilePowerpoint,
  faFileWord,
  faFileArchive,
  faFilePdf,
  faFileImage,
  faFileVideo,
  faFileAudio,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons'

const getIconByContentType = (contentType) => {
  let icon = faEyeSlash
  switch (contentType) {
    case 'text/csv':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      icon = faFileExcel
      break;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      icon = faFilePowerpoint
      break;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      icon = faFileWord
      break;
    case 'application/zip':
    case 'application/vnd.rar':
      icon = faFileArchive
      break;
    case 'application/pdf':
      icon = faFilePdf
      break;
    default:
      if (contentType.includes('image')) {
        icon = faFileImage
      }
      if (contentType.includes('video')) {
        icon = faFileVideo
      }
      if (contentType.includes('audio')) {
        icon = faFileAudio
      }
  }
  return icon
}

export default getIconByContentType