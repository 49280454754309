import React from 'react'
import FormActionButton from '../../../../shared/FormActionButton'
import {faFileCirclePlus} from '@fortawesome/free-solid-svg-icons'
import Document from '../../../../models/Document'

const HealthFormActionButtons = (
  {
    documents,
    setDocuments,
    documentTypeOptions
  }
) => {

  const addDocument = (documentTypeGroup) => {
    let typeOptions = 0
    switch (documentTypeGroup) {
      case 'bonus':
        typeOptions = 0
        break;
      case 'refund':
        typeOptions = 1
        break;
      case 'without_prevision':
      case 'accounting_document':
        typeOptions = 2
        break;
    }
    setDocuments([...documents, new Document({
      documentType: documentTypeOptions[typeOptions].options[0].value,
      documentTypeName: documentTypeOptions[typeOptions].options[0].label,
      documentTypeGroup: documentTypeGroup
    })])
  }

  const handleAddBonusDocumentBtnClick = () => {
    addDocument('bonus')
  }

  const handleAddRefundDocumentBtnClick = () => {
    addDocument('refund')
  }

  const handleAddWithoutPrevisionBtnClick = () => {
    addDocument('without_prevision')
  }

  return (
    <>
      <div className={'form-action-buttons'}>
        { window.insurerIsPrimaryInsurance ? (
          <>
            <FormActionButton
              title={'Agregar documento'}
              className={'btn--without_prevision'}
              onClick={handleAddWithoutPrevisionBtnClick}
              content={'Agregar documento'}
              icon={faFileCirclePlus}
            />
          </>
        ) : (
          <>
            <FormActionButton
              title={'Agregar Bono'}
              className={'btn--bonus'}
              onClick={handleAddBonusDocumentBtnClick}
              content={'Agregar Bono'}
              icon={faFileCirclePlus}
            />
            <FormActionButton
              title={'Agregar reembolso'}
              className={'btn--refund'}
              onClick={handleAddRefundDocumentBtnClick}
              content={'Agregar reembolso'}
              icon={faFileCirclePlus}
            />
            <FormActionButton
              title={'Agregar líneas sin previsión'}
              className={'btn--without_prevision'}
              onClick={handleAddWithoutPrevisionBtnClick}
              content={'Agregar líneas sin previsión'}
              icon={faFileCirclePlus}
            />
          </>
        )}
      </div>
    </>
  )
}

export default HealthFormActionButtons