import React, {useEffect, useState} from 'react'
import DocumentHeader from './header'
import DocumentBody from './body'
import DocumentAccountingDocuments from '././accounting_documents'
import DocumentFooter from './footer'
import StepContainer from '../../../../../shared/StepContainer'
import showLoading from '../../../../../utils/showLoading'
import axios from 'axios'
import {
  documentUrl,
  policyInstanceUrl
} from '../../../../../routes/search'
import closeAlert from '../../../../../utils/closeAlert'
import notFoundRespData from '../../../../../utils/notFoundRespData'
import {duplicatedDocumentRejectCode} from '../../../../../utils/constants'
import ProvisionDetail from '../../../../../models/ProvisionDetail'
import AccountingDocumentsModal from '././accounting_documents/AccountingDocumentsModal'
import moment from 'moment/moment'

const DocumentContainer = (
  {
    document,
    setDocument,
    removeDocument,
    index,
    documentTypeOptions,
    attachments,
    formAlerts,
    setFormAlerts,
    backgroundOptions,
    getPossibleFiles,
    isAccountingDocument,
    containerClassName,
    prescriptions,
    setPrescriptions,
    documents,
    setDocuments,
    isModal,
    healthProvisionTypes,
    policyId,
    insuree,
    policy,
    countryIso,
    triggerPolicyInstanceProvisionAlert,
    insurerProblemProviders,
    requirementType,
    toothOptions,
    sideOptions,
    isEditable,
    isFullInsurer
  }
) => {

  const insurerId = window.insurerId
  const requirementId = window.requirementId

  const [showAccountingDocumentsModal, setShowAccountingDocumentsModal] = useState(false)
  const [policyInstance, setPolicyInstance] = useState(null)
  const [directProblemProviderReject, setDirectProblemProviderReject] = useState(false)

  const handleAddAccountingDocumentButtonClick = () => {
    setShowAccountingDocumentsModal(true)
  }

  const removeDuplicatedDocumentReject = (withEmptyReject, doc) => {
    let newDocument = doc,
      rejectDetails = doc.rejectDetails
    // Tenemos razones de rechazo para el documento
    if (withEmptyReject) {
      newDocument.rejectDetails = [... rejectDetails.filter((rejectDetail) => rejectDetail.code !== duplicatedDocumentRejectCode), doc.buildEmptyDuplicatedDocumentRejectDetail(false)]
      setDocument(index, newDocument, true, newDocument.canBeEvaluated, 100)
    }else {
      newDocument.rejectDetails = rejectDetails.filter((rejectDetail) => rejectDetail.code !== duplicatedDocumentRejectCode)
    }
    return newDocument
  }

  const setDuplicatedDocumentReject = (requirement, doc) => {
    let newDocument = removeDuplicatedDocumentReject(false, doc),
      rejectDetails = newDocument.rejectDetails
    newDocument.rejectDetails = [... rejectDetails, document.buildDuplicatedDocumentRejectDetail(requirement)]
    setDocument(index, newDocument, false, true, 100)
  }

  const checkDuplicatedDocument = async (doc) => {
    let formData = new FormData()
    formData.append('provider_id', doc.providerId)
    formData.append('document_number', doc.externalCode)
    formData.append('issue_date', doc.date)
    formData.append('insurer_id', insurerId)
    formData.append('requirement_id', requirementId)
    showLoading()
    await axios({
      url: documentUrl,
      method: 'POST',
      data: formData
    })
    .then ((res) => {
      let data = res.data
      if (notFoundRespData(data)) {
        removeDuplicatedDocumentReject(true, doc)
      }else {
        setDuplicatedDocumentReject(data, doc)
      }
    })
    .catch(err =>{
      removeDuplicatedDocumentReject(true, doc)
    })
    .finally(() => {
      closeAlert()
    })
  }

  const findPolicyInstance = async (doc) => {
    let formData = new FormData()
    formData.append('policy_id', policyId)
    formData.append('date', moment(doc.date, 'YYYY-MM-DD').format('DD/MM/YYYY'))
    formData.append('insurer_id', insurerId)
    formData.append('insuree_id', insuree.value)
    formData.append('expense_id', requirementId)
    formData.append('is_budget', 'false')
    showLoading()
    await axios({
      url: policyInstanceUrl,
      method: 'POST',
      data: formData
    })
    .then ((res) => {
      let data = res.data,
        newPolicyInstance = data.policy_instance
      if (data.success) {
        newPolicyInstance = JSON.parse(newPolicyInstance)
        newPolicyInstance['date'] = doc.date
        setPolicyInstance(newPolicyInstance)
      }else {
        setPolicyInstance(null)
      }
    })
    .catch(err =>{
      setPolicyInstance(null)
    })
    .finally(() => {
      closeAlert()
    })
  }

  const handleAddLineClick = () => {
    let newDocument = document,
      provisionDetails = newDocument.provisionDetails
    newDocument.provisionDetails = [... provisionDetails, new ProvisionDetail({})]
    setDocument(index, newDocument)
  }

  const handleOnConfirmClick = (accountingDocument) => {
    if (accountingDocument !== '') {
      let newAccountingDocuments = documents,
        filteredAccountingDocument = newAccountingDocuments.filter((ad) => { return ad.systemId === accountingDocument.systemId })
      if (filteredAccountingDocument.length === 0) {
        newAccountingDocuments = [... newAccountingDocuments, accountingDocument]
      }else {
        newAccountingDocuments = [... newAccountingDocuments.filter((ad) => { return ad.systemId !== accountingDocument.systemId }), accountingDocument]
      }
      setDocuments(newAccountingDocuments)
      let newDocument = document,
        associatedDocuments = newDocument.accountingDocuments
      if (!associatedDocuments.includes(accountingDocument.systemId)) {
        newDocument.accountingDocuments = [... associatedDocuments, accountingDocument.systemId]
        setDocument(index, newDocument, true, true, 100)
      }
    }
    setShowAccountingDocumentsModal(false)
  }

  const handleOnCloseClick = () => {
    setShowAccountingDocumentsModal(false)
  }

  const checkProblemProviders = (policyInstance, providerId) => {
    let alertMessage = '',
      alertType = '',
      problemProvider = null
    // si no tengo poliza o prestador, no tengo razon para rechazar
    if (policyInstance && providerId) {
      providerId = parseInt(providerId)
      let policyProblemProviders = policyInstance.policy ? policyInstance.policy.problem_providers : null
      if (policyProblemProviders) {
        problemProvider = policyProblemProviders.filter((pp) => pp.provider_id === providerId)[0]
      }
      if (!problemProvider && !policyInstance.ignore_insurer_problem_providers) {
        // buscaremos en los problem providers de la aseguradora
        if (insurerProblemProviders) {
          problemProvider = insurerProblemProviders.filter((pp) => pp.provider_id === providerId)
        }
      }
      // si en este punto tenemos problem_provider, ... tendremos que manejarlo
      if (problemProvider) {
        let requiredAudit = false
        switch (problemProvider.konstraint) {
          case 1:  //rechazo directo
            setDirectProblemProviderReject(true)
            break;
          case 2:  //contraloria obligatoria
            //Si es vip, se salva...
            let vip = policyInstance.vip
            if (!vip) {
              //veamos entonces si el paciente es vip...
              vip = insuree && insuree.full_vip
            }
            if (vip) {
              //igual alertamos
              alertMessage = "Contralor\u00eda obligatoria para prestador. Ignorando por VIP"
              alertType = 'info'
            }else {
              requiredAudit = true
            }
            break;
          case 3:  //alerta de contraloria
            alertMessage = "Alerta de contralor\u00eda para prestador"
            alertType = 'warning'
            break;
          case 4:  //alerta de atencion
            alertMessage = "Alerta de atenci\u00f3n para prestador"
            alertType = 'warning'
            break;
        }
        if (requiredAudit) {
          alertMessage = "Contralor\u00eda obligatoria para prestador"
          alertType = 'warning'
        }
        let newDocument = document
        newDocument.requiredAudit = requiredAudit
        setDocument(index, newDocument, false)
      }
    }
    if (problemProvider && alertMessage !== '') {
      triggerPolicyInstanceProvisionAlert(policyInstance.id, providerId, alertMessage, alertType)
    }
  }

  useEffect(() => {
    if (document.attachment) {
      if (attachments.filter((attachment) => attachment.id === document.attachment.id).length === 0) {
        let newDocument = document
        newDocument.attachment = null
        setDocument(index, newDocument)
      }
    }
  }, [attachments, document])

  useEffect(() => {
    if (document.accountingDocuments.length > 0) {
      let accountingDocuments = documents.filter((document) => {
          return document.isAccountingDocument
        }),
        prevAccountingDocumentsCount = document.accountingDocuments.length,
        filteredAccountingDocuments = document.accountingDocuments.filter((accountingDocument) => {
          return accountingDocuments.filter((document) => {
            return document.systemId === accountingDocument
          }).length > 0
        }),
        filteredAccountingDocumentsCount = filteredAccountingDocuments.length
      if (filteredAccountingDocumentsCount < prevAccountingDocumentsCount) {
        document.accountingDocuments = filteredAccountingDocuments
        setDocument(index, document, true, true, 100)
      }
    }
  }, [document, documents])

  useEffect(() => {
    let {date} = document
    if (policyId !== null && policyId !== '-1' && insuree && date) {
      let insureeId = insuree.value
      if (policyInstance !== null) {
        let policyInstancePolicyId = policyInstance.policy_id,
          policyInstanceInsureeId = policyInstance.insuree_id,
          policyInstanceDate = policyInstance.date
        if ((parseInt(policyId) !== parseInt(policyInstancePolicyId)) ||
          (parseInt(insureeId) !== parseInt(policyInstanceInsureeId)) ||
          (date !== policyInstanceDate)
        ) {
          // Datos no son iguales
          // Debemos buscar policyInstance
          findPolicyInstance(document)
        }
      }else {
        // No tenemos policyInstance, pero si los datos suficientes para buscar una
        // Debemos buscar policyInstance
        findPolicyInstance(document)
      }
    }else {
      // No podemos buscar una policyInstance, nos falta información
      setPolicyInstance(null)
    }
  }, [document.date, insuree, policyId])

  useEffect(() => {
    let {providerId} = document
    if (providerId && policyInstance) {
      checkProblemProviders(policyInstance, providerId)
    }
  }, [policyInstance, document.providerId])

  return (
    <div className={containerClassName || 'col-12 pb-4'}>
      <div className={'document-container ' + document.borderClass}>
        <StepContainer
          containerClassName={''}
          cardHeader={
            <DocumentHeader
              {...
                {
                  document,
                  setDocument,
                  removeDocument,
                  index,
                  documentTypeOptions,
                  attachments,
                  documents,
                  isModal,
                  checkDuplicatedDocument,
                  requirementType,
                  countryIso,
                  isEditable
                }
              }
            />
          }
          cardFooter={
            <DocumentFooter
              {...
                {
                  document,
                  setDocument,
                  index,
                  formAlerts,
                  setFormAlerts,
                  backgroundOptions,
                  isAccountingDocument,
                  documents,
                  setDocuments,
                  isEditable
                }
              }
              handleActionButtonClick={isAccountingDocument ? removeDocument : handleAddLineClick}
            />
          }
          containerName={'document_'+index}
          showContent={document.hasProvisionDetails || document.canAssociateDocuments}
        >
          <>
            {document.hasProvisionDetails && (
              <DocumentBody
                {...
                  {
                    document,
                    setDocument,
                    index,
                    attachments,
                    handleAddLineClick,
                    prescriptions,
                    setPrescriptions,
                    healthProvisionTypes,
                    policyInstance,
                    directProblemProviderReject,
                    policyId,
                    insuree,
                    policy, 
                    requirementType,
                    toothOptions,
                    sideOptions,
                    isEditable,
                    isFullInsurer
                  }
                }
              />
            )}
            {document.canAssociateDocuments && (
              <div className={'row accounting-documents'}>
                <DocumentAccountingDocuments
                  {...
                    {
                      document,
                      setDocument,
                      index,
                      getPossibleFiles,
                      handleAddAccountingDocumentButtonClick,
                      documents,
                      setDocuments
                    }
                  }
                />
                <AccountingDocumentsModal
                  {...
                    {
                      showAccountingDocumentsModal,
                      documents,
                      handleOnConfirmClick,
                      handleOnCloseClick,
                      documentTypeOptions,
                      setDocuments,
                      getPossibleFiles,
                      backgroundOptions,
                      prescriptions,
                      setPrescriptions,
                      healthProvisionTypes,
                      policyId,
                      insuree,
                      triggerPolicyInstanceProvisionAlert,
                      insurerProblemProviders
                    }
                  }
                />
              </div>
            )}
          </>
        </StepContainer>
      </div>
    </div>
  )
}

export default DocumentContainer