import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Comments from '../../../../../../shared/Comments'

const ProvisionDetailCommentsModal = (
  {
    provisionDetail,
    setProvisionDetail,
    index,
    showProvisionDetailCommentsModal,
    setShowProvisionDetailCommentsModal
  }
) => {

  const [comments, setComments] = useState(provisionDetail.comments)
  const handleClose = () => {
    let newProvisionDetail = provisionDetail
    newProvisionDetail.comments = comments
    setProvisionDetail(index, newProvisionDetail)
    setShowProvisionDetailCommentsModal(false)
  }

  return (
    <>
      <Modal show={showProvisionDetailCommentsModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Nombre de la prestación</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Ingrese el nombre exacto de la prestación para esta linea</p>
          <Comments
            {...
              {
                comments,
                setComments
              }
            }
            placeholder={'Nombre de la prestación'}
          />
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={'btn btn-light'}
            onClick={handleClose}
          >
            Cerrar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ProvisionDetailCommentsModal