import React from 'react'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const RejectDetail = (
  {
    rejectDetail,
    setRejectDetail
  }
) => {

  const handleToggleActiveButtonClick = (checked) => {
    let newRejectDetail = rejectDetail
    newRejectDetail.active = checked
    setRejectDetail(newRejectDetail)
  }

  return (
    <tr key={rejectDetail.systemId}>
      <td scope={'col'} className={'d-flex justify-content-center'}>
        <BootstrapSwitchButton
          onlabel={'Activo'}
          offlabel={'Inactivo'}
          checked={rejectDetail.active}
          onstyle={'danger'}
          offstyle={'secondary'}
          width={150}
          onChange={(checked) => {
            handleToggleActiveButtonClick(checked)
          }}
        />
      </td>
      <td scope={'col'}>
        {rejectDetail.code}
      </td>
      <td scope={'col'}>
        {rejectDetail.description}
      </td>
      <td scope={'col'}>
        {rejectDetail.comment}
      </td>
    </tr>
  )
}

export default RejectDetail