import React, { useState } from 'react'
import IconButton from "@material-ui/core/IconButton"
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const Quota = (props) => {
  const [quotaType, setQuotaType] = useState(props.quota_type ? props.quota_type : 1)
  const [valueAmount, setValueAmount] = useState(props.value_amount ? props.value_amount : null)

  const handleRemoveQuota = (event) => {
    props.handleRemoveQuota(event, props.index_)
  }

  var quotaObject = {
    quota_type: quotaType,
    value_amount: valueAmount,
    index_: props.index_
  }

  const handleQuotaType = (value) => {
    setQuotaType(value);
    const newObject = {
      ...quotaObject,
      quota_type: value
    }
    props.handleChangeQuota(props.index_, newObject)
  }

  const handleValueCents = (value) => {
    setValueAmount(value);
    const newObject = {
      ...quotaObject,
      value_amount: value
    }
    props.handleChangeQuota(props.index_, newObject)
  }

  return (
    <div className='quota-form'>
      <IconButton className='quota-delete' onClick={(e) => handleRemoveQuota(e)}>
        <DeleteForeverIcon className='clear-icon'/>
      </IconButton>
      <label className='quota-label'>Cupo</label>
      <select className='form-control quota-selector' value={quotaType} onChange={(e) => handleQuotaType(e.target.value)}>
        <option value={1} selected={quotaType == 1 ? true : false}>Familiar</option>
        <option value={2} selected={quotaType == 2 ? true : false}>Individual</option>
      </select>
      <div className="input-group quota-input ">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon1">{props.symbol}</span>
        </div>
        <input className='form-control' type="number" placeholder={"##.###"} value={valueAmount} onChange={e => handleValueCents(e.target.value)} />
      </div>
    </div>
  )
}

export default Quota;