import React from 'react'

const Input = React.forwardRef(({name, type, value, className, disabled, ...rest}, ref) => {
  return (
    <input
      ref={ref}
      name={name}
      type={type}
      value={value || ''}
      onWheel={(e) => type == "number" ? e.target.blur() : null}
      className={'form-control ' + (className !== undefined ? className : '')}
      disabled={disabled || false}
      {...rest}
    />
  )
})

export default Input