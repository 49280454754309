import React  from 'react'
import InitialFile from './InitialFile'

const InitialFiles = (
  {
    initialProtectedFiles
  }
) => {
  return (
    <>
      {initialProtectedFiles.length > 0 && (
        <>
          {initialProtectedFiles.map((initialFile) => (
            <InitialFile
              initialFile={initialFile}
              key={initialFile.id}
            />
          ))}
        </>
      )}
    </>
  )
}

export default InitialFiles