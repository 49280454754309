import React, {useEffect, useState} from 'react'
import getInputErrorClass from '../../../../../utils/getInputErrorClass'
import getInputErrorMessage from '../../../../../utils/getInputErrorMessage'
import FormAlert from '../shared/FormAlert'
import Select from '../../../../../shared/Select'
import Label from '../../../../../shared/Label'
import getInsureeInfoByBackendData from '../../../../../utils/getInsureeInfoByBackendData'

const Insurees = (
  {
    policies,
    policyId,
    insurees,
    insureeId,
    setInsureeId,
    setInsurees,
    formAlerts,
    setShowRejectButton,
    editable,
    setEditable,
    firstRun,
    setInsuree,
    isFullInsurer
  }
) => {

  const getInsuree = (array, value) => {
    let filtered_array = array.filter((insuree) => parseInt(insuree.value) === parseInt(value)),
      insuree = null
    if (value && value !== '-1' && filtered_array.length > 0) {
      insuree = filtered_array[0]
    }
    return insuree
  }

  const getInsureeName = (insuree) => {
    let name = ''
    if (insuree) {
      if (insuree.rut_and_fullname && insuree.rut_and_fullname !== '') {
        name = insuree.rut_and_fullname
      }else {
        name = getInsureeInfoByBackendData(insuree.data)
      }
    }
    return name
  }

  const [insureeName, setInsureeName] = useState(getInsureeName(getInsuree(insurees, insureeId)))

  const getPlaceholder = () => {
    return insurees.length === 0 ? 'Seleccione titular y póliza primero' : 'Seleccione una'
  }

  const handleChange = (event) => {
    let value = event.target.value
    setInsureeId(value)
    if (value === '-1') {
      setShowRejectButton(true)
    }else {
      if (policyId !== '-1') {
        setShowRejectButton(false)
        if (value !== '') {
          setEditable(false)
          let insuree = getInsuree(insurees, value)
          setInsuree(insuree)
          setInsureeName(getInsureeName(insuree))
        }
      }
    }
  }

  const getOptions = () => {
    let options = insurees.map((insuree) => {
      return {
        value: insuree.value,
        label: insuree.name
      }
    })
    if (insurees.length !== 1) {
      options = [{value: '', label: getPlaceholder()}, ...options]
    }
    return options
  }

  useEffect(() => {
    if (firstRun) {
      return
    }
    let insurees = [],
      filteredPolicies = policies.filter((policy) => {
        return parseInt(policy.value) === parseInt(policyId)
      })
    if (filteredPolicies.length > 0) {
      let newInsurees = filteredPolicies[0].insurees
      if (newInsurees !== undefined && newInsurees.length > 0) {
        if (!insureeId || insureeId === '-1' || newInsurees.filter((insuree) => parseInt(insuree.value) === parseInt(insureeId)).length === 0) {
          insureeId = newInsurees[0].value
        }
        insurees = [...newInsurees, {value: '-1', name: 'Otro (rechazar)'}]
      }
    }else {
      insureeId = ''
    }
    setInsurees(insurees)
    setInsureeId(insureeId)
    if (insureeId !== '' && insureeId !== '-1') {
      setEditable(false)
    }
    let insuree = getInsuree(insurees, insureeId)
    setInsuree(insuree)
    setInsureeName(getInsureeName(insuree))
  }, [policyId, policies])

  return (
    <div className={'form-group col form-row justify-content-center align-items-center'}>
      <label htmlFor={'new_insuree_id'} className={'col flex-grow-0'}>Paciente</label>
      {editable ? (
        <>
          <Select
            name={'new_insuree_id'}
            className={'col' + getInputErrorClass(formAlerts, 'insuree_id')}
            placeholder={getPlaceholder()}
            value={insureeId}
            onChange={handleChange}
            options={getOptions()}
          />
          <FormAlert
            message={getInputErrorMessage(formAlerts, 'insuree_id')}
          />
        </>
      ) : (
        <>
          <Label
            className={'col pr-2'}
          >
            <strong>{insureeName}</strong>
            {insureeName ? 
              <a className='text-primary ml-2'
                href={ !isFullInsurer ? `/insurers/${window.insurerId}/policies/${policyId}/insurees/${insureeId}` : `/asegurados/${insureeId}`}
                target="_blank"
                title="Ver asegurado (nueva pestaña)"
              >
                Ver <i className="icon-external-link fas fa-external-link-alt"></i>
              </a> 
              : <></> 
            }
          </Label>
        </>
      )}

    </div>
  )
}

export default Insurees