import React, {useEffect, useState} from 'react'
import convertStringToDate from '../../../../../utils/convertStringToDate'
import convertDateToString from '../../../../../utils/convertDateToString'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import LogsInsuree from './LogsInsuree'

const InsureeInfo = (
  {
    insurees,
    insureeId,
    shipment,
    isFullInsurer
  }
) => {

  const [insuree, setInsuree] = useState(null)
  const [receiptDate, setReceiptDate] = useState(shipment ? shipment.receipt_date : null)

  const getBirthdate = () => {
    return insuree && insuree.data ? new Date(insuree.data.birthday) : null
  }

  const getBirthday = () => {
    let birthday = ''
    if (insuree && insuree.data) {
      birthday = convertDateToString(new Date(insuree.data.birthday))
    }
    return birthday
  }

  const getAge = () => {
    let birthdate = getBirthdate(),
      date_s = receiptDate
    if (!date_s || !birthdate) {
      return ''
    }
    let date
    if (date_s instanceof Date) {
      date = date_s
    } else {
      date = convertStringToDate(date_s)
    }
    let this_year_counts = date.getUTCMonth() > birthdate.getUTCMonth() || (date.getUTCMonth() === birthdate.getUTCMonth() && date.getUTCDate() >= birthdate.getUTCDate()),
      sub = this_year_counts ? 0 : 1
    return date.getUTCFullYear() - birthdate.getUTCFullYear() - sub
  }

  const getInstanceStart = () => {
    let lastInstanceStart = 'S/I'
    if (insuree && insuree.data && insuree.data.last_instance_range) {
      lastInstanceStart = insuree.data.last_instance_range[0]
    }
    return lastInstanceStart
  }

  const getInstanceEnd = () => {
    let lastInstanceEnd = 'S/I'
    if (insuree && insuree.data && insuree.data.last_instance_range) {
      lastInstanceEnd = insuree.data.last_instance_range[1]
    }
    return lastInstanceEnd
  }

  const getInstanceClass = () => {
    let lastInstanceClass = 'text-success'
    if (insuree && insuree.data && insuree.data.last_instance_range && !insuree.data.last_instance_range[2]) {
      lastInstanceClass = 'text-danger'
    }
    return lastInstanceClass
  }

  const hasContinuity = () => {
    return insuree && insuree.data && insuree.data.coverage_continuity
  }

  useEffect(() => {
    let newInsuree = null,
      filteredInsurees = insurees.filter((insuree) => {
        return parseInt(insuree.value) === parseInt(insureeId)
      })
    if (filteredInsurees.length > 0) {
      newInsuree = filteredInsurees[0]
    }
    if (newInsuree !== insuree) {
      setInsuree(newInsuree)
    }
    setReceiptDate(shipment ? shipment.receipt_date : null)
  }, [insureeId, insurees, shipment])

  return (
    <>
      {insureeId && insuree ? (
        <>
          <div className={'mr-3'}>
            Fecha nacimiento: <strong>{getBirthday()}</strong>
          </div>
          <div className={'mr-3'}>
            Edad: <strong>{getAge() + ' años'}<span className={'tooltipable'} title={'Edad a la fecha de recepción de la remesa'}> <FontAwesomeIcon icon={faCircleInfo} /></span></strong>
          </div>
          <div className={'mr-3'}>
            Última vigencia: <span className={getInstanceClass()}><strong>{getInstanceStart() + ' - ' + getInstanceEnd()}</strong></span> {hasContinuity() ? 'con' : 'sin'} <strong>continuidad</strong>
          </div>
          <LogsInsuree
          {...{
            insureeId,
            shipment,
            insuree,
            isFullInsurer
          }}
          />
        </>
      ) : (
        <p>Seleccione paciente primero</p>
      )}
    </>
  )
}

export default InsureeInfo