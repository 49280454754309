import React from 'react'

const Option = ({value, children, ...rest}) => {
  return (
    <option
      value={value}
      {...rest}
    >
      {children}
    </option>
  )
}

export default Option